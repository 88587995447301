import React, { useState, useEffect, useRef } from "react";
import { ArrowForwardIos } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  userTyping,
  assistantResponding,
  addSelectedValue,
  removeSelectedValue,
  removeAllSelectedValues,
} from "../../../../reducers/actions/actions";
import axios from "axios";

const MessageInput = () => {
  const [messageInput, setMessageInput] = useState("");
  const dispatch = useDispatch();
  const typingTimeoutRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const selectedValues = useSelector((state) => state.chat.selectedValues);

  // Debounce typing status
  useEffect(() => {
    if (messageInput.length > 0 || isInputFocused) {
      dispatch(userTyping(true));
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        dispatch(userTyping(false));
      }, 3000); // 3 seconds debounce
    } else {
      dispatch(userTyping(false));
    }
    return () => clearTimeout(typingTimeoutRef.current);
  }, [messageInput, isInputFocused, dispatch]);

  const submitMessageButtonClickHandler = async () => {
    if ((messageInput.trim() !== "" && messageInput.length !== 0) || (selectedValues.length > 0)) {
      const selectedValuesString = selectedValues.join(", ");
      const combinedMessage =
        selectedValuesString.length > 0
          ? `${messageInput} [${selectedValuesString}]`
          : messageInput;

      dispatch(
        addMessage({
          type: "You",
          text: combinedMessage,
        })
      );
      dispatch(removeAllSelectedValues());
      setMessageInput("");
      inputRef.current.blur();
      dispatch(assistantResponding(true));

      try {
        const response = await axios.post(
          process.env.REACT_APP_ASSISTANT_URL + "/usermessage/",
          {
            userInputMessage: combinedMessage,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        dispatch(
          addMessage({
            type: "Assistant",
            text: response.data.assistant_response,
          })
        );
      } catch (error) {
        console.error("Error sending message:", error);
        dispatch(
          addMessage({
            type: "Assistant",
            text: "Due to some technical issue, the server is down. Please try again later.",
          })
        );
      } finally {
        dispatch(assistantResponding(false));
        dispatch(userTyping(false));
        inputRef.current.focus();
      }
    }
  };

  const messageChangeHandler = (e) => {
    setMessageInput(e.target.value);
  };

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && messageInput.trim() !== "") {
      submitMessageButtonClickHandler();
    }
  };

  const toggleCheckbox = (value) => {
    if (selectedValues.includes(value)) {
      dispatch(removeSelectedValue(value));
    } else {
      dispatch(addSelectedValue(value));
    }
  };

  return (
    <form className="card-actions" onSubmit={(e) => e.preventDefault()}>
      <div className="card-footer">
        <textarea
          className="textarea1"
          placeholder="Message"
          onKeyPress={handleKeyPress}
          ref={inputRef}
          onChange={messageChangeHandler}
          value={messageInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maxRows={2}
          multiline
          fullWidth
        ></textarea>
        <button
          className="submit-btn"
          type="button"
          onClick={submitMessageButtonClickHandler}
        >
          <ArrowForwardIos />
        </button>
      </div>
      <div
        className={`multi-select-checkbox-container ${selectedValues.length > 0 ? "visible" : "hidden"
          }`}
      >
        <ul className="ks-cboxtags">
          {selectedValues.map((value, index) => (
            <li key={index}>
              <input type="checkbox" id={`check${index}`} value={value} />
              <label htmlFor={`check${index}`}>{value}</label>
            </li>
          ))}
        </ul>
      </div>
    </form>
  );
};

export default MessageInput;
