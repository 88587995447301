import React, { Component } from "react";
import { get } from "lodash";
import "./DonutChart.scss";
import * as d3 from "d3";
import positiveIcon from "../../../assets/icons/icon_positive.svg";
import negativeIcon from "../../../assets/icons/icon_negative.svg";
import initData from "../../../utils/initData";
import createStatTestingMessage, {
  getToExclude,
  getStatTestingIcon,
} from "../../../utils/createStatTestingMessage";

export default class DonutChart extends Component {
  static chartName = "DonutChart";
  constructor(props) {
    super(props);
    this.pie =
      props.selectedTab !== 3
        ? d3
            .pie()
            .padAngle(0.015)
            .sort(null)
            .value(function (d) {
              return d.value;
            })
        : d3.pie().sort(null).startAngle(0);
    this.state = {
      arc: d3
        .arc()
        .innerRadius(props.size / 2 - 20)
        .outerRadius(props.size / 2 - 1),
      arcs: this.pie(props.data),
      linearscale: d3
        .scaleLinear()
        .domain([
          props.data[props.data.length - 1].value,
          [props.data[0].value],
        ])
        .range([props.colors[0], props.colors[4]]),
    };

    this.renderTooltip = this.renderTooltip.bind(this);
  }

  componentDidMount() {
    this.renderTooltip();
    if (this.props.selectedTab === 3) {
      this.renderDonut();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.selectedTab === 3) {
      this.renderDonut(prevProps);
    }
  }

  componentWillReceiveProps(nextProps) {
    d3.selectAll(".tooltip").remove();
    this.setState(
      {
        arc: d3
          .arc()
          .innerRadius(nextProps.size / 2 - 20)
          .outerRadius(nextProps.size / 2 - 1),
        arcs: this.pie(nextProps.data),
      },
      this.renderTooltip
    );
  }

  async renderDonut(prevProps) {
    let arcClass = "." + this.props.tag;
    let colors = this.props.colors;
    d3.select(arcClass).html("");
    let arc = d3
      .arc()
      .innerRadius(this.props.size / 2 - 20)
      .outerRadius(this.props.size / 2 - 1)
      .startAngle(
        prevProps?.data[0]?.value / 15.88 > this.props.data[0].value / 100
          ? 0
          : 0
      );

    let svg = d3.select(arcClass).attr("transform", "translate(100, 100)");

    let a = "arc " + this.props.tag + this.props.tag;

    svg
      .append("g")
      .attr("class", a)
      .attr("id", 1)
      .append("path")
      .datum({ endAngle: (22 / 7) * 2 })
      .style("fill", "#ddd")
      .attr("d", arc);
    let foreground = svg
      .append("g")
      .attr("class", a)
      .attr("id", 0)
      .append("path")
      .datum({
        endAngle:
          (Number(prevProps?.number) === 100 &&
          prevProps?.number > this.props.number
            ? (22 / 7) * 2
            : this.props.data[0].value / 100) *
          ((22 / 7) * 2),
      })
      .style("fill", (d, i) => colors[i])
      .attr("d", arc);
    let angleInterpolation;

    if (!prevProps?.number) {
      angleInterpolation = await d3.interpolate(0, this.pie.endAngle()());
    } else {
      if (
        Number(prevProps?.number) === 100 &&
        prevProps?.number > this.props.number
      ) {
        angleInterpolation = await d3.interpolate(
          (22 / 7) * 2,
          (this.props.data[0].value / 100) * ((22 / 7) * 2)
        );
      } else {
        angleInterpolation = await d3.interpolate(
          prevProps?.number / 15.88,
          this.pie.endAngle()()
        );
      }
    }
    foreground
      .transition()
      .duration(750)
      .attrTween("d", (d) => {
        let originalEnd = d.endAngle;
        return (t) => {
          let currentAngle = angleInterpolation(t);
          d.endAngle = Math.min(currentAngle, originalEnd);
          return arc(d);
        };
      });
  }

  renderTooltip() {
    const {
      data,
      brand,
      cperiod,
      tag,
      statTestingEnabled,
      higherThan,
      lowerThan,
      selectedTab,
      number,
      selectedDate,
    } = this.props;

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("display", "none");

    tooltip.append("div").attr("class", "brand").text(brand);

    const label = tooltip.append("div").attr("class", "label");

    const value = tooltip.append("div").attr("class", "value");

    const diffValue = tooltip.append("div").attr("class", "value");

    const wrapper = tooltip.append("div").attr("class", "percentageContainer");

    const percentage = wrapper.append("div").attr("class", "percentage");

    const statTestingArrow = wrapper.append("img");

    const statTestingMessageBlock = tooltip
      .append("div")
      .attr("class", "statTooltip")
      .style("display", "none");

    if (!statTestingEnabled) {
      wrapper.style("display", "none");
    }

    let arcClass = selectedTab === 3 ? "." + tag + tag : "." + tag;

    d3.selectAll(arcClass)
      .on("mouseover", function (d) {
        const id = d3.select(this).node().id;
        const name = data[id]?.label;
        const diff = data[id]?.diff;
        const higher = get(higherThan, name, []);
        const lower = get(lowerThan, name, []);
        const toExclude = getToExclude(DonutChart.chartName);

        const icon = getStatTestingIcon(higher, lower, toExclude, [
          positiveIcon,
          negativeIcon,
        ]);
        const statTestingMessage = createStatTestingMessage({
          entity: brand,
          toExclude,
          higherThan: higher,
          lowerThan: lower,
          period: cperiod,
          selectedTab: selectedTab,
          startDate: selectedDate?.startdate,
          endDate: selectedDate?.enddate,
        });

        if (selectedTab !== 3) {
          value.text(data[id].value.toFixed(1));
        } else {
          if (Number(id) === 0) {
            value.text(number.toFixed(1) + "%");
          } else {
            value.text((100 - number).toFixed(1) + "%");
          }
        }
        label.text(name?.toUpperCase());
        if (selectedTab !== 3) {
          if(statTestingEnabled !== true) {
            diffValue.text("--");
          }
          else if (Number(diff) > 0) {
            diffValue.text("+" + data[id].diff.toFixed(1));
          }else if(diff === null) {
            diffValue.text(
              "N/A"
            );
          } else {
            diffValue.text(
              isNaN(data[id].diff) ? "N/A" : data[id].diff.toFixed(1)
            );
          }
        } else {
          diffValue.text();
        }
        statTestingArrow.attr("src", icon);

        // if(Number(diff) !== 0 && diff !== data[id]?.value && selectedTab !== 3) {
        //     percentage.text(Math.abs(diff).toFixed(1));
        // }

        if (statTestingEnabled && statTestingMessage) {
          statTestingMessageBlock
            .html(statTestingMessage)
            .style("display", "block");
        }
      })
      .on("mousemove", function () {
        tooltip.style("display", "flex");
        return tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        percentage.style("padding-right", "0").style("background", "none");
        statTestingMessageBlock.html("").style("display", "none");
        return tooltip.style("display", "none");
      });
  }

  render() {
    const {
      brand,
      colors,
      colorIndex,
      cperiod,
      higherThan,
      lowerThan,
      number,
      size,
      statTestingEnabled,
      tag,
      title,
      upDown,
      qText,
      upDownNA,
      selectedTab,
      isAdvertising,
      selectedDate,
    } = this.props;
    const { arc, arcs } = this.state;
    let donutChartUpDown = "donutChartUpDown";
    const toExclude = getToExclude(DonutChart.chartName);
    let higher, lower;
    if (selectedTab === 3) {
      let adTitle = "ADVERTISING RECALL".split(" ");
      higher = get(
        higherThan,
        adTitle[0].toLowerCase() +
          adTitle[1]?.slice(0, 1) +
          adTitle[1]?.slice(1)?.toLowerCase(),
        []
      );
      lower = get(
        lowerThan,
        adTitle[0].toLowerCase() +
          adTitle[1]?.slice(0, 1) +
          adTitle[1]?.slice(1)?.toLowerCase(),
        []
      );
    } else {
      higher = get(
        higherThan,
        `${title.slice(0, 1)}${title.slice(1).toLowerCase()}`,
        []
      );
      lower = get(
        lowerThan,
        `${title.slice(0, 1)}${title.slice(1).toLowerCase()}`,
        []
      );
    }
    const icon = getStatTestingIcon(higher, lower, toExclude, [
      positiveIcon,
      negativeIcon,
    ]);
    const statTestingMessage = createStatTestingMessage({
      entity: brand,
      toExclude,
      higherThan: higher,
      lowerThan: lower,
      period: cperiod,
      selectedTab: selectedTab,
      asComponent: true,
      isAdvertising: isAdvertising === true,
      startDate: selectedDate?.startdate,
      endDate: selectedDate?.enddate,
    });
    let upDownSign = "";
    let upDownValue = "";

    // In the event we do not have any data for the previous period,
    // we show 'N/A' else if there is no change in data then simply '-'
    // =====================================================
    if(upDown === null) {
      upDownSign = "";
      upDownValue = "N/A";
    }
    else if (!upDownNA && !isNaN(upDown)) {
      upDownSign = Number(upDown) !== 0 ? (upDown > 0 ? "+" : "–") : "";
      upDownValue = Math.abs(upDown).toFixed(1);
      upDownValue = upDownValue;
    } else {
      upDownSign = "";
      upDownValue = "N/A";
    }
    return (
      <div className="donutChartCardContainer">
        <svg
          viewBox={
            selectedTab === 3 ? "100 100 170 170" : `0 0 ${size} ${size}`
          }
          width={size}
          height={size}
        >
          <g transform={`translate(${size / 2}, ${size / 2})`}>
            {arcs.map((d, index) => (
              <g id={index} className={"arc " + tag} key={`a${index}`}>
                <path d={arc(d)} fill={colors[colorIndex[d.data.label]]} />
              </g>
            ))}
          </g>
        </svg>
        <div className="donutChartOverlapContainer">
          <div>
            <div className="donutChartTitle withQText">
              {title}
              <span>{qText}</span>
            </div>
            <div className="donutChartNumber">
              {selectedTab !== 3 ? number.toFixed(1) : number + "%"}
            </div>
          </div>
          <div className="donutChartUpDownContainer">
            <div className={donutChartUpDown}>
              {statTestingEnabled ? (
                <>
                  <span className="upDownSign">{upDownSign}</span> {upDownValue}{" "}
                </>
              ) : (
                "--"
              )}
            </div>
            {icon && statTestingEnabled && statTestingMessage && (
              <div className="donutChartUpDownIcon withQText withPaddingTop">
                <img src={icon} alt="" className="iconImg" height={15} />
                <span dangerouslySetInnerHTML={statTestingMessage()} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
