import {
  ADD_MESSAGE,
  USER_TYPING,
  ASSISTANT_RESPONDING,
  ADD_BULK_MESSAGE,
  DELETE_ALL_MESSAGES,
  SET_HAS_INITIALIZED_MESSAGES,
  ADD_SELECTED_VALUE,
  REMOVE_SELECTED_VALUE,
  REMOVE_ALL_SELECTED_VALUE,
} from "../actiontypes/actiontypes";

export const addMessage = (message) => ({
  type: ADD_MESSAGE,
  payload: message,
});

export const addBulkMessage = (message) => ({
  type: ADD_BULK_MESSAGE,
  payload: message,
});

export const userTyping = (isTyping) => ({
  type: USER_TYPING,
  payload: isTyping,
});

export const assistantResponding = (isResponding) => ({
  type: ASSISTANT_RESPONDING,
  payload: isResponding,
});

export const deleteAllMessage = () => ({
  type: DELETE_ALL_MESSAGES,
});

export const setHasInitializedMessages = (hasInitialized) => ({
  type: SET_HAS_INITIALIZED_MESSAGES,
  payload: hasInitialized,
});

export const addSelectedValue = (value) => ({
  type: ADD_SELECTED_VALUE,
  payload: value,
});

export const removeSelectedValue = (value) => ({
  type: REMOVE_SELECTED_VALUE,
  payload: value,
});

export const removeAllSelectedValues = () => ({
  type: REMOVE_ALL_SELECTED_VALUE,
});
