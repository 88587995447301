import React, { memo } from "react";
import { DateTime } from "luxon";
import useFileDownload from "./hooks/useFileDownloadHook";
import ButtonWithDropdownOption from "../ButtonWithDropdownOption";
import DownloadIconSVG from "../../assets/icons/download.svg";
import CircleProgressBar from "../widgets/Shared/CircleProgressBar";
import DownloadFailed from "../../assets/icons/icon_close_red.svg";
import { FILE_STATUS } from "./utils/constant";
import CustomTooltip from "../CustomTooltip";
import useViewsReportsExportsStatus from "../../hooks/useViewsReportsExportsStatus";
import DownloadIcon from "../widgets/Shared/ExportButton/DownloadIcon";
import {
  StyledFileDownload,
  StyledFileDownloadPopup,
} from "./Styled.FileDownload";

function FileDownload({}) {
  const {
    downloadFileList,
    progressUpdate,
    overallProgressStatus,
    triggerFileDownload,
  } = useFileDownload();
  // const fileListMaxLength = 5;
  let { ids, values } = downloadFileList;
  // if( ids?.length > 5) ids = ids.slice(ids?.length - fileListMaxLength);

  const { isReportAndViewsExportAvailable } = useViewsReportsExportsStatus();

  if (isReportAndViewsExportAvailable) {
    return (
      <StyledFileDownload>
        <ButtonWithDropdownOption
          trigger={
            <div className="file-download-header">
              {overallProgressStatus < 100 && overallProgressStatus ? (
                <div className="file-progress-loader-parent">
                  <span className="file-progress-loader-parent-download-icon">
                    <DownloadIcon iconColor="#DFE2EA" />
                  </span>
                  {true && (
                    <CircleProgressBar
                      progress={overallProgressStatus}
                      strokeWidth={6}
                      pathColor="#1BA379"
                      trailColor="#DFE2EA"
                    />
                  )}
                </div>
              ) : (
                <img
                  src={DownloadIconSVG}
                  className="file-download-trigger"
                  height={22}
                />
              )}
            </div>
          }
          closeOnContentClick={false}
        >
          <StyledFileDownloadPopup>
            <div className="download-file-heading">FILES</div>
            <ul>
              {ids.length > 0 &&
                ids.map((jobId) => {
                  const value = values[jobId];
                  return (
                    <li key={jobId}>
                      <div>
                        <div> {value.fileName}</div>
                        <div className="file-download-date">
                          {DateTime.fromJSDate(value?.date).toFormat(
                            "MMM dd, yyyy"
                          )}
                        </div>
                      </div>
                      <div className="file-progress-status">
                        {value.status === FILE_STATUS.PENDING && (
                          <div className="file-progress-loader">
                            <CircleProgressBar
                              progress={progressUpdate[jobId]}
                              strokeWidth={6}
                              pathColor="#1BA379"
                              trailColor="#DFE2EA"
                            />
                          </div>
                        )}
                        {value.status === FILE_STATUS.FAILED && (
                          <CustomTooltip
                            contentList={[value.errorMessage]}
                            classname="file-download-error"
                            placement="bottom-end"
                          >
                            <img
                              src={DownloadFailed}
                              alt="Download Failed"
                              height={22}
                            />
                          </CustomTooltip>
                        )}
                        {value.status === FILE_STATUS.SUCCESS && (
                          <div
                            className="file-download-link"
                            onClick={() => {
                              triggerFileDownload(value.url, value.fileName);
                            }}
                          >
                            Download
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              {ids.length === 0 && (
                <li className="no-file-to-download">No file to download</li>
              )}
            </ul>
          </StyledFileDownloadPopup>
        </ButtonWithDropdownOption>
      </StyledFileDownload>
    );
  }

  return null;
}

export default memo(FileDownload);
