import React, { Component } from "react";
import cx from "classnames";
import { get } from "lodash";
import initData from "../../../utils/initData";
import "./EquityCard.scss";
import upIcon from "../../../assets/icons/icon_positive.svg";
import downIcon from "../../../assets/icons/icon_negative.svg";
import triangleUpIcon from "../../../assets/icons/icon_triangle.svg";
import triangleDownIcon from "../../../assets/icons/icon_triangle_down.svg";
import DonutChart from "./DonutChart";
import BrandTitle from "../Shared/BrandTitle";
import SegmentTitle from "../Shared/SegmentTitle";
import NoDataBox from "../Shared/NoDataBox";
import * as d3 from "d3";
import createStatTestingMessage, {
  getToExclude,
  getStatTestingIcon,
} from "../../../utils/createStatTestingMessage";

const colorIndex = {
  Momentum: 4,
  Consideration: 3,
  Quality: 2,
  Familiarity: 1,
};
export default class EquityCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      size: 170,
      closeFigureData: false,
      linearscale:
        props.data.length > 0
          ? d3
              .scaleLinear()
              .domain([
                props.data[props.data.length - 1].value,
                [props.data[0].value],
              ])
              .range([props.colors[0], props.colors[4]])
          : null,
    };
  }

  onClickTriangleIcon() {
    this.setState({
      closeFigureData: !this.state.closeFigureData,
    });
  }

  render() {
    const { closeFigureData, size } = this.state;
    const {
      brand,
      chartTitle,
      colors,
      cperiod,
      data,
      isDetailed,
      higherThan,
      lowerThan,
      logo,
      number,
      page,
      pool,
      segment,
      selectedTab,
      statTestingEnabled,
      tag,
      upDown,
      regionState,
      countryCode,
      name,
      selectedDate,
    } = this.props;
    let icon = undefined;
    let containerStyle = "diagramContainer";
    let overlapContainerStyle = undefined;

    if (closeFigureData) {
      icon = triangleDownIcon;
      overlapContainerStyle = "overlapContainer additionMove";
    } else {
      icon = triangleUpIcon;
      overlapContainerStyle = "overlapContainer";
    }
    return (
      <div
        className={cx("cardContainer", "equityCompetitiveContainer", {
          segmentHidden: segment && !segment.isActive,
        })}
      >
        <div className="cardHeaderContainer" id={"cardHeaderContainer"}>
          {segment ? (
            <SegmentTitle
              segment={segment}
              pool={pool}
              onClickSegmentFilter={this.props.onClickSegment}
              page={page}
              selectedTab={selectedTab}
            />
          ) : name ? (
            <BrandTitle
              brand={{ name: name }}
              regionState={regionState}
              customClass={"equityCard"}
              noLogo
            />
          ) : (
            <BrandTitle
              brand={{ name: brand, logo: logo, countryCode: countryCode }}
              page={page}
              selectedTab={selectedTab}
              customClass={"equityCard"}
            />
          )}
          {!segment && Number(pool) > 0 && (
            <div className="value">
              n={Math.round(pool).toLocaleString("en-En")}
            </div>
          )}
        </div>
        <div className="scollContainer">
          {number > 0 ? (
            <div className={containerStyle}>
              <div id="donutChartContainer" className={overlapContainerStyle}>
                <div className="donutCharContainer">
                  <DonutChart
                    colors={colors}
                    colorIndex={colorIndex}
                    data={data?.filter(ele=> ele.label !== 'Equity')}
                    title={chartTitle}
                    number={number}
                    upDown={upDown}
                    brand={isDetailed ? segment?.segmentName : brand}
                    tag={tag}
                    size={size}
                    cperiod={cperiod}
                    statTestingEnabled={statTestingEnabled}
                    higherThan={higherThan}
                    lowerThan={lowerThan}
                    page={page}
                    selectedDate={selectedDate}
                    qText={data[0]?.qText}
                  />
                </div>
                <img
                  src={icon}
                  alt=""
                  className="triangleIcon"
                  onClick={this.onClickTriangleIcon.bind(this)}
                />
              </div>
            </div>
          ) : (
            <NoDataBox />
          )}
          {!closeFigureData && number > 0 && (
            <div className="bottomContainer">
              {data.length > 0 &&
                data
                  ?.filter((ele) => ele.label !== "Equity")
                  .map((item, index) => {
                    const { label } = item;
                    const higher = get(higherThan, label, []);
                    const lower = get(lowerThan, label, []);
                    const toExclude = getToExclude(DonutChart.chartName);

                    const icon = getStatTestingIcon(higher, lower, toExclude, [
                      upIcon,
                      downIcon,
                    ]);
                    const statTestingMessage = createStatTestingMessage({
                      entity: label,
                      toExclude: toExclude,
                      higherThan: higher,
                      lowerThan: lower,
                      period: cperiod,
                      asComponent: true,
                      startDate: selectedDate.startdate,
                      endDate: selectedDate.enddate,
                    });
                    return (
                      <div className="figureContainer" key={"bctitle" + index}>
                        <div className="figureTitle withQText">
                          {item.label?.toUpperCase()}
                          <span>{item?.qText}</span>
                        </div>
                        <div className="figureData">
                          <div
                            className="figure"
                            style={{ color: colors[colorIndex[item.label]] }}
                          >
                            {initData.getWayUpValue(item.value).toFixed(1)}
                          </div>
                          {item.diff !== 0 &&
                            item.diff !== item.value &&
                            statTestingEnabled &&
                            statTestingMessage && (
                              <div className="upDownIcon withQText">
                                <img src={icon} className="icon" alt="" />
                                <span
                                  dangerouslySetInnerHTML={statTestingMessage()}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    );
                  })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
