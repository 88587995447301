import React, { Component } from "react";
import cx from "classnames";
import "./BrandTitle.scss";
import PropTypes from "prop-types";
import { getLocalStorage } from "../../../utils/storageHelpers";
import helpIcon from "../../../assets/icons/help_icon.svg";
import { CircleFlag } from "react-circle-flags";
import { getCountryCode } from "../../../utils/common";
import { getURLPathParams } from "../../../utils/URLParamsValidations";
import spiritDrinkersMarketLogo from "../../../assets/icons/spirit_drinkers.png";

export default class brandTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adTooltip: 0,
    };
    this.tooltip = React.createRef(null);
  }
  shouldComponentUpdate(prevProps, prevState) {
    setTimeout(() => {
      if (prevState.adTooltip !== this.tooltip?.current?.scrollWidth) {
        this.setState({
          adTooltip: this.tooltip?.current?.scrollWidth,
        });
      }
    }, 1000);
    return true;
  }
  render() {
    const {
      afterIcon,
      brand,
      charKeyMap,
      customClass,
      isBrandTable,
      noData,
      noLogo,
      page,
      primaryBrand,
      ssize,
      statTestingEnabled,
      onClickItemFunction,
      regionState,
      selectedTab,
      conversion,
      emotional,
      filterOn,
      customSegment,
      dropdown,
      brandHidden,
      customSegmentpage,
      dailyAverageSampleSize,
      isFiltersExpiredInSegment,
      isAllFiltersPresentInMarket,
    } = this.props;
    let { module } = getURLPathParams();
    const authorizedType = getLocalStorage("authorizedType");
    const brandName = brand?.marketName
      ? brand?.marketName
      : brand?.displayName
      ? brand?.displayName
      : brand?.name
      ? brand?.name
      : brand?.decipherName;
    const decipherName = brand?.decipherName;
    let countryCode = getCountryCode(brandName, brand?.countryCode);

    const logo = brand?.logo ? (
      <img src={brand?.logo} className="brandLogoIcon" alt="logo" />
    ) : brand?.countryCode ? (
      countryCode !== "spiritDrinkers" ? (
        <CircleFlag
          countryCode={countryCode}
          height="25"
          style={{ paddingRight: "10px" }}
        />
      ) : (
        <img src={spiritDrinkersMarketLogo} alt="" className="roundedLogo" />
      )
    ) : (
      <div className="brandDefaultLogo">
        <div>{brandName?.slice(0, 1)?.toUpperCase()}</div>
      </div>
    );

    const brandClass = "brandTitle" + (customClass ? " " + customClass : "");
    return (
      <div
        className={brandClass}
        onClick={
          typeof onClickItemFunction === "function"
            ? () => onClickItemFunction(brand.id)
            : null
        }
      >
        {!customSegmentpage && logo}
        <div className="brandName">
          <div
            className={cx({
              brandNameDataContainer: true,
              "brandNameDataContainer--competitive": page === "competitive",
              "brandNameDataContainer--segments": page === "segments",
            })}
            ref={this.tooltip}
          >
            <div
              className="brandNameDataContainer"
              style={
                customSegmentpage && isAllFiltersPresentInMarket === false
                  ? { display: "flex", opacity: 0.5 }
                  : { display: customSegmentpage ? "flex" : "block" }
              }
            >
              {!regionState ? (
                isBrandTable ? (
                  decipherName
                ) : (
                  brandName
                )
              ) : (
                <div className={"cardTitle"}>
                  <span className={"campaignName"}>{brandName}</span>
                </div>
              )}
              {page === "competitive" &&
                (isBrandTable ? decipherName : brandName)?.length > 11 &&
                (selectedTab === 0 || selectedTab === 3) && (
                  <span className={"tooltiptext"}>
                    {isBrandTable ? decipherName : brandName}
                  </span>
                )}
              {isFiltersExpiredInSegment &&
              customSegmentpage &&
              isAllFiltersPresentInMarket === true ? (
                <div className="segmentWarning" style={{ display: "contents" }}>
                  <img
                    src={helpIcon}
                    alt=""
                    width={20}
                    height={20}
                    style={{ marginLeft: "3px", marginTop: "-1px" }}
                  />
                  <span className={"tooltipWarning"}>
                    Filters for this segment have expired. Reach out to your
                    client service contact regarding reactivation.
                  </span>
                </div>
              ) : (
                ""
              )}
              {isAllFiltersPresentInMarket === false && customSegmentpage ? (
                <div className="segmentWarning">
                  <img src={helpIcon} alt="" width={20} height={20} />
                  <span className={"tooltipWarning"}>
                    Filters for this segment are not applicable for this market.
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            {authorizedType !== "ADMIN" && (
              <div
                className={cx({ brandNameDataContainer: true })}
                style={{
                  color: "#000000",
                  fontFamily: "Silka-SemiBold, sans-serif",
                  fontWeight: 400,
                }}
              >
                {Number(ssize) && Number(ssize) > 0 ? (
                  <div className="sampleSize">
                    {Number(ssize) > 0 && (
                      <span className="samplesizeConatiner">
                        n={Number(ssize.toFixed(0)).toLocaleString("en-En")}
                        {selectedTab === 4 && (
                          <>
                            {" (total)"}
                            {!brandHidden && (
                              <span className={"tooltipWarning"}>
                                Total volume:{" "}
                                {Number(ssize.toFixed(0)).toLocaleString(
                                  "en-En"
                                )}
                              </span>
                            )}
                          </>
                        )}
                      </span>
                    )}
                    {Number(dailyAverageSampleSize) > 0 &&
                      selectedTab === 4 && (
                        <span
                          className="segmentWarning"
                          style={{ margin: "auto" }}
                        >
                          {" "}
                          | n=
                          {Number(
                            dailyAverageSampleSize.toFixed(0)
                          ).toLocaleString("en-En")}{" "}
                          (average volume)
                          {!brandHidden && (
                            <span className={"tooltipWarning"}>
                              Average volume per data point:{" "}
                              {Number(
                                dailyAverageSampleSize.toFixed(0)
                              ).toLocaleString("en-En")}
                            </span>
                          )}
                        </span>
                      )}
                  </div>
                ) : customClass ||
                  emotional ||
                  conversion ||
                  customSegment ||
                  dropdown ? (
                  ""
                ) : (
                  <div className="sampleSize">No data available</div>
                )}
              </div>
            )}
            {page === "competitive" &&
              (isBrandTable ? decipherName : brandName)?.length > 11 &&
              (selectedTab === 0 || selectedTab === 3) && (
                <span className={"tooltiptext"}>
                  {isBrandTable ? decipherName : brandName}
                </span>
              )}
            {noData && selectedTab !== 4 && (
              <div className="noData">No data available</div>
            )}
            {primaryBrand && <span>Primary Brand</span>}
          </div>
          {statTestingEnabled && (
            <div className="statLetter">
              <span>
                {charKeyMap[brand.name ? brand.name : brand.marketName]}
              </span>
            </div>
          )}
        </div>
        {afterIcon}
      </div>
    );
  }
}
brandTitle.propTypes = {
  noData: PropTypes.bool,
};
brandTitle.defaultProps = {
  noData: false,
};
