import usersIcon from "../assets/icons/icon_nav_users.svg";
import campaignsIconBlue from "../assets/icons/icon_nav_campaigns_blue.svg";
import campaignsIcon from "../assets/icons/icon_nav_campaigns.svg";
import summaryIcon from "../assets/icons/icon_nav_summary.svg";
import detailedIcon from "../assets/icons/icon_nav_detailed.svg";
import competitiveIcon from "../assets/icons/icon_nav_competitive.svg";
import geoIcon from "../assets/icons/icon_geo.svg";
import geoIconBlue from "../assets/icons/icon_geo_blue.svg";
import advancedIcon from "../assets/icons/icon_nav_advanced.svg";
import usersBlue from "../assets/icons/icon_nav_users_blue.svg";
import summaryBlue from "../assets/icons/icon_nav_summary_blue.svg";
import detailedBlue from "../assets/icons/icon_nav_detailed_blue.svg";
import competitiveBlue from "../assets/icons/icon_nav_competitive_blue.svg";
import advancedBlue from "../assets/icons/icon_nav_advanced_blue.svg";
import productInactive from "../assets/icons/product-inactive.svg";
import productActive from "../assets/icons/product-active.svg";
import viewsInactive from "../assets/icons/icon_views_inactive.svg";
import viewsActive from "../assets/icons/viewsActiveIcon.png";
import equityIcon from "../assets/icons/view_equity.svg";
import conversionIcon from "../assets/icons/views_conversion.svg";
import trendsIcon from "../assets/icons/views_trends.svg";
import emotionalIcon from "../assets/icons/views_emotional.svg";
import advertisingIcon from "../assets/icons/views_advertising.svg";
import profileIcon from "../assets/icons/views_profile.svg";
import differentiationIcon from "../assets/icons/views_differentiation.svg";
import perceptualIcon from "../assets/icons/views_perceptual.svg";
import butterflyIcon from "../assets/icons/views_butterfly.svg";
import prepostIcon from "../assets/icons/views_prepost.svg";
import pdfIcon from "../assets/icons/icon_pdf.svg";
import pptIcon from "../assets/icons/icon_ppt.svg";
import xlsIcon from "../assets/icons/icon_csv.svg";
import pngIcon from "../assets/icons/icon_image.svg";
import { getLocalStorage } from "../../src/utils/storageHelpers";
import filtersTransformations, {
  filterGroupTypes,
} from "./filterTransformations";
import groupsIcon from "../assets/icons/group.svg";
import groupsBlue from "../assets/icons/groups_blue.svg";
import hasPermission from "../utils/Permissions";

export default class InitData {
  static getExternalUserIDs() {
    return [
      "auth0|5d8e3c54fbfb0b0c4c6d5f49",
      "auth0|628f4392f9c53c0069ba371e",
      "auth0|629648fa5d73c2006f521da4",
      "auth0|605bcbecc89a51006976531f",
      "auth0|619384659792730069099576",
      "auth0|5fe10bff66406700753ce376",
      "auth0|66214d5c8dc87d096948999d",
      "auth0|66214d97a3bc2b794e26d5c6",
    ];
  }
  static getMenu() {
    return {
      users: {
        title: "User Management",
        menuTitle: "User Management",
        icon: usersIcon,
        iconCurrent: usersBlue,
        tabs: [
          {
            name: "Users",
            title: "Users",
          },
        ],
        authorizedType: "ADMIN",
      },
      user: {
        title: "User Management",
        menuTitle: "hidden",
        icon: usersIcon,
        iconCurrent: usersBlue,
        tabs: [
          {
            name: "Profile",
            title: "Profile",
          },
        ],
        authorizedType: "ADMIN",
      },
      groups: {
        title: "Group Management",
        menuTitle: "Group Management",
        icon: groupsIcon,
        iconCurrent: groupsBlue,
        tabs: [
          {
            name: "Groups",
            title: "Groups",
          },
        ],
        authorizedType: "ADMIN",
      },
      group: {
        title: "Group Management",
        menuTitle: "hidden",
        icon: groupsIcon,
        iconCurrent: groupsBlue,
        tabs: [
          {
            name: "Overview",
            title: "Overview",
          },
          {
            name: "Contracts",
            title: "Contracts",
          },
          {
            name: "Filters",
            title: "Filters",
          },
        ],
        authorizedType: "ADMIN",
      },
      brands: {
        title: "Brand Management",
        menuTitle: "Brand Management",
        icon: detailedIcon,
        iconCurrent: detailedBlue,
        tabs: [
          {
            name: "Approved",
            title: "Approved",
          },
          hasPermission()
            ? {
              name: "Pending",
              title: "Pending",
            }
            : { name: "", title: "" },
        ],
        authorizedType: "ADMIN",
      },
      markets: {
        title: "Geography Management",
        menuTitle: "Geography Management",
        icon: campaignsIcon,
        iconCurrent: campaignsIconBlue,
        tabs: [
          {
            name: "Active",
            title: "Active",
          },
          hasPermission()
            ? {
              name: "Inactive",
              title: "Inactive",
            }
            : { name: "", title: "" },
        ],
        authorizedType: "ADMIN",
      },
      market: {
        title: "Campaign Management",
        menuTitle: "hidden",
        icon: campaignsIcon,
        iconCurrent: campaignsIconBlue,
        tabs: [
          {
            name: "Overview",
            title: "Overview",
          },
          {
            name: "Brands",
            title: "Brands",
          },
        ],
        authorizedType: "ADMIN",
      },
      marketBrands: {
        title: "Campaign Management",
        menuTitle: "hidden",
        icon: campaignsIcon,
        iconCurrent: campaignsIconBlue,
        tabs: [
          {
            name: "Overview",
            title: "Overview",
          },
          {
            name: "Brands",
            title: "Brands",
          },
        ],
        authorizedType: "ADMIN",
      },
      summary: {
        title: "Brand Summary",
        menuTitle: "Summary",
        icon: summaryIcon,
        iconCurrent: summaryBlue,
        tabs: [],
        authorizedType: "USER",
      },
      segments: {
        title: "Segments",
        menuTitle: "Segments",
        icon: detailedIcon,
        iconCurrent: detailedBlue,
        tabs: [
          {
            name: "Equity",
            title: "Brand Equity",
          },
          {
            name: "Conversion",
            title: "Conversion Funnel",
          },
          {
            name: "Emotional",
            title: "Emotional",
          },
          {
            name: "Advertising",
            title: "Advertising",
          },
          {
            name: "Trends",
            title: "Trends",
          },
          {
            name: "Profile",
            title: "Profile",
          },
          // {
          //     "name": "Discover",
          //     "title": "Discover",
          // },
        ],
        authorizedType: "USER",
      },
      competitive: {
        title: "Competitive Intelligence",
        menuTitle: "Competitive",
        icon: competitiveIcon,
        iconCurrent: competitiveBlue,
        tabs: [
          {
            name: "Equity",
            title: "Brand Equity",
          },
          {
            name: "Conversion",
            title: "Conversion Funnel",
          },
          {
            name: "Emotional",
            title: "Emotional",
          },
          {
            name: "Advertising",
            title: "Advertising",
          },
          // {
          //     "name": "Touchpoints",
          //     "title": "Touchpoints",
          // },
          {
            name: "Trends",
            title: "Trends",
          },
          {
            name: "Profile",
            title: "Profile",
          },
          // {
          //     "name": "Discover",
          //     "title": "Discover",
          // },
        ],
        authorizedType: "USER",
      },
      global: {
        title: "Global",
        menuTitle: "Global",
        icon: geoIcon,
        iconCurrent: geoIconBlue,
        tabs: [
          {
            name: "Equity",
            title: "Brand Equity",
          },
          {
            name: "Conversion",
            title: "Conversion Funnel",
          },
          {
            name: "Emotional",
            title: "Emotional",
          },
          {
            name: "Advertising",
            title: "Advertising",
          },
          // {
          //     "name": "Touchpoints",
          //     "title": "Touchpoints",
          // },
          {
            name: "Trends",
            title: "Trends",
          },
          {
            name: "Profile",
            title: "Profile",
          },
          // {
          //     "name": "Discover",
          //     "title": "Discover",
          // },
        ],
        authorizedType: "USER",
      },
      advanced: {
        title: "Advanced Analytics",
        menuTitle: "Advanced",
        icon: advancedIcon,
        iconCurrent: advancedBlue,
        tabs: [
          {
            name: "Differentiation",
            title: "Differentiation",
            desc: "Differentiation mapping helps identify how differentiated brands are relative to a predefine category of brands.",
          },
          {
            name: "Perceptual",
            title: "Perceptual",
            desc: "A principle components analysis run on a set of brands and their imagery attributes scores.",
          },
          {
            name: "Butterfly",
            title: "Butterfly",
            desc: "A clustered bar chart that shows the attribute deltas for 2 brands head to head.",
          },
          {
            name: "Pre/Post",
            title: "Pre/Post",
            desc: "Allows you to see the difference in metrics before and after a date.",
          },
        ],
        authorizedType: "USER",
      },
      reports: {
        title: "Report Builder",
        menuTitle: "Report Builder",
        primaryTitle: "Report Builder",
        subTitle: "Collection of Reports",
        icon: viewsInactive,
        iconCurrent: viewsActive,
        tabs: [
          {
            name: "Reports",
            title: "Reports",
          },
          // {
          //   name: "Views",
          //   title: "Views",
          // },
        ],
        authorizedType: "USER",
      },
      // "quest-suite": {
      //     title: 'Quest Suite',
      //     primaryTitle: 'Quest Suite',
      //     subTitle: 'Access our suite of products.',
      //     menuTitle: 'Quest Suite',
      //     icon: productInactive,
      //     iconCurrent: productActive,
      //     tabs: [],
      //     authorizedType: 'USER'
      // }
    };
  }

  static getModes = () => {
    return [
      { id: 0, name: "Gen Pop" },
      { id: 1, name: "Familiar" },
      { id: 2, name: "Ad Recall" },
      { id: 3, name: "User" },
    ];
  };

  static getTabs(menu) {
    let tabs = [];
    let menuItem = InitData.getMenu()[menu];
    if (menuItem) {
      tabs = InitData.getMenu()[menu].tabs;
    }
    return tabs;
  }

  static getStabOptions = () => {
    return [
      { id: 0, name: "Equity" },
      { id: 1, name: "Conversion" },
      { id: 2, name: "Emotional" },
      { id: 3, name: "Touchpoints" },
      { id: 4, name: "Advertising" },
      { id: 5, name: "Trends" },
    ];
  };

  static getMenuTitle(menu) {
    let title = "";
    let menuItem = InitData.getMenu()[menu];
    if (menuItem) {
      title = InitData.getMenu()[menu].title;
    }
    return title;
  }

  static getSortingTypes() {
    return {
      asc: "asc",
      desc: "desc",
    };
  }

  static getColumns(index) {
    let columns = [
      [
        {
          key: "firstName",
          field: "firstName",
          title: "First Name",
          width: "160px",
          sorting: "active",
        },
        {
          key: "lastName",
          field: "lastName",
          title: "Last Name",
          width: "160px",
          sorting: "inactive",
        },
        {
          key: "userGroups",
          field: "userGroups",
          title: "Groups",
          width: "290px",
          sorting: null,
        },
        {
          key: "email",
          field: "email",
          title: "Email Address",
          width: "290px",
          sorting: null,
        },
        {
          key: "verified",
          field: "verified",
          title: "Email Verified",
          width: "150px",
          sorting: "inactive",
        },
        {
          key: "phone",
          field: "phone",
          title: "Phone Number",
          width: "150px",
          sorting: null,
        },
        {
          key: "company",
          field: "company",
          title: "Company",
          width: "200px",
          sorting: "inactive",
        },
        {
          key: "dateCreated",
          field: "registered",
          title: "Registered",
          width: "150px",
          sorting: "inactive",
        },
        {
          key: "lastLoginDate",
          field: "lastAccessed",
          title: "Last Accessed",
          width: "150px",
          sorting: "inactive",
        },
      ],
      [
        {
          key: "displayName",
          field: "displayName",
          title: "Group Name",
          width: "260px",
          sorting: "inactive",
        },
        {
          key: "company",
          field: "company",
          title: "Client Company",
          width: "180px",
          sorting: "inactive",
        },
        {
          key: "internalOwners",
          field: "groupInternalOwners",
          title: "Internal Owner",
          width: "210px",
          sorting: "inactive",
        },
        {
          key: "campaignGroups",
          field: "campaignGroups",
          title: "Markets",
          width: "250px",
          sorting: null,
        },
        {
          key: "brands",
          field: "brands",
          title: "Brands",
          width: "140px",
          sorting: "inactive",
        },
        {
          key: "users",
          field: "users",
          title: "Users",
          width: "130px",
          sorting: "inactive",
        },
        {
          key: "contractsStatus",
          field: "contractsStatus",
          title: "Contracts",
          width: "170px",
          sorting: "inactive",
        },
        {
          key: "createdOn",
          field: "createdOn",
          title: "Created",
          width: "160px",
          sorting: "active",
        },
        {
          key: "lastContractDate",
          field: "lastContractDate",
          title: "Last Contract Date",
          width: "180px",
          sorting: "inactive",
        },
      ],
      [
        { key: "brand", field: "brand", title: "Brand", width: "320px" },
        {
          key: "startDate",
          field: "startDate",
          title: "Added",
          width: "170px",
        },
        { key: "endDate", field: "endDate", title: "Expires", width: "170px" },
      ],
      [
        {
          key: "salesForceId",
          field: "salesForceId",
          title: "Salesforce ID",
          width: "200px",
        },
        { key: "amount", field: "amount", title: "Amount", width: "160px" },
        {
          key: "startDate",
          field: "startDate",
          title: "Start Date",
          width: "170px",
        },
        { key: "endDate", field: "endDate", title: "End Date", width: "170px" },
      ],
      [
        {
          key: "brand",
          field: "name",
          title: "Decipher Name",
          width: "450px",
          sorting: "active",
        },
        {
          key: "displayName",
          field: "displayName",
          title: "Display Name",
          width: "300px",
          sorting: "inactive",
        },
        {
          key: "brandId",
          field: "id",
          title: "Brand ID",
          width: "160px",
          sorting: "inactive",
        },
        {
          key: "brandGroups",
          field: "brandGroups",
          title: "Groups",
          width: "290px",
          sorting: null,
        },
        {
          key: "campaignGroups",
          field: "campaignGroups",
          title: "Markets",
          width: "290px",
          sorting: null,
        },
        {
          key: "industry",
          field: "industry",
          title: "Industry",
          width: "160px",
          sorting: "inactive",
        },
        // {key:'subIndustry', field:'subIndustry', title:'Sub-Industry', width:'290px', sorting: 'inactive'},
        {
          key: "totalCompletes",
          field: "totalCompletes",
          title: "Total Completes",
          width: "160px",
          sorting: null,
        },
        {
          key: "contracts",
          field: "activeContracts",
          title: "Active Contracts",
          width: "150px",
          sorting: "inactive",
        },
        {
          key: "expired",
          field: "expired",
          title: "Expired",
          width: "150px",
          sorting: "inactive",
        },
        {
          key: "status",
          field: "status",
          title: "Decipher Status",
          width: "160px",
          sorting: "inactive",
        },
      ],
      [
        {
          key: "brand",
          field: "name",
          title: "Decipher Name",
          width: "450px",
          sorting: "active",
        },
        {
          key: "displayName",
          field: "displayName",
          title: "Display Name",
          width: "320px",
          sorting: "inactive",
        },
        {
          key: "industry",
          field: "industry",
          title: "Industry",
          width: "160px",
          sorting: null,
        },
        // {key:'subIndustry', field:'subIndustry', title:'Sub-Industry', width:'290px', sorting: null},
        {
          key: "createdBy",
          field: "createdBy",
          title: "Added By",
          width: "320px",
          sorting: "inactive",
        },
        {
          key: "createdOn",
          field: "createdOn",
          title: "Added",
          width: "160px",
          sorting: "inactive",
        },
      ],
      //campaigns admin page - active / inactive tables
      [
        {
          key: "name",
          field: "name",
          title: "Country",
          width: "250px",
          sorting: "active",
        },
        {
          key: "regionState",
          field: "regionState",
          title: "Region/State",
          width: "200px",
          sorting: "inactive",
        },
        {
          key: "campaignGroups",
          field: "groups",
          title: "Groups",
          width: "500px",
          sorting: null,
        },
        {
          key: "contractsCount",
          field: "contractsCount",
          title: "Active Contracts",
          width: "200px",
          sorting: "inactive",
        },
        {
          key: "modifiedOn",
          field: "modifiedOn",
          title: "Last Modified",
          width: "150px",
          sorting: "inactive",
        },
        {
          key: "qCampaign",
          field: "qCampaign",
          title: "QCampaign",
          width: "150px",
          sorting: null,
        },
        {
          key: "weightQuota",
          field: "weightQuota",
          title: "Weight Quota",
          width: "175px",
          sorting: null,
        },
        {
          key: "lastWeightedAt",
          field: "lastWeightedAt",
          title: "Last Weighted",
          width: "200px",
          sorting: "active",
        },
        {
          key: "totalCompletes",
          field: "totalCompletes",
          title: "Total Weighted",
          width: "175px",
          sorting: "inactive",
        },
        {
          key: "ingestedCount",
          field: "ingestedCount",
          title: "Total Ingested",
          width: "175px",
          sorting: "active",
        },
        {
          key: "weightDifference",
          field: "weightDifference",
          title: "Weighted Difference",
          width: "175px",
          sorting: null,
        },
      ],
      [
        {
          key: "brand",
          field: "name",
          title: "Decipher Name",
          width: "450px",
          sorting: "active",
        },
        {
          key: "displayName",
          field: "displayName",
          title: "Display Name",
          width: "300px",
          sorting: "inactive",
        },
        {
          key: "brandId",
          field: "brandId",
          title: "Brand ID",
          width: "160px",
          sorting: null,
        },
        {
          key: "brandGroups",
          field: "brandGroups",
          title: "Groups",
          width: "290px",
          sorting: null,
        },
        {
          key: "contracts",
          field: "activeContracts",
          title: "Active Contracts",
          width: "150px",
          sorting: "inactive",
        },
        {
          key: "expired",
          field: "expired",
          title: "Expired",
          width: "150px",
          sorting: "inactive",
        },
        {
          key: "status",
          field: "status",
          title: "Decipher Status",
          width: "160px",
          sorting: null,
        },
      ],
    ];

    return columns[index];
  }

  static getStatus(status) {
    let name = "active";
    let label = "Active";
    let priority = 0;

    switch (status) {
      default:
      case "active":
        name = "active";
        label = "Active";
        priority = 3;
        break;
      case "inactive":
        name = "inactive";
        label = "Not Active";
        priority = 0;
        break;
      case "pending":
        name = "pending";
        label = "Not verified";
        priority = 0;
        break;
      case "expired":
        name = "expired";
        label = "Expired";
        priority = 1;
        break;
      case "future":
        name = "future";
        label = "Future";
        priority = 2;
        break;
      case "verified":
        name = "verified";
        label = "Verified";
        priority = 2;
        break;
      case "notVerified":
        name = "notVerified";
        label = "Not Verified";
        priority = 1;
        break;
    }

    return { name, label, priority };
  }

  static getBrandCategory(categoriesList, id) {
    return categoriesList.find((c) => {
      return c.id === id;
    });
  }

  static getColors(index) {
    const colors = [
      ["#73CCEF", "#DCF2FB"],
      ["#747A81", "#D0D4D8"],
      ["#17BCAC", "#C5EEEA"],
      ["#B974D2", "#F7E1FF"],
      ["#6692AD", "#C9E8FC"],
      ["#FFBA03", "#FFEEBF"],
      ["#896D9D", "#E1DAE6"],
      ["#FF7F61", "#FFDFD7"],
      ["#5DD781", "#C9F7D6"],
      ["#5DA5D7", "#C9F4F7"],
    ];
    if (index && index > 0) {
      return colors[index - 1];
    } else {
      return colors;
    }
  }
  static getDonutColors() {
    return ["#73CCEF", "#50C4CD", "#095355", "#042C3B"];
  }

  static getFilterGroups() {
    if (InitData.filterGroups == null) {
      InitData.filterGroups = [];
    }
    return InitData.filterGroups;
  }

  static optionLabelsApi = null;
  static filterGroups = null;

  static getFilterOptionLabel(key, index = 0, defaultValue = "No name") {
    this.filters = JSON.parse(getLocalStorage("filters"));
    this.filters = this.filters?.filter((obj) => obj.category !== null);
    InitData.optionLabelsApi = {};
    this.filters?.length > 0 &&
      this.filters.map(({ filterResponse }) => {
        filterResponse.forEach((filter) => {
          if (filter.filterOptions) {
            filter.filterOptions.forEach((option) => {
              InitData.optionLabelsApi[
                filtersTransformations.createFilterOptionKey(
                  filter.filterName,
                  option.filterOptionName
                )
              ] = [
                  option.filterOptionName,
                  option.filterOptionName ? option.filterOptionName : "",
                ];
            });
          } else if (filter.filterGroupOptions) {
            filter.filterGroupOptions.forEach((option) => {
              InitData.optionLabelsApi[
                filtersTransformations.createFilterOptionKey(
                  filter.filterName,
                  option.filterOptionName
                )
              ] = [option.filterOptionName, option.filterOptionName];
            });
          }
          return;
        });
        return filterResponse;
      });
    return InitData.optionLabelsApi[key]
      ? InitData.optionLabelsApi[key][index] !== ""
        ? InitData.optionLabelsApi[key][index]
        : InitData.optionLabelsApi[key][0]
      : defaultValue;
  }

  static screenIcons() {
    return [
      { screenType: "EQUITY", logo: equityIcon },
      { screenType: "CONVERSION", logo: conversionIcon },
      { screenType: "EMOTIONAL", logo: emotionalIcon },
      { screenType: "ADVERTISING", logo: advertisingIcon },
      { screenType: "TRENDS", logo: trendsIcon },
      { screenType: "PROFILE", logo: profileIcon },
      { screenType: "DIFFERENTIATION", logo: differentiationIcon },
      { screenType: "PERCEPTUAL", logo: perceptualIcon },
      { screenType: "BUTTERFLY", logo: butterflyIcon },
      { screenType: "PREPOST", logo: prepostIcon },
    ];
  }

  static getPeriodOptions(movingAverage = false, exclude = []) {
    let periods = [
      { id: 1, name: "Custom Range", key: "CUSTOM" },
      { id: 2, name: "Last 7 days", key: "L7D" },
      { id: 3, name: "Last 30 days", key: "L30D" },
      { id: 4, name: "Last 90 days", key: "L90D" },
      { id: 6, name: "Last 180 days", key: "L180D" },
      { id: 5, name: "Year to Date", key: "YTD" },
    ];

    if (movingAverage) {
      exclude.push(2);
    }
    return (periods = periods.filter((p) => !exclude.includes(p.id)));
  }

  static gettrendsTabOptions() {
    return [
      { id: 0, name: "Equity" },
      { id: 1, name: "Conversion" },
      { id: 2, name: "Emotional" },
      { id: 3, name: "Advertising" },
      { id: 4, name: "Irreplaceability" },
    ];
  }

  static getIntervals(movingAverage = false, exclude = [], hideLabels = false) {
    if (movingAverage && !exclude.includes("DAYS")) {
      exclude.push("DAYS");
    }

    // TODO: Refactor when possible. [Optimization]
    let intervals = [
      {
        label: "Periodic",
        options: this.getPeriodicIntervals([...exclude]),
      },
    ];
    let intervalsWithLabels = [
      {
        label: "Periodic",
        options: this.getPeriodicIntervals([...exclude]),
      },
    ];

    if (movingAverage) {
      intervals.push(...this.getMovingAverageIntervals([...exclude]));

      intervalsWithLabels.push({
        label: "Moving Average",
        options: this.getMovingAverageIntervals([...exclude]),
      });
    }

    if (hideLabels) {
      return intervals;
    } else {
      return intervalsWithLabels;
    }
  }

  static getSurveyPaths() {
    let surveyPaths = [
      { surveyName: "MAIN", path: "/selfserve/53b/221143" },
      { surveyName: "STATE", path: "/selfserve/53b/221042" },
      { surveyName: "INTERNATIONAL", path: "/selfserve/53b/221274" },
    ];
    return surveyPaths;
  }

  // HBP-16 | Introduced Moving Average for Trends.
  // Revised standard intervals into periodic intervals.
  // ============================================
  static getPeriodicIntervals(exclude = []) {
    let intervals = [
      { id: "DAYS", name: "Days", movingAverage: false, key: "P-D" },
      { id: "WEEKS", name: "Weeks", movingAverage: false, key: "P-W" },
      { id: "MONTHS", name: "Months", movingAverage: false, key: "P-M" },
      { id: "QUARTERS", name: "Quarters", movingAverage: false, key: "P-Q" },
    ];

    return intervals.filter((i) => !exclude.includes(i.id));
  }

  // HBP-16 | Introduced Moving Average for Trends.
  // {Days}-DAYS => {Days} is the duration to calculate the moving average for. (Convert the weeks into days)
  // Moving Average is currently exclusively used for Trends as of Feb 2022.
  // ============================================
  static getMovingAverageIntervals(exclude = []) {
    let intervals = [
      // {id: "7-DAYS", name: "1 Week"},
      // {id: "14-DAYS", name: "2 Week"},
      // {id: "28-DAYS", name: "4 Week"},
      // {id: "56-DAYS", name: "8 Week"},
      // {id: "84-DAYS", name: "12 Week"}
      { id: "ONEWEEKS", name: "1 Week", key: "MV-1" },
      { id: "TWOWEEKS", name: "2 Week", key: "MV-2" },
      { id: "FOURWEEKS", name: "4 Week", key: "MV-4" },
      { id: "EIGHTWEEKS", name: "8 Week", key: "MV-8" },
      { id: "TWELVEWEEKS", name: "12 Week", key: "MV-12" },
    ];

    return intervals.filter((i) => !exclude.includes(i.id));
  }

  static getLabelsText() {
    return {
      total: "total",
      equity: "equity",
      netMomentum: "momentum",
      momentum: "momentum",
      consideration: "consideration",
      quality: "quality",
      highQuality: "quality",
      familiarity: "familiarity",
      awareness: "awareness",
      familiarity: "familiarity",
      trial: "trial",
      usage: "usage",
      recommend: "recommend",
      stylish: "stylish",
      goodValue: "goodValue",
      good_value: "goodValue",
      sociallyConscious: "sociallyConscious",
      socially_conscious: "sociallyConscious",
      simple: "simple",
      sophisticated: "sophisticated",
      premium: "premium",
      energetic: "energetic",
      unconventional: "unconventional",
      visionary: "visionary",
      classy: "classy",
      innovative: "innovative",
      customer_centric: "customerCentric",
      customerCentric: "customerCentric",
      young: "young",
      corporate: "corporate",
      intelligent: "intelligent",
      wholesome: "wholesome",
      bold: "bold",
      fun: "fun",
      smart: "smart",
      confident: "confident",
      trustworthy: "trustworthy",
      trustWorthy: "trustworthy",
      dependable: "dependable",
      practical: "practical",
      hip: "hip",
      traditional: "traditional",
      advertisingRecall: "advertisingRecall",
      tvCableSatelliteBroadcast: "tvCableSatelliteBroadcast",
      onlineStreamingVideo: "onlineStreamingVideo",
      newsEntertainmentWebsiteOrApp: "newsEntertainmentWebsiteOrApp",
      ecommerceReviewWebsiteOrApp: "ecommerceReviewWebsiteOrApp",
      postPopUponSocialMedia: "postPopUponSocialMedia",
      influencerOnSocialMedia: "influencerOnSocialMedia",
      storePhysicalLocation: "storePhysicalLocation",
      onlineGaming: "onlineGaming",
      searchEngineegGoogleBing: "searchEngineegGoogleBing",
      streamingAudioPodcast: "streamingAudioPodcast",
      radio: "radio",
      outdoor: "outdoor",
      printMedia: "printMedia",
      email: "email",
      approachable: "approachable",
      bestInClass: "bestInClass",
      knowledgeable: "knowledgeable",
      personable: "personable",
      other: "other",
      positive: "positive",
      neutral: "neutral",
      negative: "negative",
      net: "net",
      absolutely: "absolutely",
      possibly: "possibly",
      notlikely: "notlikely",
      never: "never",
      advertisingQ:"advertising_genericQ",
      irreplaceabilityQ:"irreplaceability_genericQ",
      advertisingadvertisingRecallQ:"advertising_advertisingRecallQ",
      emotionalQ:"emotional_genericQ",
      equityequityQ:"equity_totalQ",
      equitytotalQ:"equity_totalQ",
      equityqualityQ:"equity_qualityQ",
      equitymomentumQ:"equity_momentumQ",
      equityfamiliarityQ:"equity_familiaryQ",
      equityconsiderationQ:"equity_considerationQ",
      conversionawarenessQ:"conversion_awarenessQ",
      conversionfamiliarityQ:"conversion_familiarityQ",
      conversionrecommendQ:"conversion_recommendQ",
      conversiontrialQ:"conversion_trialQ",
      conversionusageQ:"conversion_usageQ",
    };
  }

  static getWayUpValue(value) {
    return value < 0 ? 0 : value;
  }

  static getAppRoutes() {
    return {
      summary: "/summary",
      segments: "/segments",
      competitive: "/competitive",
      advanced: "/advanced",
      global: "/global",
    };
  }

  static getExportFormats() {
    return {
      pdf: "pdf",
      pptx: "pptx",
      xlsx: "xlsx",
      png: "png",
      csv: "csv",
    };
  }

  static getExportFormatsOptions(exportType) {
    const exportFormats = InitData.getExportFormats();
    return [
      {
        id: 1,
        name: exportFormats.pdf,
        icon: pdfIcon,
        disabled: exportType === 2 || exportType === 3,
      },
      {
        id: 2,
        name: exportFormats.pptx,
        icon: pptIcon,
        disabled: false,
      },
      {
        id: 3,
        name: exportFormats.xlsx,
        icon: xlsIcon,
        disabled: false,
      },
      {
        id: 4,
        name: exportFormats.png,
        icon: pngIcon,
        disabled: exportType === 2 || exportType === 3,
      },
    ];
  }

  static getExportOptions(currentRoute, selectedTab) {
    const routes = InitData.getAppRoutes();
    let secondOptionLabel = "";

    switch (currentRoute) {
      case routes.summary: {
        secondOptionLabel = "All Summary Screens";
        break;
      }
      case routes.segments: {
        secondOptionLabel = "All Segment Screens";
        break;
      }
      case routes.competitive: {
        secondOptionLabel = "All Competitive Screens";
        break;
      }
      case routes.global: {
        secondOptionLabel = "All Global Screens";
        break;
      }
      case routes.advanced: {
        secondOptionLabel = "All Advanced Screens";
        break;
      }
      default:
        secondOptionLabel = "";
    }

    const options = [
      {
        id: 1,
        name: "screen",
        label: "This Screen",
      },
      {
        id: 2,
        name: "all_screens",
        label: secondOptionLabel,
      },
    ];

    return { title: "download", options };
  }

  static getProgressBarColors() {
    return {
      pathColor: "#1BA379",
      trailColorDefault: "#000",
      trailColorMain: "#DFE2EA",
    };
  }

  static getHorizontalMenuNames() {
    return {
      bar: "secondary",
      column: "primary",
    };
  }

  static getModule = () => {
    let moduleTypes = [
      { id: 1, name: "SUMMARY", displayName: "summary" },
      { id: 2, name: "DETAILED", displayName: "segments" },
      { id: 3, name: "COMPETITIVE", displayName: "competitive" },
      { id: 4, name: "GLOBAL", displayName: "global" },
      { id: 5, name: "ADVANCED", displayName: "advanced" },
    ];
    return moduleTypes;
  };

  static getModuleType = (type) => {
    let moduleTypes = this.getModule();
    let selectedType = moduleTypes.find((ele) => ele.displayName === type);
    return selectedType && selectedType?.name;
  };

  static getExportFilename(currentRoute, currentTab, currentBrand, optionId) {
    const routes = InitData.getAppRoutes();
    switch (currentRoute) {
      case routes.segments: {
        switch (optionId) {
          case 1: {
            switch (currentTab) {
              case 0:
                return `Segments-Equity-${currentBrand.name}`;
              case 1:
                return `Segments-Conversion-${currentBrand.name}`;
              case 2:
                return `Segments-Emotional-${currentBrand.name}`;
              case 3:
                return `Segments-Advertising-${currentBrand.name}`;
              case 4:
                return `Segments-Trends-${currentBrand.name}`;
              case 5:
                return `Segments-Profile-${currentBrand.name}`;
              default:
                return `filename`;
            }
          }
          case 2:
            return `All-Segment-Screens`;
          default:
            return `filename`;
        }
      }

      case routes.competitive: {
        switch (optionId) {
          case 1: {
            switch (currentTab) {
              case 0:
                return `Competitive-Equity`;
              case 1:
                return `Competitive-Conversion`;
              case 2:
                return `Competitive-Emotional`;
              case 3:
                return `Competitive-Advertising`;
              case 4:
                return `Competitive-Trends`;
              case 5:
                return `Competitive-Profile`;
              default:
                return `filename`;
            }
          }
          case 2:
            return `All-Competitive-Screens`;
          default:
            return `filename`;
        }
      }

      case routes.global: {
        switch (optionId) {
          case 1: {
            switch (currentTab) {
              case 0:
                return `Global-Equity`;
              case 1:
                return `Global-Conversion`;
              case 2:
                return `Global-Emotional`;
              case 3:
                return `Global-Advertising`;
              case 4:
                return `Global-Trends`;
              case 5:
                return `Global-Profile`;
              default:
                return `filename`;
            }
          }
          case 2:
            return `All-Global-Screens`;
          default:
            return `filename`;
        }
      }

      case routes.advanced: {
        switch (optionId) {
          case 1: {
            switch (currentTab) {
              case 0:
                return `Differentiation-${currentBrand.name}`;
              case 1:
                return `Perceptual`;
              case 2:
                return `Butterfly`;
              case 3:
                return `Pre-Post`;
              default:
                return `filename`;
            }
          }
          case 2:
            return `All-Advanced-Screens`;
          default:
            return `filename`;
        }
      }

      default:
        return "filename";
    }
  }

  static getExportTemplate(currentRoute, selectedTab, horizontalMenu) {
    const horizontalMenuNames = InitData.getHorizontalMenuNames();
    const ignoreElements = [
      "createCustomContainer",
      "highlightBar",
      "filterIconContainer",
      "horizontalBarBottom",
      "exportContainer",
      "iconContainer",
      "blueTextButton",
      "ddArrow",
      "arrowIcon",
      "filtersContainer",
      "betaBlock",

      (element) => {
        return element.classList.contains("tab") &&
          element.classList.contains("current")
          ? false
          : element.classList.contains("tab")
            ? true
            : false;
      },
    ];

    const prepareHeader = (element, type, selectedTab) => {
      const $header = document.querySelector(".headerContainer");
      let $title = document.createElement("div");
      const $logoBlock = document.createElement("div");
      const $tabsContainer = element.querySelector(".tabsContainer");
      const titleText =
        type === "competitive" && selectedTab === 2
          ? "Emotional"
          : element.querySelector(".tab.current")
            ? element.querySelector(".tab.current").textContent
            : "Summary";

      $title.innerHTML = `
                <div style="font-size: 16px; color: #71767D; font-family: sans-serif;">${type.toUpperCase()}</div>
                <div>${titleText}</div>`;

      if (type === "summary") {
        $title = $header.querySelector(".title").cloneNode(true);
      }

      $title.setAttribute(
        "style",
        "font-family: 'Silka-Bold', sans-serif; font-size: 24px; line-height: 28px; margin-left: 30px"
      );
      $tabsContainer.setAttribute(
        "style",
        "display: flex; justify-content: space-between; color: black; width: 100%; min-height: 100px;"
      );
      $logoBlock.setAttribute("style", "margin: 5px 45px 0 0;");
      const $logo = $tabsContainer.querySelector(".compassLogoPng");
      $logo.setAttribute(
        "style",
        "width: 180px; display: block; margin: 5px 45px 0 0;"
      );
      type !== "summary" && $tabsContainer.querySelector(".tabs-list").remove();
      // if (type === 'summary') {
      //     const $brand = document.querySelector('.tab.current .brandTitle').cloneNode(true)
      //     $brand.querySelector('.brandName').setAttribute('style', 'color: black;')
      //     $tabsContainer.prepend($brand)
      // }
      const $brandDropDown = element.querySelector(".brandDropDown");
      if ($brandDropDown) {
        const $brand = $brandDropDown.querySelector(".brandTitle");
        $brand
          .querySelector(".brandName")
          .setAttribute("style", "color: black;");
        if (type !== "advanced") $tabsContainer.prepend($brand);
      }
      $tabsContainer.prepend($title);

      const $brandLogoIcon = element.querySelectorAll(".brandLogoIcon");
      if ($brandLogoIcon.length)
        $brandLogoIcon.forEach((elem) =>
          elem.setAttribute(
            "style",
            "box-shadow: unset; border: 1px solid rgba(0, 0, 0, 0.15);"
          )
        );
    };

    const prepareInputBoxes = (element) => {
      const $inputBoxes = element.querySelectorAll(".inputBox");

      if ($inputBoxes.length) {
        $inputBoxes.forEach((elem) => {
          const parentEl = elem.parentElement;
          const classListArr = [
            "selectContainer",
            "mainSelectContainer",
            "secondarySelectContainer",
          ];
          const padding = classListArr.includes(parentEl.className)
            ? "20px 30px"
            : "14px 20px";
          parentEl.setAttribute("style", "display: flex;");
          elem.setAttribute(
            "style",
            `
                        min-width: unset;
                        width: unset;
                        box-shadow: unset;
                        padding: ${padding};
                    `
          );
        });
      }
    };

    const additionalWidth = () => {
      let width = 120;
      if (document.querySelector(".mainContent.filterOpen")) width = 320;
      if (document.querySelector(".contentContainer.expand")) width = 210;

      return width;
    };

    const summaryPageTemplate = (chartsDelta, getPdfTemplate) => {
      const { xOffset, yOffset } = chartsDelta;

      const mainContent = {
        element: document.querySelector(".mainContent"),
      };

      const mainContentElement = document.querySelector(".mainContent");
      mainContentElement.style.backgroundColor = "#F5F7FA";
      const equityChartW =
        document.querySelector(".equityContainer").scrollWidth;
      const lineChartW = document.querySelector(".lineChartSvg").scrollWidth;
      const summaryBottomContainerW = document.querySelector(
        ".summaryBottomContainer"
      ).scrollWidth;

      const clearDOMCallback = () => {
        if (mainContentElement) {
          mainContentElement.style.backgroundColor = "#F5F7FA";
        }
      };

      const onCloneHandler = (element) => {
        const $brandEquity = element.querySelector(
          ".chartHeaderContainer.equityTitle"
        );
        const $menuBarContainer = element.querySelector(".menuBarContainer");
        element.querySelector(".chartHeaderContainer.equityTitle").remove();
        $brandEquity.setAttribute("style", "top: 10px; position: relative;");
        $menuBarContainer.appendChild($brandEquity);
        prepareHeader(element, "summary");
        prepareInputBoxes(element);
      };

      const templateWidth =
        (summaryBottomContainerW > equityChartW + lineChartW
          ? summaryBottomContainerW
          : equityChartW + lineChartW) +
        xOffset +
        additionalWidth();
      const templateHeight = mainContentElement.scrollHeight + yOffset;

      return {
        img: {
          templateWidth,
          templateHeight,
          element: mainContent,
          ignoreElements,
          clearDOMCallback,
          onCloneHandler,
        },
        pdf: getPdfTemplate(templateWidth, templateHeight),
      };
    };

    const globalPageTemplate = (
      chartsDelta,
      selectedTab,
      getPdfTemplate,
      horizontalMenu
    ) => {
      const { xOffset, yOffset } = chartsDelta;

      const mainContent = {
        element: document.querySelector(".mainContent"),
      };

      const mainContentElement = document.querySelector(".mainContent");
      const dataItemsContainer = document.querySelector(".dataItemsContainer");
      const leftContainer = document.querySelector(
        ".barChartContainer > div[class*=LeftContainer]"
      );
      const rightContainer = document.querySelector(
        ".barChartContainer > div[class*=RightContainer]"
      );
      const barChartContent = document.querySelector(".barChartContent");
      mainContentElement.style.backgroundColor = "#F5F7FA";

      const clearDOMCallback = () => {
        if (mainContentElement) {
          mainContentElement.style.backgroundColor = "#F5F7FA";
        }
      };

      const getDataItemsWidth = () => {
        let width = 0;
        const minWidth = 800;
        dataItemsContainer
          .querySelectorAll(".cardContainer")
          .forEach((item) => {
            width += item.offsetWidth;
          });
        if (selectedTab === 5 || selectedTab === 6)
          width = dataItemsContainer.scrollWidth + 2 * xOffset;
        if (width < minWidth) width = minWidth;

        return (width += xOffset);
      };
      let templateWidth =
        (leftContainer && rightContainer
          ? leftContainer?.scrollWidth + barChartContent?.scrollWidth + xOffset
          : getDataItemsWidth()) + additionalWidth();
      let templateHeight = mainContentElement.scrollHeight + yOffset;
      let maxCardHeaderHeight = 0;

      const $cardHeaderContainer = document.querySelectorAll(
        ".cardHeaderContainer"
      );
      if ($cardHeaderContainer.length) {
        $cardHeaderContainer.forEach((item) => {
          if (item.querySelector(".filterList")) {
            item.setAttribute("style", "height: auto !important;");
            if (maxCardHeaderHeight < item.offsetHeight)
              maxCardHeaderHeight = item.offsetHeight;
            item.setAttribute("style", "");
          }
        });
        templateHeight += maxCardHeaderHeight - 120;
      }

      const onCloneHandler = (element) => {
        const scrollWrapper = element.querySelector(".scrollWrapper");
        if (scrollWrapper)
          scrollWrapper.setAttribute("style", "overflow-y: hidden;");

        const $filterList = element.querySelector(".filterList");
        const $emotionalContainer = element.querySelectorAll(
          ".cardContainer.emotionalContainer"
        );
        const $cardContainer = element.querySelectorAll(".cardContainer");
        const $chartItems = element.querySelectorAll(".chartItem");
        const $hiddenBrands = element.querySelectorAll(
          ".brandItemRightContainer.brandHidden"
        );
        const type = "global";

        element
          .querySelector(".dataItemsContainer")
          .setAttribute("style", "min-height: unset");
        element
          .querySelector(".menuBarContainer")
          .setAttribute("style", "width: 100%;");
        element
          .querySelector(".periodContainer")
          .setAttribute(
            "style",
            "margin-right: 15px; width: auto; border-top: unset;"
          );
        element
          .querySelectorAll(".cardHeaderContainer")
          .forEach((elem) =>
            elem.setAttribute(
              "style",
              "box-shadow: unset; border-bottom: 1px solid rgba(0, 0, 0, 0.05);"
            )
          );
        element
          .querySelectorAll(".brandTitle .brandDefaultLogo")
          .forEach((elem) => elem.setAttribute("style", "box-shadow: unset;"));
        if ($filterList) $filterList.setAttribute("style", "margin: 0 26px");
        if ($emotionalContainer.length) {
          $emotionalContainer.forEach((elem) =>
            elem.setAttribute("style", "min-height: unset")
          );
          $emotionalContainer.forEach((container) => {
            container.querySelectorAll("g.node").forEach((elem) => {
              const $labelText = elem.querySelector(".label-text");
              if (navigator.userAgent.includes("Chrome")) {
                $labelText.style.letterSpacing = "0.5px";
              }
              if (navigator.userAgent.includes("Firefox")) {
                $labelText.style.letterSpacing = "-1px";
              }
              $labelText.style.fontSize =
                $labelText.style.fontSize.replace("px", "") - 1;
              $labelText.style.textAlign = "center";
            });
          });
        }
        if ($cardContainer.length) {
          $cardContainer.forEach((elem) => {
            if (elem.querySelector(".filterList")) {
              const $cardHeaderContainer = elem.querySelector(
                ".cardHeaderContainer"
              );
              const styles = $cardHeaderContainer.getAttribute("style");
              $cardHeaderContainer.setAttribute(
                "style",
                `${styles} height: ${maxCardHeaderHeight}px !important; position: relative;`
              );
              elem.setAttribute(
                "style",
                "min-height: unset; padding-top: 0px;"
              );
            }
          });
        }
        if ($chartItems.length) {
          $chartItems.forEach((item) => {
            const clArr = item.style.backgroundColor.split("");
            clArr.splice(clArr.length - 1, 0, `, ${item.style.opacity}`);
            item.style.backgroundColor = clArr.join("").replace("rgb", "rgba");
          });
        }
        if ($hiddenBrands.length) {
          $hiddenBrands.forEach((item) => {
            item
              .querySelector(".brandName")
              .setAttribute("style", "color: rgba(0, 0, 0, 0.5);");
            item
              .querySelector(".showOrHideIcon")
              .setAttribute("style", "width: 20px;");
          });
        }
        if (type === "global") {
          const $horizontalBarContainer = element.querySelector(
            ".horizontalBarContainer"
          );
          const $filterList =
            $horizontalBarContainer.querySelector(".filterList");
          $filterList.setAttribute("style", "position: absolute; left: 10px;");
          $horizontalBarContainer
            .querySelector(".periodPickerContainer")
            .prepend($filterList);
        }
        if (type === "advanced") {
          const $horizontalBarContainer = element.querySelector(
            ".horizontalBarContainer"
          );
          const $filterList =
            $horizontalBarContainer.querySelector(".filterList");
          $filterList.setAttribute("style", "position: absolute; left: 10px;");
          $horizontalBarContainer
            .querySelector(".periodPickerContainer")
            .prepend($filterList);
        }
        if (selectedTab === 1)
          element
            .querySelector(".dataItemsContainer")
            .setAttribute("style", "width: 102%;");
        // if (selectedTab === 2 || selectedTab === 3) element.querySelectorAll('.bottomContainer').forEach(elem => elem.remove())
        prepareHeader(element, type, selectedTab);
        prepareInputBoxes(element);
      };

      const imgTemplate = {
        templateWidth,
        templateHeight,
        element: mainContent,
        clearDOMCallback,
        ignoreElements,
        onCloneHandler,
      };

      switch (selectedTab) {
        case 0:
        case 1:
        case 5:
        case 6:
          return {
            img: imgTemplate,
            pdf: getPdfTemplate(
              imgTemplate.templateWidth,
              imgTemplate.templateHeight
            ),
          };

        case 2:
        case 3: {
          if (horizontalMenu === horizontalMenuNames.column) {
            return {
              img: imgTemplate,
              pdf: getPdfTemplate(
                imgTemplate.templateWidth,
                imgTemplate.templateHeight
              ),
            };
          } else {
            const barChart = document.querySelector(".barChartContainer svg");
            const equityLeftContainer = document.querySelector(
              ".equityLeftContainer"
            );
            const templateWidth =
              equityLeftContainer.scrollWidth + barChart.scrollWidth + xOffset;

            return {
              img: {
                ...imgTemplate,
                templateWidth,
              },
              pdf: getPdfTemplate(templateWidth, imgTemplate.templateHeight),
            };
          }
        }
        case 4: {
          const trendsLeftContainer = document.querySelector(
            ".trendsLeftContainer"
          );
          const lineChartSvg = document.querySelector(".lineChartSvg");
          const templateWidth =
            trendsLeftContainer.scrollWidth +
            lineChartSvg.scrollWidth +
            xOffset +
            additionalWidth();
          return {
            img: {
              ...imgTemplate,
              templateWidth,
            },
            pdf: getPdfTemplate(templateWidth, imgTemplate.templateHeight),
          };
        }

        default:
          return {};
      }
    };

    const competitiveIntelPageTemplate = (
      chartsDelta,
      selectedTab,
      getPdfTemplate,
      horizontalMenu
    ) => {
      const { xOffset, yOffset } = chartsDelta;

      const mainContent = {
        element: document.querySelector(".mainContent"),
      };

      const mainContentElement = document.querySelector(".mainContent");
      const dataItemsContainer = document.querySelector(".dataItemsContainer");
      const leftContainer = document.querySelector(
        ".barChartContainer > div[class*=LeftContainer]"
      );
      const rightContainer = document.querySelector(
        ".barChartContainer > div[class*=RightContainer]"
      );
      const barChartContent = document.querySelector(".barChartContent");
      mainContentElement.style.backgroundColor = "#F5F7FA";

      const clearDOMCallback = () => {
        if (mainContentElement) {
          mainContentElement.style.backgroundColor = "#F5F7FA";
        }
      };

      const getDataItemsWidth = () => {
        let width = 0;
        const minWidth = 800;
        dataItemsContainer
          .querySelectorAll(".cardContainer")
          .forEach((item) => {
            width += item.offsetWidth;
          });
        if (selectedTab === 5 || selectedTab === 6)
          width = dataItemsContainer.scrollWidth + 2 * xOffset;
        if (width < minWidth) width = minWidth;

        return (width += xOffset);
      };

      let templateWidth =
        (leftContainer && rightContainer
          ? leftContainer?.scrollWidth +
          barChartContent?.scrollWidth +
          xOffset +
          additionalWidth()
          : getDataItemsWidth()) + additionalWidth();
      let templateHeight = mainContentElement.scrollHeight;
      let maxCardHeaderHeight = 0;
      const $cardHeaderContainer = document.querySelectorAll(
        ".cardHeaderContainer"
      );
      if ($cardHeaderContainer.length) {
        $cardHeaderContainer.forEach((item) => {
          if (item.querySelector(".filterList")) {
            item.setAttribute("style", "height: auto !important;");
            if (maxCardHeaderHeight < item.offsetHeight)
              maxCardHeaderHeight = item.offsetHeight;
            item.setAttribute("style", "");
          }
        });
        templateHeight += maxCardHeaderHeight - 120;
      }

      const onCloneHandler = (element) => {
        const scrollWrapper = element.querySelector(".scrollWrapper");
        if (scrollWrapper)
          scrollWrapper.setAttribute("style", "overflow-y: hidden;");

        const $filterList = element.querySelector(".filterList");
        const $emotionalContainer = element.querySelectorAll(
          ".cardContainer.emotionalContainer"
        );
        const $cardContainer = element.querySelectorAll(".cardContainer");
        const $chartItems = element.querySelectorAll(".chartItem");
        const $hiddenBrands = element.querySelectorAll(
          ".brandItemRightContainer.brandHidden"
        );
        const type = element.querySelector(".pageContent.segmentsPage")
          ? "segments"
          : "competitive";

        element
          .querySelector(".dataItemsContainer")
          .setAttribute("style", "min-height: unset");
        element
          .querySelector(".menuBarContainer")
          .setAttribute("style", "width: 100%;");
        element
          .querySelector(".periodContainer")
          .setAttribute(
            "style",
            "margin-right: 15px; width: auto; border-top: unset;"
          );
        element
          .querySelectorAll(".cardHeaderContainer")
          .forEach((elem) =>
            elem.setAttribute(
              "style",
              "box-shadow: unset; border-bottom: 1px solid rgba(0, 0, 0, 0.05);"
            )
          );
        element
          .querySelectorAll(".brandTitle .brandDefaultLogo")
          .forEach((elem) => elem.setAttribute("style", "box-shadow: unset;"));
        if ($filterList) $filterList.setAttribute("style", "margin: 0 26px");
        if ($emotionalContainer.length) {
          $emotionalContainer.forEach((elem) =>
            elem.setAttribute("style", "min-height: unset")
          );
          $emotionalContainer.forEach((container) => {
            container.querySelectorAll("g.node").forEach((elem) => {
              const $labelText = elem.querySelector(".label-text");
              if (navigator.userAgent.includes("Chrome")) {
                $labelText.style.letterSpacing = "0.5px";
              }
              if (navigator.userAgent.includes("Firefox")) {
                $labelText.style.letterSpacing = "-1px";
              }
              $labelText.style.fontSize =
                $labelText.style.fontSize.replace("px", "") - 1;
              $labelText.style.textAlign = "center";
            });
          });
        }
        if ($cardContainer.length) {
          $cardContainer.forEach((elem) => {
            if (elem.querySelector(".filterList")) {
              const $cardHeaderContainer = elem.querySelector(
                ".cardHeaderContainer"
              );
              const styles = $cardHeaderContainer.getAttribute("style");
              $cardHeaderContainer.setAttribute(
                "style",
                `${styles} height: ${maxCardHeaderHeight}px !important; position: relative;`
              );
              elem.setAttribute(
                "style",
                "min-height: unset; padding-top: 0px;"
              );
            }
          });
        }
        if ($chartItems.length) {
          $chartItems.forEach((item) => {
            const clArr = item.style.backgroundColor.split("");
            clArr.splice(clArr.length - 1, 0, `, ${item.style.opacity}`);
            item.style.backgroundColor = clArr.join("").replace("rgb", "rgba");
          });
        }
        if ($hiddenBrands.length) {
          $hiddenBrands.forEach((item) => {
            item
              .querySelector(".brandName")
              .setAttribute("style", "color: rgba(0, 0, 0, 0.5);");
            item
              .querySelector(".showOrHideIcon")
              .setAttribute("style", "width: 20px;");
          });
        }
        if (type === "competitive") {
          const $horizontalBarContainer = element.querySelector(
            ".horizontalBarContainer"
          );
          const $filterList =
            $horizontalBarContainer.querySelector(".filterList");
          $filterList.setAttribute("style", "position: absolute; left: 10px;");
          $horizontalBarContainer
            .querySelector(".periodPickerContainer")
            .prepend($filterList);
        }
        if (selectedTab === 1)
          element
            .querySelector(".dataItemsContainer")
            .setAttribute("style", "width: 102%;");
        // if (selectedTab === 2 || selectedTab === 3) element.querySelectorAll('.bottomContainer').forEach(elem => elem.remove())
        prepareHeader(element, type, selectedTab);
        prepareInputBoxes(element);
      };

      const imgTemplate = {
        templateWidth,
        templateHeight,
        element: mainContent,
        clearDOMCallback,
        ignoreElements,
        onCloneHandler,
      };
      switch (selectedTab) {
        case 0:
        case 1:
        case 5:
        case 6:
          return {
            img: imgTemplate,
            pdf: getPdfTemplate(
              imgTemplate.templateWidth,
              imgTemplate.templateHeight
            ),
          };

        case 2:
        case 3: {
          if (horizontalMenu === horizontalMenuNames.column) {
            return {
              img: imgTemplate,
              pdf: getPdfTemplate(
                imgTemplate.templateWidth,
                imgTemplate.templateHeight
              ),
            };
          } else {
            const barChart = document.querySelector(".barChartContainer svg");
            const equityLeftContainer = document.querySelector(
              ".equityLeftContainer"
            );
            const templateWidth =
              equityLeftContainer.scrollWidth + barChart.scrollWidth + xOffset;

            return {
              img: {
                ...imgTemplate,
                templateWidth,
              },
              pdf: getPdfTemplate(templateWidth, imgTemplate.templateHeight),
            };
          }
        }
        case 4: {
          const trendsLeftContainer = document.querySelector(
            ".trendsLeftContainer"
          );
          const lineChartSvg = document.querySelector(".lineChartSvg");
          const templateWidth =
            trendsLeftContainer.scrollWidth +
            lineChartSvg.scrollWidth +
            xOffset +
            additionalWidth();
          return {
            img: {
              ...imgTemplate,
              templateWidth,
            },
            pdf: getPdfTemplate(templateWidth, imgTemplate.templateHeight),
          };
        }

        default:
          return {};
      }
    };

    const advancedAnalyticsPageTemplate = (
      chartsDelta,
      selectedTab,
      getPdfTemplate
    ) => {
      const { xOffset, yOffset } = chartsDelta;

      const mainContent = {
        element: document.querySelector(".mainContent"),
      };

      const mainContentElement = document.querySelector(".mainContent");
      mainContentElement.style.backgroundColor = "#F5F7FA";

      const clearDOMCallback = () => {
        const cardHeaderContainersFictive = document.querySelectorAll(
          ".cardHeaderContainer.fictive"
        );
        [].slice
          .call(cardHeaderContainersFictive)
          .forEach(
            (element) => element && element.parentNode.removeChild(element)
          );
        if (mainContentElement) {
          mainContentElement.style.backgroundColor = "#F5F7FA";
        }
      };

      const onCloneHandler = (element) => {
        const $differentiationItems = element.querySelectorAll(
          ".differentiationItem"
        );
        const $logoIcons = element.querySelectorAll(".map .logoIcon");
        const $prePost = element.querySelector(".periodContainer.prePost");
        const $periodContainer = element.querySelector(".periodContainer");
        const $ButterflyMapContainer = element.querySelector(
          ".ButterflyMapContainer"
        );

        if ($periodContainer)
          $periodContainer.setAttribute(
            "style",
            "margin-right: 25px; width: auto; border-top: unset;"
          );
        if ($logoIcons.length)
          $logoIcons.forEach((elem) =>
            elem.setAttribute("style", "border-radius: 16px; padding: unset")
          );
        if ($differentiationItems.length)
          $differentiationItems.forEach((elem) => {
            elem.style.boxShadow = "unset";
            if (elem.classList.contains("logoImg"))
              elem.style.border = "1px solid rgba(0, 0, 0, 0.15)";
          });
        // if ($prePost) {
        //     $prePost.setAttribute('style', 'margin-right: 15px; width: auto; border-top: unset; display: flex; justify-content: flex-end;')
        //     $prePost.querySelectorAll('.sSize').forEach(elem => elem.remove())
        //     $prePost.querySelectorAll('.periodIcon').forEach(elem => elem.remove())
        //     $prePost.querySelectorAll('.customInputDate').forEach(elem => elem.remove())

        //     $prePost.querySelectorAll('.periodPickerContainer').forEach((elem, index) => {
        //         if (!index) elem.setAttribute('style', 'padding-left: 0; border-right: black 1px solid;')
        //         elem.setAttribute('style', 'padding-left: 0;')
        //     })
        // }
        if ($ButterflyMapContainer) {
          $ButterflyMapContainer.setAttribute(
            "style",
            "min-height: unset; height: unset"
          );
          $ButterflyMapContainer
            .querySelector(".map")
            .setAttribute("style", "overflow: unset; height: unset;");
          $ButterflyMapContainer
            .querySelectorAll(".map > div")
            .forEach((elem) =>
              elem.setAttribute(
                "style",
                "position: relative; top: unset; right: unset;"
              )
            );
          $ButterflyMapContainer
            .querySelectorAll(".map > div.title")
            .forEach((elem) =>
              elem.setAttribute(
                "style",
                "padding: 20px 40px 20px; position: relative; top: unset; right: unset;"
              )
            );
        }

        prepareHeader(element, "advanced", selectedTab);
        prepareInputBoxes(element);
      };

      const templateWidth =
        mainContentElement.scrollWidth +
        xOffset +
        (document.querySelector(".contentContainer.expand") &&
          additionalWidth());
      let templateHeight = mainContentElement.scrollHeight + yOffset;

      const $ButterflyMapContainer = mainContentElement.querySelector(
        ".ButterflyMapContainer"
      );

      // Get height from the real DOM
      if ($ButterflyMapContainer) {
        // Modify
        $ButterflyMapContainer.setAttribute(
          "style",
          "min-height: unset; height: unset"
        );
        $ButterflyMapContainer
          .querySelector(".map")
          .setAttribute("style", "overflow: unset; height: unset;");
        mainContentElement
          .querySelector(".advancedAnalyticContainer")
          .setAttribute("style", "min-height: unset; height: unset");
        const styles = [];
        $ButterflyMapContainer
          .querySelectorAll(".map > div")
          .forEach((elem) => {
            styles.push(elem.style.cssText);
            return elem.setAttribute(
              "style",
              "position: relative; top: unset; right: unset;"
            );
          });
        templateHeight = mainContentElement.scrollHeight + yOffset;
        // Restore
        $ButterflyMapContainer.setAttribute("style", "");
        $ButterflyMapContainer.querySelector(".map").setAttribute("style", "");
        mainContentElement
          .querySelector(".advancedAnalyticContainer")
          .setAttribute("style", "");
        $ButterflyMapContainer
          .querySelectorAll(".map > div")
          .forEach((elem, index) => elem.setAttribute("style", styles[index]));
      }

      switch (selectedTab) {
        case 0:
        case 1:
        case 2: {
          return {
            img: {
              templateWidth,
              templateHeight,
              element: mainContent,
              ignoreElements,
              clearDOMCallback,
              onCloneHandler,
            },
            pdf: getPdfTemplate(templateWidth, templateHeight),
          };
        }
        case 3: {
          const barChart = document.querySelector(".barChartContainer svg");
          const templateWidth =
            barChart.scrollWidth + xOffset + additionalWidth();
          const index = ignoreElements.indexOf("iconContainer");
          if (index !== -1) {
            ignoreElements.splice(index, 1);
          }

          return {
            img: {
              templateWidth,
              templateHeight,
              element: mainContent,
              ignoreElements,
              clearDOMCallback,
              onCloneHandler,
            },
            pdf: getPdfTemplate(templateWidth, templateHeight),
          };
        }
        default: {
          return {};
        }
      }
    };

    const initExportTemplate = (currentRoute, selectedTab, horizontalMenu) => {
      const getPdfTemplate = (imgWidth, imgHeight) => {
        const ratio = imgWidth / imgHeight;

        return {
          orientation: ratio > 1 ? "l" : "p",
          unit: "px",
          format: "a4",
          xOffset: 0,
          yOffset: 0,
          imageFormat: "PNG",
          ratio,
          width: imgWidth,
          height: imgHeight,
        };
      };

      const routes = InitData.getAppRoutes();

      let xOffset = 0;
      let yOffset = 0;

      const yCorrection = () => {
        return document.querySelector(".horizontalBarContainer").offsetHeight >
          100
          ? -80
          : 0;
      };

      const yScrollCorrection = () => {
        // const containerHeight = document.querySelector('.dataItemsContainer').offsetHeight;
        const scrollWrapperHeight =
          document.querySelector(".scrollWrapper").offsetHeight;
        // const scrollWrapperTop = document.querySelector('.scrollWrapper').offsetTop;

        return scrollWrapperHeight;
      };

      if (currentRoute === routes.summary) {
        xOffset = 200;
        yOffset = -20;
      }

      if (currentRoute === routes.segments) {
        yOffset = yCorrection();
        if (selectedTab === 0 || horizontalMenu === horizontalMenuNames.bar) {
          xOffset = 110;
        } else if (selectedTab === 1) {
          xOffset = 110;
          yOffset = -60 + yCorrection();
        } else if (selectedTab === 2) {
          xOffset = 40;
        } else if (selectedTab === 3) {
          xOffset = 110;
          // yOffset = -1200 + yCorrection()
        } else if (selectedTab === 4) {
          xOffset = 200;
        } else if (selectedTab === 6) {
          xOffset = 40;
        }
      }

      if (currentRoute === routes.competitive) {
        yOffset = yCorrection();
        if (
          selectedTab === 0 ||
          selectedTab === 1 ||
          horizontalMenu === horizontalMenuNames.bar
        ) {
          xOffset = 110;
        } else if (selectedTab === 2) {
          xOffset = 150;
        } else if (selectedTab === 3) {
          xOffset = 160;
          // yOffset = -1100 + yCorrection()
        } else if (selectedTab === 4) {
          xOffset = 200;
        } else if (selectedTab === 6) {
          xOffset = 40;
        }
      }

      if (currentRoute === routes.global) {
        yOffset = yCorrection();
        if (
          selectedTab === 0 ||
          selectedTab === 1 ||
          horizontalMenu === horizontalMenuNames.bar
        ) {
          xOffset = 110;
        } else if (selectedTab === 2) {
          xOffset = 40;
        } else if (selectedTab === 3) {
          xOffset = 110;
          // yOffset = -1100 + yCorrection()
        } else if (selectedTab === 4) {
          xOffset = 200;
        } else if (selectedTab === 6) {
          xOffset = 40;
        }
      }

      if (currentRoute === routes.advanced) {
        xOffset = 100;
        yOffset = 80 + yCorrection();
        if (selectedTab === 2) {
          xOffset = 200;
          yOffset = 110 + yCorrection();
        }
        if (selectedTab === 3) {
          xOffset = 190;
        }
      }

      const chartsDelta = {
        xOffset: xOffset,
        yOffset: yOffset,
      };

      switch (currentRoute) {
        case routes.summary:
          return summaryPageTemplate(chartsDelta, getPdfTemplate);

        case routes.segments:
          return competitiveIntelPageTemplate(
            chartsDelta,
            selectedTab,
            getPdfTemplate,
            horizontalMenu
          );

        case routes.competitive:
          return competitiveIntelPageTemplate(
            chartsDelta,
            selectedTab,
            getPdfTemplate,
            horizontalMenu
          );

        case routes.global:
          return globalPageTemplate(
            chartsDelta,
            selectedTab,
            getPdfTemplate,
            horizontalMenu
          );

        case routes.advanced:
          return advancedAnalyticsPageTemplate(
            chartsDelta,
            selectedTab,
            getPdfTemplate
          );

        default:
          return {};
      }
    };

    return initExportTemplate(currentRoute, selectedTab, horizontalMenu);
  }

  static getExportModes() {
    return {
      summaryAll: "summary_all",
      detailedAll: "detailed_all",
      competitiveAll: "competitive_all",
      globalAll: "global_all",
      advancedAll: "advanced_all",
      dashboardsAll: "dashboards_all",
    };
  }

  static getUserData() {
    const modes = {
      create: "create",
      profileUpdate: "profile_update",
      profileDisplay: "profile_display",
    };

    return {
      title: (mode) => (mode === modes.create ? "Add New User" : ""),
      subtitle: (mode) =>
        mode === modes.create
          ? "Create a new User for HQB Dashboard"
          : mode === modes.profileDisplay || mode === modes.profileUpdate
            ? "User ID:"
            : "",
      modes,
      form: {
        firstName: {
          key: "firstname",
          label: "First Name",
          placeholder: "First Name",
        },
        lastName: {
          key: "lastname",
          label: "Last Name",
          placeholder: "Last Name",
        },
        email: {
          key: "email",
          label: "Email Address",
          placeholder: "email@address.com",
          inputMessages: {
            validEmail: "Please enter a valid email address",
          },
        },
        phoneNumber: {
          key: "phone",
          label: "Phone Number",
          placeholder: "000-000-0000",
        },
        password: {
          key: "password",
          label: "Password",
          placeholder: "Password",
          inputMessages: {
            validPassword:
              "Password must be of minimum 8 characters, with combination of one uppercase character, numeric and special character",
          },
        },
        company: {
          key: "company",
          label: "Company",
          placeholder: "Company",
        },
        position: {
          key: "position",
          label: "Position",
          placeholder: "Position",
        },
        groups: {
          key: "groups",
          label: "Groups",
          placeholder: "Search for Group to add to…",
        },
        userRoles: {
          key: "role",
          label: "Role",
          placeholder: "Role",
          options:
            window.location.pathname === "/adduser"
              ? [
                { id: 1, name: "QuestBrand User", type: "USER" },
                hasPermission()
                  ? { id: 2, name: "QuestBrand Admin", type: "ADMIN" }
                  : { id: 1 },
              ]
              : [
                { id: 1, name: "QuestBrand User", type: "USER" },
                { id: 2, name: "QuestBrand Admin", type: "ADMIN" },
              ],
        },
      },
      buttons: {
        cancel: {
          key: "cancel",
          label: "Cancel",
        },
        add: {
          key: "add_user",
          label: (mode) =>
            mode === modes.create
              ? "Add New User"
              : mode === modes.profileUpdate
                ? "Update User"
                : "",
        },
        addToGroup: {
          key: "add_to_group",
          label: "Add to Group",
        },
      },
      inputMessages: {
        emailNotEdited: "Email address cannot be edited.",
      },
      modals: {
        removeUser: {
          title: "Delete User",
          message: "Are you sure you want to delete the following user?",
          extraMessage: "This is a permanent action.",
          buttons: [{ name: "Cancel" }, { name: "Delete User" }],
        },
      },
      table: {
        title: "Groups",
        columns: [
          {
            key: "displayName",
            title: "Group Name",
            width: "160px",
            clickable: true,
          },
          { key: "usersAmount", title: "# of Users", width: "160px" },
          { key: "brandsAmount", title: "# of Brands", width: "160px" },
          { key: "added", title: "Added", width: "160px" },
        ],
        noGroupsTitle: "Uh,oh. No Groups.",
        noGroupMessage:
          "User is not currently part of any groups. Add to a group in order to grant access to brands",
      },
    };
  }

  static getGroupData() {
    const modes = {
      create: "create",
      profileUpdate: "profile_update",
      profileDisplay: "profile_display",
    };
    return {
      title: (mode) => (mode === modes.create ? "Add New Group" : ""),
      subtitle: (mode) =>
        mode === modes.create
          ? "Create a new Group for HQB Users"
          : mode === modes.profileDisplay || mode === modes.profileUpdate
            ? "Group ID:"
            : "",
      modes,
      form: {
        groupName: {
          key: "groupname",
          label: "Group Name",
          placeholder: "Group Name",
        },
        company: {
          key: "company",
          label: "Client Company",
          placeholder: "Search for Client Company",
        },
        internalOwners: {
          key: "internalOwners",
          label: "Internal Owner",
          placeholder: "Search for Internal Owner",
        },
        users: {
          key: "users",
          label: "Users",
          placeholder: "Search for Users to add…",
        },
        brands: {
          key: "brands",
          label: "Active Brands",
          placeholder: "",
          primaryBrand: "Primary Brand",
        },
      },
      buttons: {
        cancel: {
          key: "cancel",
          label: "Cancel",
        },
        add: {
          key: "add_group",
          label: (mode) =>
            mode === modes.create
              ? "Add New Group"
              : mode === modes.profileUpdate
                ? "Update Group"
                : "",
        },
        addToGroup: {
          key: "add_to_group",
          label: "Add User",
        },
      },
      modals: {
        removeGroup: {
          title: "Delete Group",
          message: "Are you sure you want to delete the following group?",
          extraMessage: "This is a permanent action.",
          buttons: [{ name: "Cancel" }, { name: "Delete Group" }],
        },
        removeUser: {
          title: "Remove User",
          message: "Are you sure you want to remove the user from this group?",
          extraMessage: "They will loose access to dashboard and brand data.",
          buttons: [{ name: "Cancel" }, { name: "Remove User" }],
        },
      },
      table: {
        title: "Users",
        columns: [
          {
            key: "email",
            title: "Email Address",
            width: "470px",
            clickable: true,
          },
          { key: "firstname", title: "First Name", width: "160px" },
          { key: "lastname", title: "Last Name", width: "160px" },
          { key: "added", title: "Added", width: "160px" },
        ],
        noGroupsTitle: "Uh,oh. No Users.",
        noGroupMessage:
          "There are no users in this group. Add users to grant access to brands available in this group.",
      },
    };
  }

  static getContractData() {
    const modes = {
      create: "create",
      profileUpdate: "profile_update",
      profileCopy: "profile_copy",
      profileDisplay: "profile_display",
    };
    return {
      title: (mode) => (mode === modes.create ? "Add New Contract" : ""),
      subtitle: (mode) =>
        mode === modes.create || mode === modes.profileCopy
          ? "Create a new Contract for the following group"
          : mode === modes.profileDisplay || mode === modes.profileUpdate
            ? "Contract ID: %{id}% | Last Updated By: %{userName}% | Last Updated Date: %{updatedDate}%"
            : "",
      modes,
      form: {
        contractName: {
          key: "contract_name",
          label: "Contract Name",
          placeholder: "Contract Name",
        },
        purpose: {
          key: "purpose",
          label: "Purpose",
          placeholder: "Purpose",
          options: [
            { id: 1, name: "Lead / Demo" },
            { id: 2, name: "Trial / Pilot" },
            { id: 3, name: "Client Sale" },
            { id: 4, name: "Client Sale Add On" },
            { id: 5, name: "Client Sale Renewal" },
            { id: 12, name: "Client Sub Portal" },
            { id: 6, name: "Custom Study Value Add" },
            { id: 7, name: "Marketing Initiative" },
            { id: 8, name: "Stagwell Agency Pitch" },
            { id: 9, name: "Stagwell Agency Client" },
            { id: 10, name: "Internal Need" },
            { id: 11, name: "Other" },
          ],
        },
        salesforceId: {
          key: "salesforce_id",
          label: "Salesforce ID",
          placeholder: "Salesforce ID #",
        },
        amount: {
          key: "amount",
          label: "Amount",
          placeholder: "$0.00",
        },
        cost: {
          key: "cost",
          label: "Cost",
          placeholder: "$0",
          inputMessages: {
            validCost: "Cost should not be greater than 9,999,999,999.99",
          },
        },
        startDate: {
          key: "start_date",
          label: "Start Date",
          placeholder: "Start Date",
          warnings: {
            duration:
              "Length of this contract falls outside of standard guidelines - Contact Ops for support",
            extension:
              "The extension of this contract falls outside of standard guidelines - Contact Ops for support",
          },
        },
        endDate: {
          key: "end_date",
          label: "End Date",
          placeholder: "End Date",
          warnings: {
            duration:
              "Length of this contract falls outside of standard guidelines - Contact Ops for support",
            extension:
              "The extension of this contract falls outside of standard guidelines - Contact Ops for support",
          },
        },
        brands: {
          key: "brands",
          label: "Contract Brands",
          placeholder: "Search for Brands to add...",
          primaryBrand: "Primary Brand",
          warnings: {
            activation:
              "Updating the contract may cause the brand to start fielding - Contact Ops for support",
            reactivation:
              "Updating the contract may cause the brand to start fielding - Contact Ops for support",
            deactivation:
              "Updating the contract may cause the brand to stop fielding - Contact Ops for support",
          },
        },
        campaigns: {
          key: "geographies",
          label: "Contract Markets",
          placeholder: "Search for Markets to add...",
        },
      },
      buttons: {
        cancel: {
          key: "cancel",
          label: "Cancel",
        },
        add: {
          key: "add_contract",
          label: (mode) =>
            mode === modes.create
              ? "Add New Contract"
              : mode === modes.profileUpdate
                ? "Update Contract"
                : modes.profileCopy
                  ? "Add New Contract"
                  : "",
        },
      },
      modals: {
        suspendContract: {
          title: "Suspend Contract",
          message: "Are you sure you want to suspend the following contract?",
          extraMessage: "This is a permanent action.",
          buttons: [{ name: "Cancel" }, { name: "Suspend Contract" }],
        },
      },
      header: {
        extraInfo: {
          status: "Group: ",
          message: "",
        },
      },
    };
  }

  static getBrandData() {
    const modes = {
      create: "create",
      profileUpdate: "profile_update",
      profileDisplay: "profile_display",
    };
    return {
      title: (mode) => (mode === modes.create ? "Add New Brand" : ""),
      subtitle: (mode) =>
        mode === modes.create
          ? "Create a new Brand for HQB platform"
          : mode === modes.profileDisplay || mode === modes.profileUpdate
            ? "Brand ID:"
            : "",
      modes,
      form: {
        decipherName: {
          key: "decipher_name",
          label: "Decipher Name",
          placeholder: "Decipher Name",
        },
        decipherId: {
          key: "decipher_id",
          label: "Decipher ID",
          placeholder: "Decipher ID",
        },
        displayName: {
          key: "display_name",
          label: "Display Name",
          placeholder: "Display Name",
        },
        industry: {
          key: "industry",
          label: "Industry",
          placeholder: "Industry",
        },
        // subIndustry: {
        //     key: 'sub_industry',
        //     label: 'Sub-Industry',
        //     placeholder: 'Sub-Industry',
        // },
        logo: {
          key: "logo",
          label: "Logo",
          placeholder: "Logo",
        },
      },
      buttons: {
        cancel: {
          key: "cancel",
          label: "Cancel",
        },
        add: {
          key: "add_brand",
          label: (mode) =>
            mode === modes.create
              ? "Add New Brand"
              : mode === modes.profileUpdate
                ? "Update Brand"
                : "",
        },
      },
      modals: {
        removeBrand: {
          title: "Delete Brand",
          message: "Are you sure you want to delete the following brand?",
          extraMessage: "This is a permanent action.",
          buttons: [{ name: "Cancel" }, { name: "Delete Brand" }],
        },
        updateBrand: {
          title: "Update Brand",
          message:
            "Are you sure you want to update the following brand details?",
          buttons: [{ name: "Cancel" }, { name: "Proceed" }],
        },
      },
      table: [
        {
          title: "Contracts",
          columns: [
            {
              key: "brandContractName",
              field: "brandContractName",
              title: "Contract Name",
              width: "160px",
              sorting: "active",
            },
            {
              key: "brandGroupName",
              field: "brandGroupName",
              title: "Group",
              width: "160px",
              sorting: "inactive",
            },
            {
              key: "campaignGroups",
              field: "campaignGroups",
              title: "Markets",
              width: "160px",
              sorting: "inactive",
            },
            {
              key: "startDate",
              field: "startDate",
              title: "Start Date",
              width: "160px",
              sorting: "inactive",
            },
            {
              key: "endDate",
              field: "endDate",
              title: "End Date",
              width: "160px",
              sorting: "inactive",
            },
            {
              key: "quota",
              field: "sampleSize",
              title: "Sample Size",
              width: "160px",
              sorting: "inactive",
            },
          ],
          noGroupsTitle: "Uh,oh. No Contracts.",
          noGroupMessage: "There are no contracts for this brand.",
        },
        {
          title: "Markets",
          columns: [
            {
              key: "campaignCountry",
              field: "campaignCountry",
              title: "Country",
              width: "160px",
              sorting: "active",
            },
            {
              key: "campaignRegion",
              field: "campaignRegion",
              title: "Region/State",
              width: "160px",
              sorting: null,
            },
            {
              key: "completes",
              field: "completes",
              title: "Completes",
              width: "160px",
              sorting: "inactive",
            },
            {
              key: "sampleSize",
              field: "sampleSize",
              title: "Sample Size",
              width: "160px",
              sorting: "inactive",
            },
            // {key: 'status', title: 'Status', width: '160px'}
          ],
          noGroupsTitle: "Uh,oh. No Markets.",
          noGroupMessage: "There are no Markets for this brand.",
        },
      ],
      inputMessages: {
        decipherNameNotEdited: "Cannot edit Decipher Name.",
        decipherIdNotEdited: "Cannot edit Decipher ID.",
      },
    };
  }

  static getCampaignFieldsAttributes() {
    const modes = {
      profileUpdate: "profile_update",
      profileDisplay: "profile_display",
      profileCreate: "profile_create",
    };
    return {
      modes,
      form: {
        name: {
          key: "name",
          label: "Name",
          placeholder: "Market name",
        },
        regionState: {
          key: "regionState",
          label: "Region/State",
          placeholder: "Region/State",
        },
        totalCompletes: {
          key: "totalCompletes",
          label: "Total Weighted",
          placeholder: "--",
        },
        decipherSurvey: {
          key: "decipherSurvey",
          label: "Decipher Survey",
          placeholder: "Decipher Survey",
        },
        sampleId: {
          key: "sampleId",
          label: "Sample ID",
          placeholder: "ex.sample_{id}",
        },
        identity: {
          key: "identity",
          label: "Identity",
          placeholder: "Identity",
        },
        code: {
          key: "code",
          label: "Code",
          placeholder: "Code",
        },
        qcampaign: {
          key: "qcampaign",
          label: "QCAMPAIGN",
          placeholder: "QCAMPAIGN",
        },
        displayName: {
          key: "displayName",
          label: "Display name",
          placeholder: "Display name",
        },
        weightQuota: {
          key: "weightQuota",
          label: "Weight Quota",
          placeholder: "Weight Quota",
        },
        lastWeightedAt: {
          key: "lastWeightedAt",
          label: "Last Weighted",
          placeholder: "--",
        },
        ingestedCount: {
          key: "ingestedCount",
          label: "Total Ingested",
          placeholder: "--",
        },
        weightDifference: {
          key: "weightedDifference",
          label: "Weighted Difference",
          placeholder: "--",
        },
        // consumerQuota: {
        //     key: 'consumerQuota',
        //     label: 'Consumer Quota',
        //     placeholder: 'Consumer Quota',
        // },
        // vendorQuota: {
        //     key: 'vendorQuota',
        //     label: 'Vendor Quota',
        //     placeholder: 'Vendor Quota',
        // },
        campaignStatus: {
          key: "status",
          label: "Status",
          placeholder: "Market status",
        },
      },
      buttons: {
        cancel: {
          key: "cancel",
          label: "Cancel",
        },
        update: {
          key: "update_campaign",
          label: "Update Market",
        },
        create: {
          key: "create_campaign",
          label: "Create Market",
        },
      },
      modals: {
        enableMarket: {
          title: "Enable Market",
          message: "Are you sure you want to enable the following market?",
          buttons: [{ name: "Cancel" }, { name: "Enable Market" }],
        },
        disableMarket: {
          title: "Disable Market",
          message: "Are you sure you want to disable the following market?",
          buttons: [{ name: "Cancel" }, { name: "Disable Market" }],
        },
      },
      table: {
        title: "Contracts",
        columns: [
          {
            key: "brandContractName",
            field: "contractName",
            title: "Contract Name",
            width: "170px",
            sorting: "active",
          },
          {
            key: "brandGroupName",
            field: "groupName",
            title: "Group",
            width: "160px",
            sorting: "Inactive",
          },
          {
            key: "startDate",
            field: "startDate",
            title: "Start Date",
            width: "160px",
            sorting: "Inactive",
          },
          {
            key: "endDate",
            field: "endDate",
            title: "End Date",
            width: "160px",
            sorting: "Inactive",
          },
        ],
        noGroupsTitle: "Uh,oh. No Contracts.",
        noGroupMessage: "There are no contracts for this market.",
      },
      inputMessages: {
        decipherNameNotEdited: "Cannot edit Decipher Name.",
        decipherIdNotEdited: "Cannot edit Decipher ID.",
      },
    };
  }

  static getUserDropdownOptions(user) {
    return {
      user: {
        options: [
          {
            name: "edit_user",
            label: "Edit User",
          },
          {
            name: "reset_pass",
            label: "Reset Password",
          },
        ],
        separateOptions: [
          {
            name: "delete_user",
            label: "Delete User",
          },
        ],
      },
      group: {
        options: [],
        separateOptions: [
          {
            name: "remove_from_group",
            label: "Remove From Group",
          },
        ],
      },
    };
  }

  static getGroupDropdownOptions() {
    return {
      group: {
        options: [
          {
            name: "edit_group",
            label: "Edit Group",
          },
        ],
        separateOptions: [
          {
            name: "delete_group",
            label: "Delete Group",
          },
        ],
      },
      user: {
        options: [],
        separateOptions: [
          {
            name: "remove_user",
            label: "Remove User",
          },
        ],
      },
    };
  }

  static getContractDropdownOptions() {
    return {
      options: [
        {
          name: "edit_contract",
          label: "Edit Contract",
        },
        {
          name: "copy_contract",
          label: "Copy Contract",
        },
      ],
      separateOptions: [],
    };
  }

  static getContractBrandDropdownOptions(isPrimaryBrand) {
    return [
      {
        name: "primary_brand",
        label: isPrimaryBrand ? "Remove Primary Brand" : "Set as Primary Brand",
      },
    ];
  }

  static getBrandDropdownOptions() {
    return {
      options: [
        {
          name: "edit_brand",
          label: "Edit Brand",
        },
      ],
      separateOptions: [
        {
          name: "delete_brand",
          label: "Delete Brand",
        },
      ],
    };
  }

  static getCampaignDropdownOptions() {
    return {
      options: [
        {
          name: "edit_campaign",
          label: "Edit Market",
        },
        {
          name: "disable_geo",
          label: "Disable Market",
        },
        {
          name: "enable_geo",
          label: "Enable Market",
        },
      ],
    };
  }

  static getUserContractOptions() {
    return {
      options: [
        {
          name: "brand",
          label: "Brand",
          icon: detailedIcon,
        },
        {
          name: "geography",
          label: "Geography",
          icon: geoIcon,
        },
      ],
    };
  }

  static getStatusMessages() {
    const subjects = {
      user: "user",
      group: "group",
      password: "password",
      brand: "brand",
      contract: "contract",
      segments: "segments",
      market: "market",
      decipherSurvey: "decipherSurvey",
      filter: "filter",
    };
    const actionTypes = {
      add: "add",
      remove: "remove",
      update: "update",
      setPrimaryBrand: "set_primary",
      suspend: "suspend",
      changeStatus: "change_status",
      approve: "approve",
      decline: "decline",
      limit: "limit",
      statusEnabled: "statusEnabled",
      statusDisabled: "statusDisabled",
      existing: "existing",
    };
    return {
      types: {
        addUser: [subjects.user, actionTypes.add],
        removeUser: [subjects.user, actionTypes.remove],
        updateUser: [subjects.user, actionTypes.update],
        existingUser: [subjects.user, actionTypes.existing],
        changeUserStatus: [subjects.user, actionTypes.changeStatus],
        addGroup: [subjects.group, actionTypes.add],
        removeGroup: [subjects.group, actionTypes.remove],
        updateGroup: [subjects.group, actionTypes.update],
        existingGroup: [subjects.group, actionTypes.existing],
        resetPassword: [subjects.password, actionTypes.remove],
        setPrimaryBrand: [subjects.brand, actionTypes.setPrimaryBrand],
        createContract: [subjects.contract, actionTypes.add],
        suspendContract: [subjects.contract, actionTypes.suspend],
        updateContract: [subjects.contract, actionTypes.update],
        createBrand: [subjects.brand, actionTypes.add],
        updateBrand: [subjects.brand, actionTypes.update],
        existingBrand: [subjects.brand, actionTypes.existing],
        removeBrand: [subjects.brand, actionTypes.remove],
        approveBrand: [subjects.brand, actionTypes.approve],
        declineBrand: [subjects.brand, actionTypes.decline],
        segmentsLimit: [subjects.segments, actionTypes.limit],
        campaignEnabled: [subjects.campaign, actionTypes.statusEnabled],
        campaignDisabled: [subjects.campaign, actionTypes.statusDisabled],
        campaignUpdated: [subjects.campaign, actionTypes.update],
        campaignCreate: [subjects.campaign, actionTypes.add],
        existingCampaign: [subjects.campaign, actionTypes.existing],
        decipherSurveyCreate: [subjects.decipherSurvey, actionTypes.add],
        filterCreate: [subjects.filter, actionTypes.add],
        filterRemove: [subjects.filter, actionTypes.remove],
        filterUpdated: [subjects.filter, actionTypes.update],
        existingFilter: [subjects.filter, actionTypes.existing],
      },
      [subjects.user]: {
        [actionTypes.add]: {
          status: "User Created: ",
          message: "Successfully created user",
        },
        [actionTypes.remove]: {
          status: "User Deleted:",
          message: "Succesfully deleted user",
        },
        [actionTypes.update]: {
          status: "User Updated:",
          message: "Succesfully updated user",
        },
        [actionTypes.existing]: {
          status: "User Already Exists:",
          // message: userEmail => `${userEmail} email address already exists.`
        },
        [actionTypes.changeStatus]: {
          status: "User Status:",
          message: "Succesfully updated user status",
        },
      },
      [subjects.group]: {
        [actionTypes.add]: {
          status: "Group Created:",
          message: "Successfully created group",
        },
        [actionTypes.existing]: {
          status: "Group Already Exists:",
          message: (groupName) =>
            `This ${groupName} group name already exists, Please change group name`,
        },
        [actionTypes.remove]: {
          status: "Group Deleted:",
          message: "Successfully deleted group",
        },
        [actionTypes.update]: {
          status: "Group Updated:",
          message: "Successfully updated ",
        },
      },
      [subjects.password]: {
        [actionTypes.remove]: {
          status: "Reset Password:",
          message: "Email sent to",
        },
      },
      [subjects.brand]: {
        [actionTypes.add]: {
          status: "",
          message: (brandName) => `${brandName} brand created successfully.`,
        },
        [actionTypes.existing]: {
          status: "Brand Existing:",
          message: "Brand already exists. ",
        },
        [actionTypes.setPrimaryBrand]: {
          status: "Primary Brand:",
          message: (brandName) => `${brandName} is now the primary brand.`,
        },
        [actionTypes.remove]: {
          status: "Delete Brand: ",
          message: (brandName) => `Successfully deleted brand.`,
        },
        [actionTypes.approve]: {
          status: "Brand Approved: ",
          message: (brandName) => `Successfully approved ${brandName}`,
        },
        [actionTypes.update]: {
          status: "Brand Updated:",
          message: "Succesfully updated Brand",
        },
        [actionTypes.decline]: {
          status: "Brand Deleted: ",
          message: (brandName) => `Declined ${brandName}`,
        },
      },
      [subjects.contract]: {
        [actionTypes.add]: {
          status: "Contract Created:",
          message: (contractName) =>
            `${contractName} contract has been created.`,
        },
        [actionTypes.suspend]: {
          status: "Contract Suspended:",
          message: (contractName) =>
            `${contractName} contract has been suspended.`,
        },
        [actionTypes.update]: {
          status: "Contract Updated:",
          message: (contractName) => `contract has been updated.`,
        },
      },
      [subjects.segments]: {
        [actionTypes.limit]: {
          status: "Whoops: ",
          message: () =>
            "Please limit your selection to 10 profiling attributes",
        },
      },
      [subjects.campaign]: {
        [actionTypes.add]: {
          status: "Market created: ",
          message: "Successfully created market",
        },
        [actionTypes.existing]: {
          status: "Market Already Exists",
        },
        [actionTypes.statusEnabled]: {
          status: "Market Enabled: ",
          message: (marketName) => `Successfully enabled market ${marketName}`,
        },
        [actionTypes.statusDisabled]: {
          status: "Market Disabled: ",
          message: (marketName) => `Successfully disabled market ${marketName}`,
        },
        [actionTypes.update]: {
          status: "Market Updated:",
          message: "Successfully updated market",
        },
      },
      [subjects.decipherSurvey]: {
        [actionTypes.add]: {
          status: "Decipher Survey created: ",
          message: "Successfully created Decipher Survey",
        },
      },
      [subjects.filter]: {
        [actionTypes.add]: {
          status: "Filter created: ",
          message: "Successfully created.",
        },
        [actionTypes.existing]: {
          status: "Filter Already Exists:",
          message: (filterName) =>
            `${filterName} already exists with same date range`,
        },
        [actionTypes.remove]: {
          status: "Filter removed: ",
          message: "Successfully deleted.",
        },
        [actionTypes.update]: {
          status: "Filter Updated:",
          message: `Successfully updated.`,
        },
      },
    };
  }

  static getDefaultAgeSegmentNames() {
    return ['Age 18+','Age 21+','Age 35+'];
  }

  static getDefaultSegment() {
    return {
      id: -1,
      name: "Age 18+",
      active: true,
      data: {
        filters: [],
      },
    };
  }

  static get2x2AllSegments(filters) {
    const dateFilterId = 3;
    const ageFilterId = 8;
    const segmentsToExclude = [dateFilterId, ageFilterId];
    // by default we request and show the first 5 active segments (1 default + 4 generated)
    const maxActiveNumberByDefault = 4;
    const filterGroups = InitData.getFilterGroups();
    const allSegments = filterGroups.reduce((acc, filterGroup) => {
      const { filters: filterNames } = filterGroup;
      let i = 0;

      const segments = filterNames.reduce((acc, fName) => {
        if (!filters.length) {
          return acc;
        }

        const filter = filters.find(({ name }) => name === fName);

        if (!filter) return acc;

        const { id, filterOptions, name: filterName } = filter;

        if (segmentsToExclude.includes(id)) {
          return acc;
        }

        i++;

        const data = {
          id,
          data: {
            filter: filterOptions,
          },
        };

        const name = filterName
          .split("_")
          .map((wordInName) => {
            const countries = ["us", "uk", "au", "ca"];
            if (countries.includes(wordInName)) {
              return wordInName.toUpperCase();
            } else {
              return wordInName.replace(/^[a-z]/, (a) => a.toUpperCase());
            }
          })
          .join(" ");
        acc.push({
          name,
          filterName,
          active: i < maxActiveNumberByDefault,
          ...data,
        });

        return acc;
      }, []);

      return [...acc, ...segments];
    }, []);
    const parsedAllSegments = allSegments.filter((segment) => {
      const { id: segmentId } = segment;
      const filter = filters.find(({ id }) => id === segmentId);

      if (filter) {
        const { type } = filter;

        if (type !== filterGroupTypes.FILTER_GROUP) {
          return segment;
        }
      }
    });
    return [this.getDefaultSegment(), ...parsedAllSegments];
  }

  static getSubcategoriesList() {
    return;
  }

  static getAdvertisingAndEmotionalLabelText(key) {
    const labelText = {
      "Advertising Recall": "Advertising Recall",
      "TV (cable/satellite/broadcast)": "TV",
      "Online/Streaming Video": "Online/Stream",
      "News/Entertainment Website or App": "News Website",
      "E-commerce/Review Website or App": "Retail Website",
      "Post/Pop-up on Social Media": "Social Media",
      "Influencer on Social Media": "Influencer",
      "Store/Physical Location": "Store Location",
      "Online Gaming": "Online Gaming",
      "Search Engine(e.g., Google, Bing)": "Search Engine",
      "Streaming Audio/Podcast": "Podcast",
      "Radio": "Radio",
      "Outdoor": "Outdoor",
      "Print Media": "Print",
      "Email": "Email",
      "Other": "Other",
      "Corporate": "Corporate",
      "Customer-centric": "Customer-centric",
      "Dependable": "Dependable",
      "Fun": "Fun",
      "Approachable": "Approachable",
      "Good Value": "Good Value",
      "Hip": "Hip",
      "Best in Class": "Best in Class",
      "Innovative": "Innovative",
      "Intelligent": "Intelligent",
      "Knowledgeable": "Knowledgeable",
      "Practical": "Practical",
      "Premium": "Premium",
      "Simple": "Simple",
      "Socially Conscious": "Socially Conscious",
      "Smart": "Smart",
      "Stylish": "Stylish",
      "Personable": "Personable",
      "Traditional": "Traditional",
      "Trustworthy": "Trustworthy",
      "Unconventional": "Unconventional",
      "Visionary": "Visionary",
      "Wholesome": "Wholesome",
      "Classy": "Classy",
      "Sophisticated": "Sophisticated",
      "Young": "Young",
      "Energetic": "Energetic",
      "Confident": "Confident",
      "Bold": "Bold",
      "N/A" : "N/A"
    };
    return labelText[key] ? labelText[key] : key;
  }

  static getTrendsOptionCategories() {
    return {
      // Equity
      0: [
        {
          id: "total",
          name: "Total",
        },
        {
          id: "momentum",
          name: "Momentum",
        },
        {
          id: "consideration",
          name: "Consideration",
        },
        {
          id: "quality",
          name: "Quality",
        },
        {
          id: "familiarity",
          name: "Familiarity",
        },
      ],
      // Conversion
      1: [
        {
          id: "awareness",
          name: "Awareness",
        },
        {
          id: "familiarity",
          name: "Familiarity",
        },
        {
          id: "trial",
          name: "Trial",
        },
        {
          id: "usage",
          name: "Usage",
        },
        {
          id: "recommend",
          name: "Recommend",
        },
      ],
      // Emotional
      2: [
        {
          id: "corporate",
          name: "Corporate",
        },
        {
          id: "customerCentric",
          name: "Customer-centric",
        },
        {
          id: "dependable",
          name: "Dependable",
        },
        {
          id: "fun",
          name: "Fun",
        },
        {
          id: "goodValue",
          name: "Good Value",
        },
        {
          id: "hip",
          name: "Hip",
        },
        {
          id: "innovative",
          name: "Innovative",
        },
        {
          id: "intelligent",
          name: "Intelligent",
        },
        {
          id: "practical",
          name: "Practical",
        },
        {
          id: "premium",
          name: "Premium",
        },
        {
          id: "simple",
          name: "Simple",
        },
        {
          id: "sociallyConscious",
          name: "Socially Conscious",
        },
        {
          id: "smart",
          name: "Smart",
        },
        {
          id: "stylish",
          name: "Stylish",
        },
        {
          id: "traditional",
          name: "Traditional",
        },
        {
          id: "trustworthy",
          name: "Trustworthy",
        },
        {
          id: "unconventional",
          name: "Unconventional",
        },
        {
          id: "visionary",
          name: "Visionary",
        },
        {
          id: "wholesome",
          name: "Wholesome",
        },
        {
          id: "classy",
          name: "Classy",
        },
        {
          id: "sophisticated",
          name: "Sophisticated",
        },
        {
          id: "young",
          name: "Young",
        },
        {
          id: "energetic",
          name: "Energetic",
        },
        {
          id: "confident",
          name: "Confident",
        },
        {
          id: "bold",
          name: "Bold",
        },
      ],
      // Advertising
      3: [
        {
          id: "advertisingRecall",
          name: "Advertising Recall",
        },
        {
          id: "tvCableSatelliteBroadcast",
          name: "TV (cable/satellite/broadcast)",
        },
        {
          id: "onlineStreamingVideo",
          name: "Online/Streaming Video",
        },
        {
          id: "newsEntertainmentWebsiteOrApp",
          name: "News/Entertainment Website or App",
        },
        {
          id: "ecommerceReviewWebsiteOrApp",
          name: "E-commerce/Review Website or App",
        },
        {
          id: "postPopUponSocialMedia",
          name: "Post/Pop-up on Social Media",
        },
        {
          id: "influencerOnSocialMedia",
          name: "Influencer on Social Media",
        },
        {
          id: "storePhysicalLocation",
          name: "Store/Physical Location",
        },
        {
          id: "onlineGaming",
          name: "Online Gaming",
        },
        {
          id: "streamingAudioPodcast",
          name: "Streaming Audio/Podcast",
        },
        {
          id: "radio",
          name: "Radio",
        },
        {
          id: "outdoor",
          name: "Outdoor",
        },
        {
          id: "printMedia",
          name: "Print Media",
        },
        {
          id: "email",
          name: "Email",
        },
        {
          id: "other",
          name: "Other",
        },
      ],
      //Irreplaceability
      4: [
        {
          id: "positive",
          name: "Positive",
        },
        {
          id: "neutral",
          name: "Neutral",
        },
        {
          id: "negative",
          name: "Negative",
        },
        {
          id: "net",
          name: "NET",
        },
      ],
      5: [
        {
          id: "positive",
          name: "Positive",
        },
        {
          id: "negative",
          name: "Negative",
        },
        {
          id: "absolutely",
          name: "Absolutely",
        },
        {
          id: "possibly",
          name: "Possibly",
        },
        {
          id: "notlikely",
          name: "Not Likely",
        },
        {
          id: "never",
          name: "Never",
        },
      ],
    };
  }
}
