import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import rightArrowIcon from "../../assets/icons/icon_views_right.svg";
import emotionalIcon from "../../assets/icons/icon_views_emotional.svg";
import trendsIcon from "../../assets/icons/icon_views_trends.svg";
import conversionIcon from "../../assets/icons/icon_views_conversion.svg";
import equityIcon from "../../assets/icons/icon_views_equity.svg";
import advertisingIcon from "../../assets/icons/icon_views_advertising.svg";
import profileIcon from "../../assets/icons/icon_views_profile.svg";
import differentiationIcon from "../../assets/icons/icon_views_differentiation.svg";
import perceptualIcon from "../../assets/icons/icon_views_perceptual.svg";
import butterflyIcon from "../../assets/icons/icon_views_butterfly.svg";
import prepostIcon from "../../assets/icons/icon_views_prepost.svg";
import ReportActions from "./ReportActions";
import { setViews } from "../../reducers/TwoByTwoChart/actionCreators";
import { useDispatch } from "react-redux";
import ViewCardTooltip from "./ViewCard/ViewCardTooltip";

const screenLogos = [
  { screenType: "EQUITY", logo: equityIcon, color: "#7f56d9" },
  { screenType: "CONVERSION", logo: conversionIcon, color: "#17B26A" },
  { screenType: "EMOTIONAL", logo: emotionalIcon, color: "#FF9500" },
  { screenType: "ADVERTISING", logo: advertisingIcon, color: "#ff2d55" },
  { screenType: "TRENDS", logo: trendsIcon, color: "#30B0C7" },
  { screenType: "PROFILE", logo: profileIcon, color: "#493971" },
  {
    screenType: "DIFFERENTIATION",
    logo: differentiationIcon,
    color: "#938f96",
  },
  { screenType: "PERCEPTUAL", logo: perceptualIcon, color: "#89475e" },
  { screenType: "BUTTERFLY", logo: butterflyIcon, color: "#797677" },
  { screenType: "PREPOST", logo: prepostIcon, color: "#6750A4" },
];

function ReportCard({
  id,
  report,
  handleExportReportOptions,
  handleReportActionsModal,
  handleReportsActions,
  handleExportModal,
  handleRedirectViewsPage,
  handleExport,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const dispatch = useDispatch();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: "white",
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      style={style}
      className="viewCard"
    >
      <div className="viewContentContainer">
        <div className="cardContentContainer">
          <div className="viewHeaderContainer">
            <ViewCardTooltip
              contentList={[report?.name]}
              offset={[0, 5]}
              delay={500}
            >
              <div className="header">{report?.name}</div>
            </ViewCardTooltip>
            {report.description && (
              <ViewCardTooltip
                contentList={[report?.description]}
                offset={[0, 5]}
                delay={500}
              >
                <div className="subHeader">{report.description}</div>
              </ViewCardTooltip>
            )}
          </div>
          <div className="screensIconsContainer">
            {report.viewScreenTypes?.map((screen, ind) => {
              let logoObj = screenLogos?.find(
                ({ screenType }) => screen === screenType
              );

              const brandStyle = {
                zIndex: ind + 1,
                left: 25 * ind + "px",
                background: ind === 4 ? "#f2f4f7" : logoObj?.color,
                border:
                  ind === 4 ? "2px solid #00000014" : "1.5px solid #FFFFFF",
              };

              return (
                <>
                  {ind <= 4 && (
                    <div
                      className="avatarIconContainer"
                      style={brandStyle}
                      key={ind}
                    >
                      {ind < 4 && <img src={logoObj?.logo} alt="" />}
                      {ind === 4 && (
                        <div className="avatarIcon avatarIconNumber">
                          +{report?.viewScreenTypes?.length - 4}
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <ReportActions
          report={report}
          handleExportModal={handleExportModal}
          handleReportsActions={handleReportsActions}
          handleExport={handleExport}
          handleReportActionsModal={handleReportActionsModal}
          handleExportReportOptions={handleExportReportOptions}
        />
      </div>
      <div
        className="viewLinkContainer"
        onClick={() => {
          dispatch(setViews([]));
          handleRedirectViewsPage(report);
        }}
      >
        <div className="viewLinkButton">
          <div className="viewLinkContent">View Report</div>
          <img src={rightArrowIcon} className="viewLinkIcon" alt="" />
        </div>
      </div>
    </div>
  );
}

export default ReportCard;
