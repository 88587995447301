import React, { useState } from "react";
import { StyledViewsActions } from "./Styled.ViewsActions";
import ViewsDetailIcon from "../../../../assets/icons/icon_views_detail.svg";
import ViewsActionsIcon from "../../../../assets/icons/icon_views_dots.svg";
import ViewCloneIcon from "../../../../assets/icons/icon_views_clone.svg";
import ViewMakeDefaultIcon from "../../../../assets/icons/icon_views_make_default.svg";
import ViewExportIcon from "../../../../assets/icons/icon_views_download.svg";
import ViewDeleteIcon from "../../../../assets/icons/icon_views_trash.svg";
import PPTIcon from "../../../../assets/icons/ppt.svg";
import ExcelIcon from "../../../../assets/icons/xlsx.svg";
import EditIcon from "../../../../assets/icons/icon_report_edit.svg";
import ButtonWithDropdownOption from "../../../ButtonWithDropdownOption";
import useFileDownload from "../../../FileDownload/hooks/useFileDownloadHook";
import ApiData from "../../../../utils/Api";
import { generateWSPrivateChannelId } from "../../../../utils/userHelper";
import Toaster from "../../Toaster";
import useViewsReportsExportsStatus from "../../../../hooks/useViewsReportsExportsStatus";
import InitData from "../../../../utils/initData";

function ViewsActions({
  view,
  reportsIdsSelectedOnView,
  onClickDetailView,
  onClickDeleteView,
  onClickCloneView,
  onClickEditView,
  handleDefaultView,
}) {
  const {
    isViewsExcelExportAvailable,
    isViewsPPTExportAvailable,
    isViewsExportAvailable,
  } = useViewsReportsExportsStatus();

  const [toasterMessage, setToasterMessage] = useState(null);

  const { addNewFileToDownload } = useFileDownload();

  async function handleExport(isPPT = false) {
    const fileType = isPPT ? "pptx" : "xlsx";
    const fileName = `${view.viewName}.${fileType}`;
    const { id } = JSON.parse(localStorage.getItem("userInfo"));
    const connectionId = generateWSPrivateChannelId(id);
    try {
      let res = await ApiData.PostReportExport(
        {
          reportId: reportsIdsSelectedOnView,
          viewId: view.viewId,
          fileName,
          connectionId,
          userId: id,
        },
        fileType
      );
      setToasterMessage({
        type: "success",
        message: "Export initiated",
      });
      addNewFileToDownload({
        fileName,
        jobId: res,
        screenType: "views",
      });
    } catch (error) {
      setToasterMessage({
        type: "error",
        message: "Error while exporting view.",
      });
    }
  }

  const handleViewClick = (e, view, closeDropdown, onClick) => {
    onClick(e, view);
    closeDropdown();
  };

  const handleDropdownButtonClick = (e) => {
    e.stopPropagation();
  };

  function handleToasterClose(e) {
    e.stopPropagation();
    setToasterMessage(null);
  }

  return (
    <StyledViewsActions className="views-header-right-container">
      <img
        height={18}
        src={ViewsDetailIcon}
        onClick={(e) => onClickDetailView(e, view)}
        alt="views-details"
      />
      <ButtonWithDropdownOption
        closeOnContentClick={false}
        trigger={
          <img
            width={22}
            src={ViewsActionsIcon}
            alt="views-actions"
            onClick={handleDropdownButtonClick}
          />
        }
      >
        {(closeDropdown) => (
          <ul>
            <li
              key={`clone_view_${view.viewId}`}
              onClick={(e) =>
                handleViewClick(e, view, closeDropdown, onClickEditView)
              }
            >
              <img height={18} src={EditIcon} alt="views-details" />
              <span>Edit View</span>
            </li>
            <li
              key={`clone_view_${view.viewId}`}
              onClick={(e) =>
                handleViewClick(e, view, closeDropdown, onClickCloneView)
              }
            >
              <img height={18} src={ViewCloneIcon} alt="views-details" />
              <span>Clone View</span>
            </li>
            <li
              key={`make_default_view_${view.viewId}`}
              onClick={(e) => handleDefaultView(e, view)}
              condition={view?.isDefaultView}
            >
              <img height={18} src={ViewMakeDefaultIcon} alt="views-details" />
              <span>{!view?.isDefaultView && "Make"} Default View</span>
              <input type="checkbox" checked={view?.isDefaultView} />
            </li>
            {isViewsExportAvailable && (
              <ButtonWithDropdownOption
                trigger={
                  <li key={`export_view_${view.viewId}`}>
                    <img height={18} src={ViewExportIcon} alt="views-details" />
                    <span>Export View</span>
                  </li>
                }
                className="view-export-download-main-container"
                offset={[40, -40]}
                placement="right"
              >
                <ul>
                  {isViewsPPTExportAvailable && (
                    <li
                      key={`ppt_view_${view.viewId}`}
                      onClick={handleExport.bind(this, true)}
                    >
                      <img height={18} src={PPTIcon} alt="views-details" />
                      <span>PPT</span>
                    </li>
                  )}
                  {isViewsExcelExportAvailable &&
                    view?.screenType !== "PERCEPTUAL" && (
                      <li
                        key={`Excel_view_${view.viewId}`}
                        onClick={handleExport.bind(this, false)}
                      >
                        <img height={18} src={ExcelIcon} alt="views-details" />
                        <span>Excel</span>
                      </li>
                    )}
                </ul>
              </ButtonWithDropdownOption>
            )}
            <li
              id="view-delete-action"
              key={`delete_view_${view.viewId}`}
              onClick={(e) =>
                handleViewClick(e, view, closeDropdown, onClickDeleteView)
              }
            >
              <div className="view-delete-action-button">
                <img height={18} src={ViewDeleteIcon} alt="views-details" />
                <span>Delete View</span>
              </div>
            </li>
          </ul>
        )}
      </ButtonWithDropdownOption>
      {toasterMessage && (
        <Toaster
          isOpen={toasterMessage}
          variant={toasterMessage?.type}
          onClose={(e) => handleToasterClose(e)}
        >
          {toasterMessage?.message}
        </Toaster>
      )}
    </StyledViewsActions>
  );
}

export default ViewsActions;
