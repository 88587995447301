import React, { Component } from "react";
import cx from "classnames";
import initData from "../../../utils/initData";
import "./EmotionalCard.scss";
import BrandTitle from "../Shared/BrandTitle";
import SegmentTitle from "../Shared/SegmentTitle";
import NoDataBox from "../Shared/NoDataBox";
import BubbleChart from "./BubbleChart";
import upIcon from "../../../assets/icons/icon_positive.svg";
import downIcon from "../../../assets/icons/icon_negative.svg";
import { isEmpty, get } from "lodash";
import * as d3 from "d3";
import createStatTestingMessage from "../../../utils/createStatTestingMessage";

export default class EmotionalCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aniStart: false,
      width: 0,
    };

    //this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.setState({
      aniStart: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    d3.selectAll(".tooltip").remove();
  }

  renderStatTestingArrow = (key, id) => {
    const { statTestingEnabled, lowerThan, higherThan, selectedDate, cperiod } =
      this.props;
    const higher = get(higherThan, id, []);
    const lower = get(lowerThan, id, []);
    const statTestingMessage = createStatTestingMessage({
      entity: key,
      toExclude: "brands",
      higherThan: higher,
      lowerThan: lower,
      period: cperiod,
      asComponent: true,
      startDate: selectedDate?.startdate,
      endDate: selectedDate?.enddate,
    });
    if (statTestingEnabled) {
      if (
        !isEmpty(lowerThan) &&
        lowerThan[id] &&
        lowerThan[id].includes("<prevPeriod>")
      ) {
        return (
          <div className="upDownIcon withQText">
            <img src={downIcon} className="icon statArrow" alt="" height={14} />
            <span dangerouslySetInnerHTML={statTestingMessage()} />
          </div>
        );
      }
      if (
        !isEmpty(higherThan) &&
        higherThan[id] &&
        higherThan[id].includes("<prevPeriod>")
      ) {
        return (
          <div className="upDownIcon withQText">
            <img src={upIcon} className="icon statArrow" alt="" height={14} />
            <span dangerouslySetInnerHTML={statTestingMessage()} />
          </div>
        );
      }
    }
  };

  checkDataValuesValidity(data) {
    return data.filter((d) => d.value > 0).length > 0;
  }

  render() {
    const {
      data,
      colors,
      logo,
      brand,
      brandValue,
      segment,
      hideBars,
      label,
      name,
      regionState,
      page,
      countryCode,
    } = this.props;
    const { aniStart } = this.state;
    const tooltipProps = [
      "mattDamon",
      {
        css: "value",
        prop: "value",
      },
      {
        css: "change",
        prop: "colorValue",
        display: "Change",
      },
    ];
    !!data &&
      data.sort((x, y) => {
        return y.value - x.value;
      });
    const maxValue =
      data?.length > 0 && data[0]?.value > 0
        ? parseInt(data[0]?.value) + 5
        : 100;
    let areDataValuesValid;
    if (!!data) areDataValuesValid = this.checkDataValuesValidity(data);

    return (
      <div
        className={cx("cardContainer", "emotionalContainer", {
          segmentHidden: segment && !segment.isActive,
        })}
        ref={(ref) => (this.container = ref)}
      >
        <div className="cardHeaderContainer" id={"cardHeaderContainer"}>
          {segment ? (
            <SegmentTitle
              segment={segment}
              pool={brandValue}
              page={page}
              onClickSegmentFilter={this.props.onClickSegment}
            />
          ) : name ? (
            <BrandTitle
              brand={{ name: name }}
              regionState={regionState}
              customClass={"equityCard"}
              emotional="emotional"
              noLogo
            />
          ) : (
            <BrandTitle
              brand={{ name: brand, logo: logo, countryCode: countryCode }}
              emotional="emotional"
            />
          )}
          {!segment && brandValue > 0 && (
            <div className="value">
              n={Math.round(brandValue).toLocaleString("en-En")}
            </div>
          )}
        </div>
        <div
          className="scrollContainer"
          style={!hideBars ? { height: "115rem" } : {}}
        >
          {brandValue > 0 && data.length > 0 && areDataValuesValid ? (
            <div className="diagramContainer">
              <div className="overlapContainer">
                <BubbleChart
                  colors={colors}
                  graph={{
                    zoom: 1,
                    offsetX: 0.01,
                    offsetY: 0.01,
                  }}
                  overflow={false}
                  padding={20} // optional value, number that set the padding between bubbles
                  labelFont={{
                    family: "azo-sans-web",
                    size: 12,
                    weight: "400",
                  }}
                  data={data}
                  tooltipProps={tooltipProps}
                  brand={segment ? segment.segmentName : brand}
                />
              </div>
            </div>
          ) : (
            <NoDataBox />
          )}
          {brandValue > 0 && data.length > 0 && !hideBars && (
            <div className="bottomContainer">
              {data.map((item, index) => {
                const w = aniStart
                  ? ((100 * item.value) / maxValue).toFixed(1) + "%"
                  : 0;
                return (
                  <div className="figureContainer" key={index}>
                    <div className="figureTitle withQText">
                      {item.label}
                      <span>{item?.qText}</span>
                    </div>
                    <div className="barContainer">
                      <div
                        className="percentBar"
                        style={{ width: w, backgroundColor: colors[0] }}
                      ></div>
                    </div>
                    <div className="figureData">
                      {item.value && item.value.toFixed(1)}%
                      {this.renderStatTestingArrow(item.label, item?.id)}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
