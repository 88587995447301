import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { getLocalStorage } from "../../../utils/storageHelpers";
import { IoIosArrowUp } from "react-icons/io";
import FilterCommonItem from "./FilterCommonItem";
import RangeSlider from "./RangeSlider";
import "./filterGroup.scss";
import SegmentCommonItem from "./SegmentCommonItem";
import { RxQuestionMarkCircled } from "react-icons/rx";
import filtersTransformations from "../../../utils/filterTransformations";
import { getRangeSliderDefaultValues } from "../../../utils/common";

const BrandFilterCard = (props) => {
  const {
    title,
    filters,
    searchFound,
    currentSelectedFilters,
    categoryObj,
    searchKey,
    selectedMarket,
    allFilters,
    page,
    selectedGlobalBrand,
  } = props;

  let [expanded, setExpanded] = useState();
  let [filtersArr, setFiltersArr] = useState([]);
  let [selectedOptionsCount, setSelectedOptionsCount] = useState(0);
  let [rangeData, setRangeData] = useState([]);
  let ageSlider = useRef();

  useEffect(() => {
    let filters = JSON.parse(getLocalStorage("filters"));
    setFiltersArr(filters);
    setExpanded(expandFilterOptionsContainer());
  }, []);

  useEffect(() => {
    if (searchFound && searchKey.length > 0) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [searchKey, searchFound]);

  useEffect(() => {
    calcSelectedOptionsCount();
    setExpanded(expandFilterOptionsContainer());
    let cateroryIndex = currentSelectedFilters?.findIndex(
      (f) => f.categoryName === "Age"
    );
    let rangeFilter =
      cateroryIndex > -1 &&
      currentSelectedFilters[cateroryIndex]?.filters?.find(
        ({ filterId }) => filterId == 8
      );
    rangeFilter &&
      setRangeData(
        rangeFilter?.filterOptions?.reduce((acc, ele) => {
          acc.push(ele?.filterOptionId);
          return acc;
        }, [])
      );
  }, [currentSelectedFilters, allFilters, selectedGlobalBrand]);

  const onExpandClick = () => {
    setExpanded(!expanded);
  };

  const expandFilterOptionsContainer = () => {
    let isSelectedFiltersFound = currentSelectedFilters?.find((obj) => {
      if (obj.categoryId === categoryObj.categoryId) {
        if (categoryObj.categoryName === "Segments") {
          return obj.filters.find((ele) =>
            categoryObj.filterResponse.find((element) =>
              element.find(({ filterId }) => filterId === ele.filterId)
            )
          );
        } else {
          return obj.filters.find((ele) =>
            categoryObj.filterResponse.find(({ filterId, filterOptions }) => {
              if (ele?.filterType !== "Age Range") {
                let selectedFilterOptions = ele?.filterOptions?.map(
                  (val) => val.filterOptionId
                );
                let allFilterOptions = filterOptions?.map(
                  (val) => val.filterOptionId
                );
                let allfilterOptionsPresentInList =
                  selectedFilterOptions?.every(
                    (val) => allFilterOptions?.indexOf(val) >= 0
                  );
                return (
                  filterId === ele.filterId && allfilterOptionsPresentInList
                );
              } else {
                return filterId === ele.filterId;
              }
            })
          );
        }
      }
    });
    return isSelectedFiltersFound ? true : false;
  };

  const calcSelectedOptionsCount = () => {
    let findCategory = currentSelectedFilters?.filter(
      (ele) => ele.categoryId === categoryObj.categoryId
    );
    let count;
    if (props?.categoryObj?.categoryId === null) {
      count = findCategory?.reduce((accu, categoryObj) => {
        return (
          accu +
          categoryObj?.filters?.reduce((acc, obj) => {
            let filterMarkets = filters?.reduce((acc, sr) => {
              sr.filter(
                (el) =>
                  el.filterId === obj.filterId && acc.push(...el?.filterMarket)
              );
              return acc;
            }, []);
            const filtersPresentInMarket =
              filtersTransformations.checkFilterPresentInMarket(
                page,
                filterMarkets,
                selectedMarket?.marketName,
                selectedGlobalBrand
              );
            return acc + (filtersPresentInMarket ? 1 : 0);
          }, 0)
        );
      }, 0);
    } else {
      count =
        findCategory &&
        findCategory[0]?.filters?.reduce((acc, obj) => {
          let findObj = allFilters.find(({ filterResponse }) =>
            filterResponse.find((ele) => {
              if (
                Object.keys(obj?.filterOptions[0])?.length > 0 &&
                obj?.filterType !== "SEGMENT" &&
                obj?.filterType !== "Age Range"
              ) {
                let selectedFilterOptions = obj?.filterOptions?.map(
                  (val) => val?.filterOptionId
                );
                let allFilterOptions = ele?.filterOptions?.map(
                  (val) => val?.filterOptionId
                );
                let allfilterOptionsPresentInList =
                  selectedFilterOptions?.every(
                    (val) => allFilterOptions?.indexOf(val) >= 0
                  );
                return (
                  ele.filterId === obj.filterId &&
                  !ele.isExpired &&
                  allfilterOptionsPresentInList
                );
              } else {
                return ele.filterId === obj.filterId && !ele.isExpired;
              }
            })
          );
          return (
            acc +
            (findObj
              ? obj?.filterId === 8
                ? 1
                : obj?.filterOptions?.length
              : 0)
          );
        }, 0);
    }
    setSelectedOptionsCount(count);
  };

  const onChangeFilters = (
    categoryObj,
    filterObj,
    optionObj,
    active,
    filterType
  ) => {
    let { currentSelectedFilters } = props;
    let { filterId, filterName } = filterObj;
    let { categoryId, categoryName, filterGroup } = categoryObj;

    const categoryIndex =
      filterType === "SEGMENT"
        ? currentSelectedFilters.findIndex((cf) => {
            return cf.categoryName === filterGroup;
          })
        : currentSelectedFilters.findIndex((cf) => {
            return cf.categoryId === categoryId;
          });
    const filterIndex = currentSelectedFilters[
      categoryIndex
    ]?.filters?.findIndex((cf) => {
      return cf.filterId === filterId;
    });
    if (active) {
      if (categoryIndex < 0) {
        currentSelectedFilters.push({
          categoryId: categoryId ? categoryId : null,
          categoryName: categoryName ? categoryName : filterGroup,
          type: filterType,
          filters: [
            {
              filterId: filterId,
              filterName: filterName,
              filterType: filterType,
              filterOptions: [
                {
                  filterOptionId: optionObj?.filterOptionId,
                  filterOptionName: optionObj?.filterOptionName,
                },
              ],
            },
          ],
          expanded: true,
        });
      } else if (categoryIndex >= 0 && filterIndex < 0) {
        currentSelectedFilters[categoryIndex].filters.push({
          filterId: filterId,
          filterName: filterName,
          filterType: filterType,
          filterOptions: [
            {
              filterOptionId: optionObj?.filterOptionId,
              filterOptionName: optionObj?.filterOptionName,
            },
          ],
        });
      } else if (filterIndex >= 0) {
        currentSelectedFilters[categoryIndex].filters[
          filterIndex
        ].filterOptions.push({
          filterOptionId: optionObj?.filterOptionId,
          filterOptionName: optionObj?.filterOptionName,
        });
      }
      let findGenerationFilter = currentSelectedFilters[
        categoryIndex
      ]?.filters?.findIndex(({ filterId }) => filterId === 7);
      if (findGenerationFilter > -1) {
        let rangeIndex = currentSelectedFilters[
          categoryIndex
        ].filters.findIndex(({ filterId }) => filterId === 8);
        if (rangeIndex > -1) {
          currentSelectedFilters[categoryIndex].filters.splice(rangeIndex, 1);
          setRangeData([]);
        }
      }
    } else {
      if (categoryIndex > -1 && filterIndex > -1) {
        let oIndex = currentSelectedFilters[categoryIndex]?.filters[
          filterIndex
        ]?.filterOptions.findIndex(
          (option) => option?.filterOptionId === optionObj?.filterOptionId
        );
        if (oIndex > -1) {
          currentSelectedFilters[categoryIndex].filters[
            filterIndex
          ].filterOptions.splice(oIndex, 1);
        }
        if (
          currentSelectedFilters[categoryIndex].filters[filterIndex]
            .filterOptions.length === 0
        ) {
          currentSelectedFilters[categoryIndex].filters.splice(filterIndex, 1);
        }
        if (currentSelectedFilters[categoryIndex].filters.length === 0) {
          currentSelectedFilters.splice(categoryIndex, 1);
        }
      }
    }
    calcSelectedOptionsCount();
    props.onFilterChange(currentSelectedFilters);
  };

  const onChangeRange = (active, range) => {
    let { currentSelectedFilters } = props;
    let ageCategoryIndex = currentSelectedFilters.findIndex(
      (ele) => ele.categoryId === 1
    );
    currentSelectedFilters.forEach((categoryObj, ind) => {
      if (categoryObj.categoryId === 1) {
        if (categoryObj.filters[0].filterId === 8) {
          currentSelectedFilters[ind].filters[0].filterOptions = [
            { filterOptionId: range[0], filterOptionName: null },
            { filterOptionId: range[1], filterOptionName: null },
          ];
        }
      }
    });
    if (
      active &&
      Array.isArray(range) &&
      range.length === 2 &&
      ageCategoryIndex < 0
    ) {
      currentSelectedFilters.push({
        categoryId: 1,
        categoryName: "Age",
        type: "Age Range",
        filters: [
          {
            filterId: 8,
            filterName: "Range",
            filterOptions: [
              { filterOptionId: range[0], filterOptionName: null },
              { filterOptionId: range[1], filterOptionName: null },
            ],
            filterType: "Age Range",
          },
        ],
        expanded: true,
      });
    }
    setRangeData(range);
    calcSelectedOptionsCount();
    props.onFilterChange(currentSelectedFilters);
  };

  const cateroryIndex = filtersArr.findIndex((f) => f.categoryName === "Age");
  const matchTitle = title?.toLowerCase()?.includes(searchKey?.toLowerCase());
  return (
    filters?.length > 0 && (
      <div
        className={cx(
          "filterCardContainer",
          { collapse: !expanded && !searchFound },
          { active: selectedOptionsCount > 0 }
        )}
      >
        <div
          className={cx(
            "filterCardHeader",
            { active: selectedOptionsCount > 0 },
            { expanded: selectedOptionsCount > 0 && expanded },
            { collapse: expanded }
          )}
          onClick={onExpandClick}
        >
          <div className="categoryTitle">
            {searchFound && matchTitle ? (
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: title.replace(
                    new RegExp(`(${searchKey})`, "gi"),
                    (match) => `<span class='match'>${match}</span>`
                  ),
                }}
              />
            ) : (
              <span>{title}</span>
            )}
            {selectedOptionsCount > 0 && (
              <span className="countContainer">{selectedOptionsCount}</span>
            )}
          </div>
          <IoIosArrowUp className="arrowIcon" alt="" />
        </div>
        {expanded && (
          <div className="filtersListContainer">
            {filters.map((filter, indValue) => {
              const showOptions = [];
              if (filter?.length > 0 && searchKey?.length > 0) {
                let filterOptionsMatchFound = filter?.findIndex(
                  ({ filterName }) =>
                    filterName
                      ?.toLowerCase()
                      ?.includes(searchKey?.toLowerCase())
                );
                if (filterOptionsMatchFound > -1) {
                  filter = filter?.filter(({ filterName }) =>
                    filterName
                      ?.toLowerCase()
                      ?.includes(searchKey?.toLowerCase())
                  );
                }
              }
              //Handling segments as a filters in CI.Global,Advanced
              filter?.length > 0 &&
                filter.forEach((obj, ind) => {
                  const matchFilterName = obj.filterGroup
                    ?.toLowerCase()
                    ?.includes(searchKey?.toLowerCase());
                  obj.filterType === "SEGMENT" &&
                    ind === 0 &&
                    showOptions.push(
                      <div
                        key={"segmentTitle" + indValue}
                        className="titleContainer"
                      >
                        {searchFound && matchFilterName ? (
                          <div
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: obj.filterGroup.replace(
                                new RegExp(`(${searchKey})`, "gi"),
                                (match) => `<span class='match'>${match}</span>`
                              ),
                            }}
                          />
                        ) : (
                          <div className="title">{obj.filterGroup}</div>
                        )}
                      </div>
                    );
                  const filtersPresentInMarket =
                    filtersTransformations.checkFilterPresentInMarket(
                      page,
                      obj.filterMarket,
                      selectedMarket?.marketName,
                      selectedGlobalBrand
                    );
                  const active = Boolean(
                    currentSelectedFilters?.length &&
                      currentSelectedFilters.find((ele) => {
                        return ele.filters.find(
                          ({ filterId, filterName }) =>
                            filterId === obj.filterId &&
                            filterName === obj.filterName &&
                            filtersPresentInMarket
                        );
                      })
                  );
                  showOptions.push(
                    <SegmentCommonItem
                      key={"fo_" + ind}
                      filterObj={obj}
                      categoryObj={obj}
                      title={obj.filterName}
                      active={active}
                      filterType={obj.filterType}
                      onToggle={onChangeFilters}
                      searchFound={searchFound}
                      searchKey={searchKey}
                      selectedMarket={selectedMarket}
                      selectedGlobalBrand={selectedGlobalBrand}
                      page={page}
                      handleSegmentsActions={props.handleSegmentsActions}
                    />
                  );
                });

              //handling expired segment edit filters
              const isExpiredFilter = Boolean(
                currentSelectedFilters?.length &&
                  currentSelectedFilters?.find((obj) => {
                    return obj.filters.find(
                      ({ filterId }) =>
                        filterId === filter.filterId &&
                        filter.isExpired === true
                    );
                  })
              );

              //Displaying other filters
              const matchFilterName = filter.filterName
                ?.toLowerCase()
                ?.includes(searchKey?.toLowerCase());
              !Array.isArray(filter) &&
                ((isExpiredFilter && filter.isExpired) || !filter.isExpired) &&
                showOptions.push(
                  <div key={"ftitle" + indValue} className="titleContainer">
                    {searchFound && matchFilterName ? (
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: filter.filterName.replace(
                            new RegExp(`(${searchKey})`, "gi"),
                            (match) => `<span class='match'>${match}</span>`
                          ),
                        }}
                      />
                    ) : (
                      <div className="title">{filter.filterName}</div>
                    )}
                    {filter.isExpired && (
                      <div className="segmentWarning">
                        <RxQuestionMarkCircled className="warningIcons" />
                        <span className={"tooltipWarning"}>
                          {filter.filterName} filter is Expired.
                        </span>
                      </div>
                    )}
                  </div>
                );
              let foptions = filter?.filterOptions;
              if (searchKey?.length > 0) {
                let filterOptionsMatchFound = foptions?.findIndex(
                  ({ filterOptionName }) =>
                    filterOptionName
                      ?.toLowerCase()
                      ?.includes(searchKey?.toLowerCase())
                );
                if (filterOptionsMatchFound > -1) {
                  foptions = foptions?.filter(({ filterOptionName }) =>
                    filterOptionName
                      ?.toLowerCase()
                      ?.includes(searchKey?.toLowerCase())
                  );
                }
              }
              ((isExpiredFilter && filter.isExpired) || !filter.isExpired) &&
                foptions &&
                foptions.forEach((option, index) => {
                  const active = Boolean(
                    currentSelectedFilters?.length &&
                      currentSelectedFilters?.find((obj) => {
                        return obj.filters.find(
                          ({ filterId, filterOptions }) => {
                            if (filterId === filter.filterId) {
                              return filterOptions.find(
                                ({ filterOptionId }) =>
                                  filterOptionId === option.filterOptionId
                              );
                            }
                          }
                        );
                      })
                  );
                  showOptions.push(
                    <FilterCommonItem
                      key={"fo_" + index}
                      filterObj={filter}
                      optionObj={option}
                      title={option.filterOptionName}
                      active={active}
                      categoryObj={categoryObj}
                      filterType={filter.filterType}
                      onToggle={filter.isExpired ? null : onChangeFilters}
                      isExpired={filter.isExpired}
                      searchFound={searchFound}
                      searchKey={searchKey}
                    />
                  );
                });
              let ageRangeSliderDefaultValues = getRangeSliderDefaultValues(
                page,
                selectedMarket,
                selectedGlobalBrand
              );

              return (
                <>
                  {filter.filterName !== "AGE RANGE" ? (
                    showOptions.length > 0 && (
                      <div key={"filter" + indValue}> {showOptions} </div>
                    )
                  ) : (
                    <RangeSlider
                      ref={ageSlider}
                      active={true}
                      rangeData={
                        currentSelectedFilters?.length > 0 &&
                        cateroryIndex > -1 &&
                        rangeData?.length > 0
                          ? rangeData
                          : [ageRangeSliderDefaultValues[0], 100]
                      }
                      disabled={
                        currentSelectedFilters.findIndex((cf) => {
                          return (
                            cf.categoryId === 1 &&
                            cf.filters[0].filterId === 7 &&
                            cf.filters[0].filterOptions.length > 0
                          );
                        }) > -1
                      }
                      onToggle={onChangeRange}
                      defaultValues={ageRangeSliderDefaultValues}
                    />
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
    )
  );
};

export default BrandFilterCard;
