import React, { Component } from "react";
import cx from "classnames";
import { get } from "lodash";
import initData from "../../../utils/initData";
import BrandTitle from "./BrandTitle";
import hideIcon from "../../../assets/icons/icon_hide.svg";
import "./ItemList.scss";
import ApiData from "../../../utils/Api";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../utils/storageHelpers";
import { ChromePicker } from "react-color";

export default class itemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showColor: false,
      id: "",
      colorChange: false,
      index: "",
    };
  }
  showColorPicker = (item, listItem) => {
    this.setState({
      showPicker: !this.state.showPicker,
      id: item.id,
      color:
        item.brandUpdation === "yes" || this.props.page === "global"
          ? item.color
          : listItem.color,
    });
  };

  handleMouseLeave = (index) => {
    this.setState({ showPicker: false, showColor: false, index: "" });
  };

  onClose = async (listKey, listitem, item, index) => {
    let { selectedMarket } = this.props;
    const myUserInfo = getLocalStorage("userInfo");
    const userId = myUserInfo ? JSON.parse(myUserInfo).id : "USER";
    if (listKey === "brand" && this.props.page === "competitive") {
      let colorObject = {
        entityId: item.brand.id,
        position: null,
        color: this.state.color,
      };
      let result = [];
      result.push(colorObject);
      ApiData.updateBrandsOrderOrColour(userId, selectedMarket, result)
        .then(async () => {
          // brandInfo was updated, but the data in localStorage was not. Let's request the updated one
          await this.props.getList();
          return await ApiData.getUserBrandsByCampaign(userId, selectedMarket);
        })
        .then((brands) => {
          if (brands.length) {
            setLocalStorage(
              "myBrands",
              JSON.stringify(
                brands.map((v) => (v.id === listitem.id ? listitem : v))
              )
            );
          }
        })
        .catch((error) => {});
    }
    if (listKey === "segment") {
      let payload = {
        color: this.state.color,
      };
      ApiData.updateSegmentColor({ segmentId: item.segment.segmentId, payload })
        .then(async () => {
          // segmentsInfo was updated, but the data in localStorage was not. Let's request the updated one
          await this.props.getList(selectedMarket);
          await this.props.getSegments(selectedMarket);
          // return ApiData.getUserSegments(userId);
        })
        .catch((error) => {});
    }
    if (this.props.page === "global") {
      listitem.color = this.state.color;
      item.color = this.state.color;
      let colorObject = {
        entityId: item.campaign.marketId,
        position: null,
        color: this.state.color,
      };
      let result = [];
      result.push(colorObject);

      let brands = JSON.parse(getLocalStorage("globalBrands"));
      brands.map((brand) => {
        brand.marketGroups.filter((obj) =>
          obj.marketGroupsList
            .filter((campaign) => campaign?.marketId === item.campaign.marketId)
            .map((camp) => (camp.color = this.state.color))
        );
      });
      await setLocalStorage("globalBrands", JSON.stringify(brands));
      ApiData.updateGeoOrderOrColour(userId, result);
      await this.props.getList();
    }

    this.setState(
      {
        showColor: true,
        showPicker: false,
      },
      () => {
        this.props.onClickShowOrHide("", true);
      }
    );
  };

  handleChangeComplete = (e, item) => {
    const newColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")",
    };
    this.setState({ color: newColor.hex, colorChange: true });
  };

  getColor = (listColor, listitem) => {
    if (listitem.id === this.state.id && this.state.showColor) {
      return (listColor = this.state.color);
    }
    return listitem.brandUpdation === "yes" || this.props.page === "global"
      ? listitem.color
      : listColor;
  };

  render() {
    const {
      listData,
      hiddenList,
      listClass,
      onClickShowOrHide,
      listKey,
      defaultList,
      showSsize,
      statTestingEnabled,
      charKeyMap,
      page,
      movingAverage,
      customSegment,
      customSegmentpage,
      selectedTab,
    } = this.props;
    const bcolors = initData.getColors().map((color) => color[0]);
    let regionState;
    return (
      <div className={listClass} id="clickbox">
        {listData.map((item, index) => {
          const listitem = listKey === "self" ? item : item[listKey];
          const listColor = item.color;
          const brands = JSON.parse(localStorage.getItem("myBrands"));
          if (page === "global") {
            item.campaign.marketId = item.campaign?.marketId;
            regionState = item.regionState;
            listitem.id = item.campaign?.marketId;
            listitem.countryCode = item.countryCode
              ? item.countryCode
              : item.groupName;
          }
          if (page === "segments") {
            listitem.name = item.segment.segmentName;
            listitem.id = item.segment.segmentId;
          }
          if (
            Number(listitem.id) === Number(this.state.id) &&
            listKey === "brand" &&
            this.state.showColor
          ) {
            item.brand.brandUpdation = "yes";
            item.brand.color = this.state.color;
            localStorage.setItem(
              "myBrands",
              JSON.stringify(
                brands.map((v) => (v.id === listitem.id ? listitem : v))
              )
            );
          }
          if (
            Number(listitem.id) === Number(this.state.id) &&
            listKey === "segment" &&
            this.state.showColor
          ) {
            item.color = this.state.color;
            item.segment.color = this.state.color;
          }

          const itemClass = cx("brandItemRightContainer", {
            noHidden: item?.id
              ? item?.id === -1
              : item?.segment?.segmentName === "Age 18+" ||
                item?.segment?.segmentName === "Age 35+",
            brandHidden:
              item?.id !== -1 && hiddenList.indexOf(listitem.id) > -1,
          });
          let samplesize = item.data?.pool || item.data?.base || null;
          let dailyAverageSampleSize = 0;
          const noData = get(item, "data", { filterOptions: {} }).length === 0;
          if (showSsize && Array.isArray(item.data)) {
            samplesize = 0;
            dailyAverageSampleSize = Number(
              item?.data[0]?.dailyAverageSampleSize
            );
            item.data.forEach((d) => {
              if ((d.pool || d.poolTotal) !== null) {
                samplesize += Number(movingAverage ? d.poolTotal : d.pool);
              }
            });
          }
          const defaultAgeSegmentNames = initData.getDefaultAgeSegmentNames();
          return (
            <div key={index} onMouseLeave={() => this.handleMouseLeave(index)}>
              <div
                className={
                  index === 0 || index === 1
                    ? "show-color-picker-1"
                    : "show-color-picker"
                }
                onMouseLeave={() => this.handleMouseLeave(index)}
              >
                {listitem.id === this.state.id &&
                this.state.showPicker &&
                page ? (
                  <div
                    className="color-picker"
                    style={{ position: "absolute", zIndex: 9999 }}
                    onMouseLeave={() => this.handleMouseLeave(index)}
                  >
                    <ChromePicker
                      className="arrow-right"
                      color={this.state.color}
                      id="chrome-picker"
                      onChangeComplete={this.handleChangeComplete}
                      disableAlpha={true}
                      width="50%"
                      renderers={false}
                    />
                    <button
                      style={{
                        position: "absolute",
                        bottom: "12px",
                        height: "33px",
                        background: "rgb(36, 31, 31)",
                        borderRadius: "5px",
                        zIndex: 9,
                        left: "47%",
                        width: "77px",
                      }}
                      onClick={() =>
                        this.onClose(listKey, listitem, item, index)
                      }
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: "10px",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Save Color
                      </span>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="brandItem" key={"litem" + listitem.id}>
                <div
                  className={page ? "legend" : "color"}
                  style={{
                    backgroundColor: this.getColor(
                      listColor ? listColor : bcolors[index % 10],
                      listitem
                    ),
                  }}
                  onMouseOver={() => this.showColorPicker(listitem, item)}
                />
                <div
                  className={itemClass}
                  style={{ display: "block" }}
                  onClick={
                    !defaultAgeSegmentNames?.includes(listitem.name)
                      ? () => onClickShowOrHide(listitem.id)
                      : null
                  }
                >
                  <BrandTitle
                    brand={listitem}
                    customSegment={customSegment}
                    noLogo={listKey === "segment"}
                    noData={noData}
                    listClass={listClass}
                    ssize={samplesize}
                    dailyAverageSampleSize={dailyAverageSampleSize}
                    statTestingEnabled={statTestingEnabled}
                    charKeyMap={charKeyMap}
                    page={page}
                    regionState={regionState}
                    isFiltersExpiredInSegment={
                      listitem?.isFiltersExpiredInSegment
                    }
                    isAllFiltersPresentInMarket={listitem?.isActive}
                    customSegmentpage={customSegmentpage}
                    selectedTab={selectedTab}
                    brandHidden={
                      item?.id !== -1 && hiddenList.indexOf(listitem.id) > -1
                    }
                  />
                  <div
                    className={cx("showOrHideIconContainer", {
                      rightRem: statTestingEnabled,
                    })}
                    style={{ top: "24px" }}
                  >
                    <img src={hideIcon} alt="" className="showOrHideIcon" />
                  </div>
                </div>
                {listKey === "self" &&
                  defaultList.indexOf(index) < 1 &&
                  listitem.id !== -1 && (
                    <div className="operateContainer">
                      {!listitem?.isFiltersExpiredInSegment &&
                        listitem?.isAllFiltersPresentInMarket === true && (
                          <div
                            onClick={() =>
                              this.props.itemOprateClick("edit", listitem.id)
                            }
                          >
                            Edit
                          </div>
                        )}
                      <div
                        onClick={() =>
                          this.props.itemOprateClick("del", listitem.id)
                        }
                      >
                        Delete
                      </div>
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
