import React, { Component } from 'react';
import { get } from 'lodash';
import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import initData from '../../../utils/initData';
import _ from 'lodash';
import SimpleSelect from '../Shared/SimpleDropDownSelect';
import plusIcon from '../../../assets/icons/icon_plus_big.svg';
import ItemList from '../Shared/ItemList';
import FilterDropdown from '../OptionFilters/FilterDropdown';
import OutsideClickCatcher from '../../common/OutsideClickCatcher';
import {
    disableZAxis,
    enableZAxis,
    disableBrand,
    disableSegment,
    disableCustomSegment,
    enableBrand,
    enableSegment,
    enableCustomSegment,
} from '../../../reducers/TwoByTwoChart/actionCreators';

import './TwoByTwoChart.scss';
import NoDataBox from '../Shared/NoDataBox';

const categoriesList = initData.getStabOptions();
const subcategoriesList = initData.getSubcategoriesList();
const selectTypes = {
  main: 'main',
}

class TwoByTwoChart extends Component {
  state = {
    axisSelectOpened: '',
    chartData: {
      xMaxValue: 0,
      yMaxValue: 0,
      items: [],
    },
  }

  axisNames = {
    x: 'x',
    y: 'y',
    z: 'z',
  }

  static getDerivedStateFromProps(props, state) {
    const { zAxisDisabled } = props;
    const {
        chartData: data,
        current2x2MainFilter,
        isDetailed,
        hiddenBrandIdList,
        hiddenSegmentIdList,
        hiddenCustomSegmentIdList,
    } = props;
    const currentHiddenSegmentIdList = current2x2MainFilter ? hiddenCustomSegmentIdList : hiddenSegmentIdList;
    const hiddenList = isDetailed ? currentHiddenSegmentIdList : hiddenBrandIdList;

    if (data) {
      const flatDataArr = []
      for (const i in data) {
        const dataObj = data[i].data
        if (dataObj.filterOptions) {
          for (const j in dataObj.filterOptions) {
            flatDataArr.push({
                id: isDetailed ? data[i].segment.id : data[i].brand.id,
                name: j,
                filterName: isDetailed && data[i].segment.name,
                color: data[i].color,
                percent: {
                    x: dataObj.filterOptions[j].x * 100,
                    y: dataObj.filterOptions[j].y * 100,
                    z: dataObj.filterOptions[j].z * 100,
                },
                pool: dataObj.filterOptions[j].base
            })
          }
        } else {
          flatDataArr.push({
            id: isDetailed ? data[i].segment.id : data[i].brand.id,
            name: isDetailed ? data[i].segment.name : data[i].brand.name,
            color: data[i].color,
            percent: {
                x: data[i].data.x * 100,
                y: data[i].data.y * 100,
                z: data[i].data.z * 100,
            },
            pool: data[i].data.base
          })
        }
      }

      const xMaxValue = Math.ceil(Math.max(...flatDataArr.map(item => item.percent.x).filter(item => !isNaN(item))));
      const yMaxValue = Math.ceil(Math.max(...flatDataArr.map(item => item.percent.y).filter(item => !isNaN(item))));
      const xAxisDelta = Math.ceil(xMaxValue / 5);
      const yAxisDelta = Math.ceil(yMaxValue / 5);
      let xMaxAxisValue = Math.floor(xMaxValue/xAxisDelta) * xAxisDelta + xAxisDelta;
      let yMaxAxisValue = Math.floor(yMaxValue/yAxisDelta) * yAxisDelta + yAxisDelta;
      // if (xMaxAxisValue > 100) xMaxAxisValue = 100    UNCOMMENT TO SET MAX AXIS VALUES!!!
      // if (yMaxAxisValue > 100) yMaxAxisValue = 100
      const xCoef = 100 / xMaxAxisValue;
      const yCoef = 100 / yMaxAxisValue;
      const minCircleSize = 60;
      const maxCircleSize = 250;
      const minPercentValue = 0;
      const maxPercentValue = 100;
      const styledData = flatDataArr.map(item => {
          const z = item.percent.z;
          return {
              ...item,
              diameter: zAxisDisabled
                ? minCircleSize * 2
                : minCircleSize + (maxCircleSize - minCircleSize)/(maxPercentValue - minPercentValue) * z,
              coords: {
                  x: item.percent.x * xCoef,
                  y: item.percent.y * yCoef,
              },
              zIndex: 0,
              opacity: 1,
          }
      })

      for (const i in styledData) {
          for (const j in styledData) {
              const first = styledData[i]
              const second = styledData[j]
              const length = Math.sqrt((first.coords.x - second.coords.x)**2 + (first.coords.y - second.coords.y)**2)

              if (first.id !== second.id && length < (first.diameter + second.diameter)/2) {
                  if (first.diameter > second.diameter) {
                      styledData[j].zIndex += 1
                      if (styledData[j].opacity === 1) {
                          styledData[j].opacity = 0.85
                      }
                  }
              }
          }
      }
      return {
        chartData: {
          items: styledData.filter(item => hiddenList.every(hiddenSegmentId => hiddenSegmentId !== item.id)),
          xAxisDelta,
          yAxisDelta,
          xMaxAxisValue,
          yMaxAxisValue,
        }
      }
    }
  }

  onAxisSelectOpen = (axis) => () => {
    const { axisSelectOpened } = this.state;
    const nextValue = axisSelectOpened === axis ? '' : axis;
    this.setState({ axisSelectOpened: nextValue });
  }

  getTooltipAxisTitle = (axisSelect) => {
      const { category, subcategory } = axisSelect;

      const title = category === 4
          ? initData.getStabOptions().find(({ id }) => id === 4).name
          : _.get(subcategory, 'name', '');

      return title;
  }

  renderTooltip(data, tooltipStyle) {
    const { zAxisDisabled } = this.props;
    const { xAxisSelect, yAxisSelect, zAxisSelect } = this.props;
    const { percent: { x, y, z }, name, pool, filterName, color } = data;

    return (
      <div className={'diagramTooltip'} style={tooltipStyle}>
        <div className={'diagramTooltipTitle'}>
          {/* {initData.getTooltipLabels(name) || name} */}
          {filterName && <span style={{ color: color }}>{filterName}</span>}
          <div className={'absoluteValue'}>n={Number(pool.toFixed(0)).toLocaleString('en-En')}</div>
        </div>
        <div className={'valuesBlock'}>
          <div className={'row'}>
              <div className={'axis'}>{this.getTooltipAxisTitle(xAxisSelect)}</div>
              <div className={'value'}>{x.toFixed(1)}{this.getIsEquity('x') ? '' : '%'}</div>
          </div>
          <div className={'row'}>
              <div className={'axis'}>{this.getTooltipAxisTitle(yAxisSelect)}</div>
              <div className={'value'}>{y.toFixed(1)}{this.getIsEquity('y') ? '' : '%'}</div>
          </div>
          {!zAxisDisabled &&
          <div className={'row'}>
              <div className={'axis'}>{this.getTooltipAxisTitle(zAxisSelect)}</div>
              <div className={'value'}>{z.toFixed(1)}{this.getIsEquity('z') ? '' : '%'}</div>
          </div>
          }
        </div>
      </div>
    )
  }

  renderAxisLines() {
    const { chartData: { xMaxAxisValue, yMaxAxisValue, xAxisDelta, yAxisDelta, items } } = this.state
    const xAxisMin = 0;
    const yAxisMin = xAxisDelta;
    const xValues = [];
    const yValues = [];
    const xMaxScale = 100;
    const yMaxScale = 100;
    if (!items || !items.length) return null
    for (let i = yAxisMin; i <= xMaxAxisValue; i += xAxisDelta) {
      yValues.push(i);
    }

    for (let i = xAxisMin; i <= yMaxAxisValue; i += yAxisDelta) {
      xValues.push(i);
    }

    return (
      <>
        <div>
          {xValues.map((value, index) => {
            return (
              <div key={index} className={'xAxis'} style={{top: `${(xMaxScale/(xValues.length)) * (xValues.length - index)}%`}}>
                <div className={'valueContainer'}>{value}</div>
                <div className={'axisLine'}/>
              </div>
            )
          })}
        </div>
        <div>
          {yValues.map((value, index) => {
            return (
              <div key={index} className={'yAxis'} style={{left: `${(yMaxScale/(yValues.length)) * (index + 1)}%`}}>
                <div className={'axisLine'}/>
                <div className={'valueContainer'}>{value}</div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  renderChartElems() {
    const { chartData: { items } } = this.state;
    return (
      <div className="map">
        {items.map((item, index) => {
          const { coords: { x, y }, diameter } = item
          const itemStyle = {
            width: diameter,
            height: diameter,
            backgroundColor: item.color,
            zIndex: item.zIndex,
            opacity: item.opacity
          }
          const tooltipStyle = {
            left: `-${(200-diameter)/2}px`,
          }
          const containerStyle = {
            left: `calc(${x}% - ${diameter/2}px)`,
            bottom: `calc(${y}% + ${diameter/2}px)`,
          }

          if (x < 5) {
            tooltipStyle.left = `${(diameter)/2}px`
          } else if (x > 95) {
            tooltipStyle.left = 'unset'
            tooltipStyle.right = `-${(diameter)/2}px`
          }
          if (y > 50) {
            tooltipStyle.top = `${diameter + 10}px`
            tooltipStyle.bottom = 'initial'
          }
          return (
            <div key={index} className={'chartItemContainer'} style={containerStyle}>
              <div className={'chartItem'} style={itemStyle}>
                {this.getBubbleValue(item)}
              </div>
              {this.renderTooltip(item, tooltipStyle)}
            </div>
          )
        })}
      </div> 
    )
  }

  getIsEquity = (axisName) => {
    const axisSelect = get(this.props, `${axisName}AxisSelect`);
    const {category } = axisSelect;
    const isEquity = category === 0;

    return isEquity;
  };

  getBubbleValue = (item) => {
      const { zAxisDisabled } = this.props;
      const {
          percent: { z },
      } = item;

      const isEquityTotal = this.getIsEquity('z');
      const value = isEquityTotal
          ? z.toFixed(1)
          : `${z.toFixed(1)}%`;
      return zAxisDisabled ? '' : isNaN(value)? null : value ;
  };

  axisSelectOptionChange = (axis) => (option) => {
    this.props.axisSelectOptionChange(axis)(option)
    this.setState({ axisSelectOpened: '' })
  }

  toggleZAxisData = () => {
    const { zAxisDisabled } = this.props;
    const { disableZAxis, enableZAxis } = this.props.actions;

    zAxisDisabled ? enableZAxis() : disableZAxis();
  }

  renderChartArea() {
    const { xAxisSelect, yAxisSelect, zAxisSelect, zAxisDisabled, isDetailed } = this.props;
    const { axisSelectOpened } = this.state;
    const categoriesToShow = isDetailed ? categoriesList : categoriesList.slice(0, -1); // Size should be available only for Detailed Discover
    const xCategoryTitle = `${xAxisSelect.category !== 4 ? '/' : ''}${categoriesToShow.find(category => category.id === xAxisSelect.category).name}`;
    const yCategoryTitle = `${yAxisSelect.category !== 4 ? '/' : ''}${categoriesToShow.find(category => category.id === yAxisSelect.category).name}`;
    const zCategoryTitle = `${zAxisSelect.category !== 4 ? '/' : ''}${categoriesToShow.find(category => category.id === zAxisSelect.category).name}`;
    const { chartData: { items } } = this.state;
    return (
      items && items.length && items.map((item)=>item.coords).some((coord)=>!isNaN(coord.x) && !isNaN(coord.y)) ?
      <div className={'chartArea'}>
        <div className={'chartContainer'}>
          {this.renderAxisLines()}
          {this.renderChartElems()}
        </div>
          <div className={cn('axisSelect', {'axisSelect--selected': axisSelectOpened === this.axisNames.x})}
            onClick={this.onAxisSelectOpen(this.axisNames.x)}
          >
          <div className={'textBlock'}>
          <div className="brandLogo textLogo">
              <div>{"X"}</div>
          </div>
            <div className={'subtitle'}>{_.get(xAxisSelect, 'subcategory.name', '')}</div>
            <div className={'title'}>{xCategoryTitle}</div>
          </div>
          {axisSelectOpened === this.axisNames.x &&
              <OutsideClickCatcher
                  handler={() => this.setState({ axisSelectOpened: '' })}
              >
                  <FilterDropdown
                      currentAxis={axisSelectOpened}
                      selectedOptions={xAxisSelect}
                      title={'X-Axis Variable'}
                      onChangeLineOption={this.axisSelectOptionChange(this.axisNames.x)}
                      categoriesList={categoriesToShow}
                      subcategoriesList={subcategoriesList}
                  />
              </OutsideClickCatcher>
          }
        </div>
        <div className={cn('axisSelectVertical', {'axisSelect--selected': axisSelectOpened === this.axisNames.y})}
          onClick={this.onAxisSelectOpen(this.axisNames.y)}>
          <div className={'textBlock'}>
          <div className="brandLogo textLogo">
              <div>{"Y"}</div>
          </div>
            <div className={'subtitle'}>{_.get(this.props.yAxisSelect, 'subcategory.name', '')}</div>
            <div className={'title'}>{yCategoryTitle}</div>
          </div>
          {axisSelectOpened === this.axisNames.y &&
              <OutsideClickCatcher
                  handler={() => this.setState({ axisSelectOpened: '' })}
              >
                  <FilterDropdown
                      currentAxis={axisSelectOpened}
                      selectedOptions={yAxisSelect}
                      title={'Y-Axis Variable'}
                      onChangeLineOption={this.axisSelectOptionChange(this.axisNames.y)}
                      categoriesList={categoriesToShow}
                      subcategoriesList={subcategoriesList}
                  />
              </OutsideClickCatcher>
          }
        </div>
        <div className={cn('axisSelectRight', {'axisSelect--selected': axisSelectOpened === this.axisNames.z})}
          onClick={this.onAxisSelectOpen(this.axisNames.z)}>
          <div className={'textBlock'}>
             <div className="brandLogo textLogo">
               <div>{"Z"}</div>
             </div>
            <div className={'subtitle'}>{_.get(zAxisSelect, 'subcategory.name', '')}</div>
            <div className={'title'}>{zCategoryTitle}</div>
          </div>
          {axisSelectOpened === this.axisNames.z &&
              <OutsideClickCatcher
                  handler={() => this.setState({ axisSelectOpened: '' })}
              >
                  <FilterDropdown
                      currentAxis={axisSelectOpened}
                      selectedOptions={zAxisSelect}
                      title={'Z-Axis Variable'}
                      onChangeLineOption={this.axisSelectOptionChange(this.axisNames.z)}
                      categoriesList={categoriesToShow}
                      subcategoriesList={subcategoriesList}
                      zAxisDisabled={zAxisDisabled}
                      toggleZAxisData={this.toggleZAxisData}
                  />
              </OutsideClickCatcher>
          }
        </div>
      </div> : 
      <NoDataBox/>
    )
  }

  onShowOrHideClick = (id) => {
      const {
          actions: {
              enableBrand,
              enableSegment,
              enableCustomSegment,
              disableBrand,
              disableSegment,
              disableCustomSegment,
          },
          chartData,
          current2x2MainFilter,
          hiddenBrandIdList,
          hiddenSegmentIdList: hiddenAllSegmentIdList,
          hiddenCustomSegmentIdList,
          isDetailed,
          showAdminPanelMessage,
      } = this.props;

      const listData = this.getListData();
      const hiddenSegmentIdList = current2x2MainFilter ? hiddenCustomSegmentIdList : hiddenAllSegmentIdList;
      const list = isDetailed ? hiddenSegmentIdList : hiddenBrandIdList;
      let action;
      let requestAdditionalData = false;
      let forbidShowToggle = false;
      const maxVisibleSegments = 10;
      const currentlyVisibleSegments = listData.length - hiddenSegmentIdList.length;
      const { types } = initData.getStatusMessages();

      /*
        we have separate lists for allSegments and customSegments (just as actions)
        pick the correct action based on select active value
       */
      const enableSegmentAction = current2x2MainFilter ? enableCustomSegment : enableSegment;
      const disableSegmentAction = current2x2MainFilter ? disableCustomSegment : disableSegment;

      if (list.includes(id)) {
          forbidShowToggle = isDetailed ? currentlyVisibleSegments === maxVisibleSegments : false;
          action = isDetailed ? enableSegmentAction : enableBrand;
          requestAdditionalData = isDetailed ? !chartData.find(({ segment }) => id === segment.id) : false;
      } else {
          action = isDetailed ? disableSegmentAction : disableBrand;
      }

      forbidShowToggle ? showAdminPanelMessage(types.segmentsLimit) : action(id);
      (isDetailed && requestAdditionalData && !forbidShowToggle) && this.props.requestMoreSegmentsData();
  };

  getListData = () => {
      const segmentsPage = 'segments';
      const allSegmentsMainFilter = 0;
      const {
          chartData,
          current2x2MainFilter,
          page,
          segmentList,
      } = this.props;
      const bcolors = initData.getColors().map(c=>{return c[0]});

      return (page === segmentsPage && current2x2MainFilter === allSegmentsMainFilter)
          ? segmentList.map((segment, i) => ({ segment, color: bcolors[i % 10] }))
          : chartData;
  };

  getHiddenListData = () => {
      const {
          current2x2MainFilter,
          hiddenBrandIdList,
          hiddenSegmentIdList: hiddenAllSegmentIdList,
          hiddenCustomSegmentIdList,
          isDetailed,
      } = this.props;
      const hiddenSegmentIdList = current2x2MainFilter ? hiddenCustomSegmentIdList : hiddenAllSegmentIdList;

      return isDetailed ? hiddenSegmentIdList : hiddenBrandIdList;
  }

  render() {
      const {
          main2x2FilterOptions,
          current2x2MainFilter,
          option2x2Open,
          set2x2OptionOpen,
          onSelect2x2MainFilter,
          isDetailed,
          onClickFilterFunction,
          customSegmentList,
          page,
      } = this.props;
      const listData = this.getListData();
      const hiddenListData = this.getHiddenListData();
      const { chartData: { items } } = this.state;
      return (
        items && listData && items.length?
        <div className={'twoByTwoChart'}>
          <div className={'leftBlock'}>
              {isDetailed && (
                <div className={'selectContainer'}>
                  <div className={'mainSelectContainer'}>
                    <SimpleSelect
                      data={main2x2FilterOptions}
                      selected={current2x2MainFilter}
                      open={option2x2Open === selectTypes.main}
                      showSsize
                      placeholder="" skey="c_"
                      onOpenSelect={() => set2x2OptionOpen(selectTypes.main)}
                      onSelectFunction={onSelect2x2MainFilter}
                    />
                  </div>
                </div>
              )}
              <div className={'segments'}>
                <ItemList
                  listData={listData}
                  showSsize={true}
                  listKey={isDetailed ? "segment" : "brand"}
                  defaultList={isDetailed ? [0] : []}
                  hiddenList={hiddenListData}
                  listClass="trendsLeftContainer"
                  onClickShowOrHide={this.onShowOrHideClick}
                  page={page}
                />
                {isDetailed && current2x2MainFilter === 1 && customSegmentList.length < 10 && (
                  <div className={'buttonContainer'}>
                    <div className={'titleBlock'}>
                      <div className="iconContainer" onClick={onClickFilterFunction}>
                        <img className="plusIcon" src={plusIcon} alt="plus icon" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
          </div>
          {this.renderChartArea()}
        </div>:
        <NoDataBox/>
      )
  }
}

const mapStateToProps = ({ twoByTwoChart }) => ({
    zAxisDisabled: twoByTwoChart.zAxisDisabled,
    brandList: twoByTwoChart.brandList,
    segmentList: twoByTwoChart.allSegmentList,
    customSegmentList: twoByTwoChart.customSegmentList,
    hiddenBrandIdList: twoByTwoChart.hiddenBrandIdList,
    hiddenSegmentIdList: twoByTwoChart.hiddenSegmentIdList,
    hiddenCustomSegmentIdList: twoByTwoChart.hiddenCustomSegmentIdList,
    chartData: twoByTwoChart.chartData,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        disableZAxis,
        enableZAxis,
        disableBrand,
        disableSegment,
        disableCustomSegment,
        enableBrand,
        enableSegment,
        enableCustomSegment,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoByTwoChart);
