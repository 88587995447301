import React, { Component } from "react";
import cx from "classnames";
import initData from "../../../utils/initData";
import "./AdvertisingCard.scss";
import upIcon from "../../../assets/icons/icon_positive.svg";
import downIcon from "../../../assets/icons/icon_negative.svg";
import triangleUpIcon from "../../../assets/icons/icon_triangle.svg";
import triangleDownIcon from "../../../assets/icons/icon_triangle_down.svg";
import DonutChart from "./DonutChart";
import BrandTitle from "../Shared/BrandTitle";
import SegmentTitle from "../Shared/SegmentTitle";
import NoDataBox from "../Shared/NoDataBox";
import { get, isEmpty } from "lodash";
import createStatTestingMessage from "../../../utils/createStatTestingMessage";

const colorIndex = { No: 1, Yes: 0 };

export default class AdvertisingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 170,
      closeFigureData: false,
      aniStart: false,
    };
  }

  componentDidMount() {
    this.setState({
      aniStart: true,
    });
  }

  onClickTriangleIcon() {
    this.setState({
      closeFigureData: !this.state.closeFigureData,
    });
  }

  renderStatTestingArrow = (key, id) => {
    const { statTestingEnabled, lowerThan, higherThan, selectedDate, cperiod } =
      this.props;
    const higher = get(higherThan, id, []);
    const lower = get(lowerThan, id, []);
    const statTestingMessage = createStatTestingMessage({
      entity: key,
      toExclude: "brands",
      higherThan: higher,
      lowerThan: lower,
      period: cperiod,
      asComponent: true,
      startDate: selectedDate?.startdate,
      endDate: selectedDate?.enddate,
      isAdvertising: true,
    });
    if (statTestingEnabled) {
      if (
        !isEmpty(lowerThan) &&
        lowerThan[id] &&
        lowerThan[id].includes("<prevPeriod>")
      ) {
        return (
          <div className="upDownIcon withQText">
            <img src={downIcon} className="icon statArrow" alt="" height={14} />
            <span dangerouslySetInnerHTML={statTestingMessage()} />
          </div>
        );
      }
      if (
        !isEmpty(higherThan) &&
        higherThan[id] &&
        higherThan[id].includes("<prevPeriod>")
      ) {
        return (
          <div className="upDownIcon withQText">
            <img src={upIcon} className="icon statArrow" alt="" height={14} />
            <span dangerouslySetInnerHTML={statTestingMessage()} />
          </div>
        );
      }
    }
  };

  render() {
    const { closeFigureData, size } = this.state;
    const {
      brand,
      chartTitle,
      colors,
      cperiod,
      data,
      isDetailed,
      higherThan,
      lowerThan,
      logo,
      number,
      page,
      pool,
      segment,
      selectedTab,
      statTestingEnabled,
      tag,
      upDown,
      upDownNA,
      hideBars,
      countryCode,
      selectedDate,
    } = this.props;
    const { aniStart } = this.state;
    let icon = undefined;
    let containerStyle = "diagramContainer";
    let overlapContainerStyle = undefined;
    let donutData = data?.filter(
      (item) => item.label === "Advertising Recall"
    )[0];
    const donutChartData =
      donutData !== 100
        ? [
            { label: "Yes", value: donutData?.value },
            {
              label: "No",
              value: 100 - donutData?.value,
            },
          ]
        : [{ label: "Yes", value: donutData }];
    if (closeFigureData) {
      icon = triangleDownIcon;
      overlapContainerStyle = "overlapContainer additionMove";
    } else {
      icon = triangleUpIcon;
      overlapContainerStyle = "overlapContainer";
    }
    let filterData = data.filter((item) => item.label !== "Advertising Recall");
    filterData.sort((a, b) => {
      if (a.label?.toString() === "other") return 1;
      if (b.label?.toString() === "other") return 1;
      return a.value < b.value ? 1 : -1;
    });
    return (
      <div
        className={cx("cardContainer", "advertisingCompetitiveContainer", {
          segmentHidden: segment && !segment.isActive,
        })}
      >
        <div className="cardHeaderContainer" id={"cardHeaderContainer"}>
          {segment ? (
            <SegmentTitle
              segment={segment}
              pool={pool}
              onClickSegmentFilter={this.props.onClickSegment}
              page={page}
              selectedTab={selectedTab}
            />
          ) : (
            <BrandTitle
              brand={{ name: brand, logo: logo, countryCode: countryCode }}
              page={page}
              selectedTab={selectedTab}
              customClass={"equityCard"}
            />
          )}
          {!segment && Number(pool) > 0 && (
            <div className="value">
              n={Math.round(pool).toLocaleString("en-En")}
            </div>
          )}
        </div>
        <div className="scrollContainer">
          {number > 0 && data.length > 0 ? (
            <div className={containerStyle}>
              <div id="donutChartContainer" className={overlapContainerStyle}>
                <div className="donutCharContainer">
                  <DonutChart
                    colors={colors}
                    colorIndex={colorIndex}
                    data={donutChartData}
                    title={chartTitle}
                    number={
                      data?.filter(
                        (item) => item.label === "Advertising Recall"
                      )[0]?.value
                    }
                    brand={isDetailed ? segment.segmentName : brand}
                    tag={tag}
                    size={size}
                    cperiod={cperiod}
                    statTestingEnabled={statTestingEnabled}
                    higherThan={higherThan}
                    lowerThan={lowerThan}
                    upDown={upDown}
                    upDownNA={upDownNA}
                    page={page}
                    selectedTab={selectedTab}
                    isAdvertising={true}
                    selectedDate={selectedDate}
                    qText={donutData?.qText}
                  />
                </div>
                <img
                  src={icon}
                  alt=""
                  className="triangleIcon"
                  onClick={this.onClickTriangleIcon.bind(this)}
                />
              </div>
            </div>
          ) : (
            <NoDataBox />
          )}
          {pool > 0 && filterData.length > 0 && !hideBars && (
            <div className="bottomContainer">
              {filterData.map((item, index) => {
                const w = aniStart ? item.value.toFixed(1) + "%" : 0;
                return (
                  <div className="figureContainer" key={index}>
                    <div className="figureContainer">
                      <div className="figureTitle withQText">
                        {item.label}
                        <span>{item.qText}</span>
                      </div>
                      <div className="barContainer">
                        <div
                          className="percentBar"
                          style={{ width: w, backgroundColor: colors[0] }}
                        ></div>
                      </div>
                      <div className="figureData">{item.value.toFixed(1)}%</div>
                    </div>
                    <div style={{ padding: "5px" }}>
                      {this.renderStatTestingArrow(item.label, item?.id)}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
