const initialState = {
  messages: [],
  isTyping: false,
  isAssistantResponding: false,
  hasInitializedMessages: false,
  selectedValues: [],
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      const updatedMessages = [...state.messages, action.payload];
      localStorage.setItem("messages", JSON.stringify(updatedMessages));
      return { ...state, messages: updatedMessages };

    case "ADD_BULK_MESSAGE":
      return { ...state, messages: action.payload };

    case "USER_TYPING":
      return { ...state, isTyping: action.payload };

    case "ASSISTANT_RESPONDING":
      return { ...state, isAssistantResponding: action.payload };

    case "DELETE_ALL_MESSAGES":
      window.localStorage.removeItem("messages");
      return { ...state, messages: [] };

    case "SET_HAS_INITIALIZED_MESSAGES":
      return { ...state, hasInitializedMessages: action.payload };

    case "ADD_SELECTED_VALUE":
      return { ...state, selectedValues: [...state.selectedValues, action.payload] };

    case "REMOVE_SELECTED_VALUE":
      return {
        ...state,
        selectedValues: state.selectedValues.filter((value) => value !== action.payload),
      };
    case "REMOVE_ALL_SELECTED_VALUE":
      console.log("its coming here the state is getting updated ...")
      return {
        ...state,
        selectedValues :[]
      }

    default:
      return state;
  }
};

export default chatReducer;
