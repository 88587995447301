import React, { useEffect, useState, useRef } from "react";
import "./Views.scss";
import {
  getSessionStorage,
  setSessionStorage,
} from "../../utils/storageHelpers";
import {
  compareURLs,
  getURLPathParams,
} from "../../utils/URLParamsValidations";
import CreateEditView from "./CreateEditView";
import viewsInactive from "../../assets/icons/icon_views.svg";
import copIcon from "../../assets/icons/icon_views_clone.svg";
import shareView from "../../assets/icons/icon_views_make_default.svg";
import Toaster from "./Toaster";

const ViewsActions = (props) => {
  const {
    closeModal,
    showSaveViewsActionsModal,
    onChangeHandle,
    showViewNotification,
    queryParamslist,
  } = props;
  const actionsModalref = useRef(null);

  const [viewId, setViewId] = useState();
  const [debouncedValue, setDebouncedValue] = useState(false);
  const [viewsModalIsOpen, setViewsModalIsOpen] = useState(false);
  const [selectedViewAction, setSelectedViewAction] = useState("");
  const [toasterMessage, setToasterMessage] = useState(null);
  const [autoSaveview, setAutoSaveView] = useState(
    JSON?.parse(getSessionStorage("isAutoSaveViewButtonEnabled"))
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    let { viewId } = getURLPathParams();
    setViewId(viewId);
    updateViewURL();
  }, [window.location.href]);

  const updateViewURL = () => {
    let comparedUrl = compareURLs();
    onChangeHandle && onChangeHandle(comparedUrl);
    if (comparedUrl) {
      if (autoSaveview) {
        clearTimeout(debouncedValue);
        const timer = setTimeout(() => {
          console.log("call api");
        }, 1500);
        setDebouncedValue(timer);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      actionsModalref.current &&
      !actionsModalref.current.contains(event.target)
    ) {
      closeModal();
    }
  };

  const handleCreateEditActions = (action) => {
    closeModal();
    setViewsModalIsOpen(!viewsModalIsOpen);
    setSelectedViewAction(action);
  };

  const switchAutoSaveView = () => {
    setAutoSaveView(!autoSaveview);
    setSessionStorage("isAutoSaveViewButtonEnabled", !autoSaveview);
  };

  const handleCopy = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  const copyURL = () => {
    handleCopy(window.location.href);
    closeModal();
  };

  const handleToasterMessage = (messageObj) => {
    setToasterMessage({
      type: messageObj.type,
      message: messageObj.message
    });
  }

  const handleToasterClose = (_event, reason) => {
    if(_event) {
      _event.stopPropagation();
    }
    if (reason === "clickaway") {
      return;
    }
    setToasterMessage(null);
  };

  return (
    <div>
      {showSaveViewsActionsModal && (
        <div className="viewsContainer">
          <div className="actionsContainer" ref={actionsModalref}>
            <div className="listConatiner">
              <div
                className="listViewAction"
                onClick={() =>
                  handleCreateEditActions(viewId ? "edit" : "save")
                }
              >
                <img
                  src={viewsInactive}
                  className="viewsActionIcons"
                  alt=""
                  height={20}
                />
                Save View
              </div>
              {viewId && (
                <div
                  className="listViewAction"
                  onClick={() => handleCreateEditActions("save")}
                >
                  <img
                    src={copIcon}
                    className="viewsActionIcons"
                    alt=""
                    height={22}
                  />
                  Save as New View
                </div>
              )}
              <div className="listViewAction" onClick={copyURL}>
                <img
                  src={shareView}
                  alt=""
                  className="viewsActionIcons"
                  height={20}
                />
                Copy URL to Share
              </div>
            </div>

            {/* auto save feature 
            {viewId && (
              <>
                <div className="statTesting">
                  <Switch checked={false} onChange={""} />
                  <div onClick={switchAutoSaveView}>Autosave Changes</div>
                </div>
                <div className="statTestingContent">
                  Changes to brands, markets and filters applied are saved
                  automatically.
                </div>
              </>
            )} */}
          </div>
        </div>
      )}
      {(selectedViewAction === "save" || selectedViewAction === "edit") && (
        <CreateEditView
          isOpen={viewsModalIsOpen}
          closeModal={handleCreateEditActions}
          selectedViewAction={selectedViewAction}
          viewNotification={""}
          queryParamslist={queryParamslist}
          handleToasterMessage={handleToasterMessage}
        ></CreateEditView>
      )}
      {toasterMessage && (
        <Toaster
          isOpen={toasterMessage}
          variant={toasterMessage?.type}
          onClose={(e)=>handleToasterClose(e)}
        >
          {toasterMessage?.message}
        </Toaster>
      )}
    </div>
  );
};
export default ViewsActions;