import React, { Component } from "react";
import { get } from "lodash";
import "./BarChart.scss";
import * as d3 from "d3";
import initData from "../../../utils/initData";
import ItemList from "../Shared/ItemList";
import SimpleSelect from "../Shared/SimpleDropDownSelect";
import NoDataBox from "../Shared/NoDataBox";
import positiveIcon from "../../../assets/icons/icon_positive.svg";
import negativeIcon from "../../../assets/icons/icon_negative.svg";
import createStatTestingMessage, {
  getToExclude,
  excludeData,
  prevPeriod,
} from "../../../utils/createStatTestingMessage";

const noLineDataKey = ["pool", "date"];
const vkOptions = [
  { id: "value", name: "Out of 100 People" },
  { id: "percentage", name: "Percent" },
];
export default class BarChart extends Component {
  static chartName = "BarChart";

  constructor(props) {
    super(props);
    this.state = {
      yAxis: null,
      hiddenList: [],
      optionOpen: false,
      valueKey: "value",
    };
    this.getBarChartSetting();
    this.customYAxis = this.customYAxis.bind(this);
    this.customXAxis = this.customXAxis.bind(this);
    this.onClickShowOrHide = this.onClickShowOrHide.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.renderLabelsTooltip = this.renderLabelsTooltip.bind(this);
    this.onResize = this.onResize.bind(this);
    this.myInput = React.createRef();
  }

  getBarChartSetting = () => {
    const { valueKey } = this.state;
    const { page, statTestingEnabled } = this.props;
    let barOptions = [];
    let maxValue = 0;
    this.props.data.forEach((brandData) => {
      if (
        Array.isArray(brandData?.data?.data) &&
        brandData?.data?.data?.length > 0 &&
        barOptions?.length === 0
      ) {
        brandData.data.data.forEach((d) => {
          if (noLineDataKey.indexOf(d.label) < 0) {
            barOptions.push({ id: d.id, name: d.label, key: d.key });
          }
        });
      }
      let maxV =
        parseInt(
          Math.max.apply(
            0,
            brandData?.data?.data.map((d) => {
              return d[valueKey];
            })
          )
        ) + 5;
      if (maxV > maxValue) {
        maxValue = maxV;
      }
    });

    if (maxValue > 80 && page !== "advanced") {
      maxValue = maxValue + 40;
    }

    this.dataValid = barOptions.length > 0;
    this.optionsData = barOptions;
    this.yMax = maxValue;

    // to trigger recalculations and state update (yAxis)
    this.onResize();
  };

  componentDidMount() {
    d3.selectAll(".tooltip").remove();
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps?.data[0]?.data?.data[0]?.value !==
        this.props?.data[0]?.data?.data[0]?.value &&
        !isNaN(prevProps?.data[0]?.data?.data[0]?.value)) ||
      this.props?.menuExpand !== prevProps.menuExpand ||
      this.props.data?.length !== prevProps?.data.length
    ) {
      this.getBarChartSetting();
      this.onResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { data, page } = nextProps;
    state.charKeyMap = BarChart.createCharKeyMap(data, page);
    return state;
  }

  customYAxis(node) {
    const { yAxis, w } = this.state;
    let x2 = w.toString();

    node.call(yAxis);
    node.select(".domain").remove();
    node
      .selectAll(".tick line")
      .attr("stroke", "#DFE2EA")
      .attr("x1", "30")
      .attr("x2", x2);
    node
      .selectAll(".tick text")
      .attr("x", 4)
      .style("font-size", "0.8rem")
      .style("color", "#264653");
  }

  customXAxis(node) {
    let { selectedTab, selectedPrepostStab } = this.props;
    let data = this.optionsData;
    let cursorPointer =
      (selectedTab === 3 && selectedPrepostStab === 3) ||
      (selectedTab === 3 && selectedPrepostStab === undefined)
        ? "pointer"
        : "text";
    node.call(
      d3
        .axisBottom(this.xValue)
        .tickPadding(-20)
        .tickSizeInner(0)
        .tickSizeOuter(0)
    );
    node.selectAll(".domain").remove();
    node
      .selectAll("text")
      .data(data, (d, i) => i) // Bind data uniquely using index
      .join("text")
      .attr("class", "barChartStack")
      .attr("id", function () {
        let text = d3.select(this),
          words = text.text();
        return words;
      })
      .style("font-size", "0.8rem")
      .style("color", "#264653")
      .style("cursor", cursorPointer)
      .attr("transform", "translate(0," + (500 + 50) + ")")
      .style("transform", "rotate(-32deg) translateX(-55px) translateY(-1px)")
      .attr("y", "30")
      .text((d) => d.id) // Render ID for duplicate objects
      .call(this.wrap, data, selectedTab);
    node.selectAll("line").style("stroke", "#DFE2EA");
  }

  wrap(text, data, selectedTab) {
    text.each(function (_, i) {
      const textElement = d3.select(this);
      let words = textElement.text().split(/\s+/).reverse(); // Split text into words
      let word;
      const line = [];
      let lineNumber = 0;
      const lineHeight = 1;
      const y = 10;
      const dy = parseFloat(textElement.attr("dy")) || 0;
      let tspan = textElement
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", `${dy}em`);

      if (
        this.props?.selectedPrepostStab === 3
          ? this.props?.selectedPrepostStab === 3 && selectedTab === 3
          : selectedTab === 3
      ) {
        let adLabel = words.join("");
        let xyz = data.filter((obj) => {
          if (obj.id.split(/\s+/).reverse().join("") === adLabel) {
            return obj.name;
          }
        });
        words = initData
          .getAdvertisingAndEmotionalLabelText(xyz[0]?.name)
          ?.split(/\s+/)
          .reverse();
      } else {
        let adLabel = words.join("");

        let xyz = data.filter((obj) => {
          if (obj.id.split(/\s+/).reverse().join("") === adLabel) {
            return obj.name;
          }
        });
        words = initData
          .getAdvertisingAndEmotionalLabelText(xyz[0]?.name)
          ?.split(/\s+/)
          .reverse();
      }
      while ((word = words?.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > 130) {
          line.pop();
          tspan.text(line.join(" "));
          line.length = 0;
          line.push(word);
          tspan = textElement
            .append("tspan")
            .attr("x", 0)
            .attr("y", y + 2)
            .attr("dy", `${++lineNumber * lineHeight + dy}em`)
            .text(word);
        }
      }
    });
  }

  onResize() {
    const { listNotShow, data, selectedTab, page, menuExpand } = this.props;
    const paddingDelta = data.length / 2 / 100;
    let h =
      page === "advanced"
        ? window.innerHeight < 730
          ? 300
          : window.innerHeight - 420
        : window.innerHeight - 300;
    if (selectedTab === 1) {
      h = h - 100;
    }
    if (this.barChartCardContainer) {
      const w = Math.max(
        (data.length * 41 - data.length / 4) * this.optionsData.length + 60,
        !listNotShow
          ? window.innerWidth > 812
            ? this.barChartCardContainer.clientWidth - 420
            : this.barChartCardContainer.clientWidth
          : window.innerWidth > 812
          ? menuExpand
            ? window.innerWidth - 450
            : window.innerWidth - 300
          : window.innerWidth - 70
      );
      this.yValue = d3
        .scaleLinear()
        .domain([0, this.yMax])
        .nice()
        .range([h - 50, 0]);
      this.xValue = d3
        .scaleBand()
        .rangeRound([0, w])
        .padding(1 - paddingDelta)
        .domain(this.optionsData.map((d) => d.id));
      const chartWidth = this.myInput.current?.offsetWidth;

      // Set the tick values based on the calculated tick interval
      var tickValues = this.yValue.ticks();
      var formattedTickValues = tickValues.map(d3.format("d"));

      var uniqueTickValues = [];
      formattedTickValues.forEach(function (value) {
        if (uniqueTickValues.indexOf(value) === -1) {
          uniqueTickValues.push(value);
        }
      });

      this.setState(
        {
          w,
          h,
          yAxis: d3
            .axisRight(this.yValue)
            .tickValues(uniqueTickValues)
            .tickFormat(d3.format("d"))
            .tickPadding(-20)
            .tickSize(w),
          chartWidth,
        },
        this.renderTooltip
      );
      if (selectedTab === 3) {
        if (
          this.props.selectedPrepostStab &&
          this.props.selectedPrepostStab === 3
        ) {
          this.setState({}, this.renderLabelsTooltip);
        } else if (this.props.selectedPrepostStab === undefined) {
          this.setState({}, this.renderLabelsTooltip);
        }
      }
    }
  }

  onClickShowOrHide(index, loading) {
    let { hiddenList } = this.state;
    let position = hiddenList.indexOf(index);

    if (position === -1) {
      hiddenList.push(index);
    } else {
      hiddenList.splice(position, 1);
    }

    this.setState({ hiddenList });
    this.props.hiddenList(hiddenList);
    if (loading) {
      this.props.emulateLoading(true, false);
      setTimeout(() => this.props.emulateLoading(false, true), 500);
    }
  }

  onSelectVk(value) {
    this.setState(
      {
        valueKey: value,
        optionOpen: false,
      },
      () => {
        this.getBarChartSetting();
        this.renderTooltip();
      }
    );
  }

  renderLabelsTooltip() {
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("display", "none")
      .style("min-width", "5%")
      .style("position", "absolute")
      .style("margin-left", "-70px")
      .style("margin-top", "10px");

    const labelTooltip = tooltip.append("div").attr("class", "labelTooltip");
    let data = this.optionsData;
    d3.selectAll(".barChartStack")
      .on("mouseover", function (d) {
        let id = d3.select(this).node().id.split("_");
        let label = data?.find((obj) => {
          if (obj.id === id[0]) {
            return obj.name;
          }
        })?.name;
        labelTooltip.text(label);
      })
      .on("mousemove", function () {
        tooltip.style("display", "flex");
        return tooltip
          .style("top", d3.event.pageY - 100 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        return tooltip.style("display", "none");
      });
  }

  renderTooltip() {
    const { valueKey } = this.state;
    const { cperiod, isDetailed, page, statTestingEnabled, selectedDate } =
      this.props;
    const context = this;

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("display", "none");

    const brandBlock = tooltip.append("div").attr("class", "brand");

    const label = tooltip.append("div").attr("class", "label");

    const ssize = tooltip.append("div").attr("class", "sampleSize");

    const value = tooltip.append("div").attr("class", "value");

    const statTestingMessageBlock = tooltip
      .append("div")
      .attr("class", "statTooltip")
      .style("display", "none");

    d3.selectAll(".bar")
      .on("mouseover", function () {
        let id = d3.select(this).node().id.split("_");
        const visibleData = context.getVisibleData();
        value.text(
          id[0] === "Equity"
            ? visibleData[id[1]].data[valueKey]?.toFixed(1)
            : visibleData[id[1]].data.data
                ?.find((a) => {
                  return a.label === id[0];
                })
                [valueKey]?.toFixed(1)
        );
        brandBlock
          .text(
            page === "global"
              ? visibleData[id[1]].campaign.marketName
              : isDetailed
              ? visibleData[id[1]].segment.segmentName
              : visibleData[id[1]].brand.displayName
              ? visibleData[id[1]].brand.displayName
              : visibleData[id[1]].brand.name
          )
          .style("color", visibleData[id[1]].brand.color);
        label.text(id[0]);
        ssize.text(
          visibleData[id[1]].data.pool
            ? "n=" +
                Math.round(visibleData[id[1]].data.pool).toLocaleString("en-En")
            : ""
        );

        const { brand, segment, campaign, higherThan, lowerThan } =
          visibleData[id[1]];

        const toExclude = getToExclude(BarChart.chartName);
        const statTestingMessage = createStatTestingMessage({
          entity:
            page === "competitive"
              ? get(brand, "name", "")
              : page === "global"
              ? get(campaign, "marketName", "")
              : get(segment, "name", ""),
          toExclude,
          higherThan: get(
            higherThan,
            context.getStatTestingDataPath(id[0]),
            []
          ),
          lowerThan: get(lowerThan, context.getStatTestingDataPath(id[0]), []),
          period: cperiod,
          startDate: selectedDate?.startdate,
          endDate: selectedDate?.enddate
        });

        if (statTestingEnabled && statTestingMessage) {
          statTestingMessageBlock
            .html(statTestingMessage)
            .style("display", "block");
        }
      })
      .on("mousemove", function () {
        tooltip.style("display", "flex");
        return tooltip
          .style("top", d3.event.pageY - 100 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        statTestingMessageBlock.html("").style("display", "none");

        return tooltip.style("display", "none");
      });
  }

  static createCharKeyMap = (data, page) =>
    data.reduce((acc, el, i) => {
      const brandName =
        page === "competitive"
          ? get(el, "brand.name")
          : page === "global"
          ? get(el, "campaign.marketName")
          : get(el, "segment.segmentName");
      const charKey = (i) =>
        (i >= 26 ? charKey(Math.floor(i / 26) - 1) : "") +
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[i % 26];
      acc[brandName] = charKey(i);
      return acc;
    }, {});

  getHigherIconsList = (barData, higherThan, segmentName) => {
    const { charKeyMap } = this.state;
    const higher = get(
      higherThan,
      `${this.getStatTestingDataPath(barData.label)}`,
      []
    );
    const toExclude = getToExclude(BarChart.chartName);
    const higherExcluded = excludeData(higher, toExclude);

    let iconsList = higherExcluded.reduce((acc, el) => {
      if (el === prevPeriod) {
        acc.push(charKeyMap[segmentName]);
      } else {
        acc.push(charKeyMap[el]);
      }

      return acc;
    }, []);

    if (iconsList.length > 2) {
      iconsList = [...iconsList.slice(0, 1), `+${iconsList.length - 1}`];
    }

    return iconsList;
  };

  getVisibleData = () => {
    const { hiddenList } = this.state;
    const { data, isDetailed, page } = this.props;
    if (page === "global")
      return data.filter(
        (dataItem) => hiddenList.indexOf(dataItem.campaign?.marketId) < 0
      );
    else
      return data.filter(
        (dataItem) =>
          hiddenList.indexOf(
            isDetailed ? dataItem.segment.segmentId : dataItem.brand?.id
          ) < 0
      );
  };

  getStatTestingDataPath = (value) => {
    const { selectedTab } = this.props;
    /*
          The data keys between Conversion and other Tabs is slightly different.
          This method allows to retrieve it in the correct way.
      */

    return selectedTab === 1
      ? value.replace(/^./, (match) => match.toLowerCase())
      : value;
  };

  render() {
    const { hiddenList, w, h, valueKey, optionOpen, charKeyMap, chartWidth } =
      this.state;
    const {
      isDetailed,
      listNotShow,
      data,
      selectedTab,
      zThreshold,
      statTestingEnabled,
      page,
      selectedMarket,
    } = this.props;
    const sn = data.length - hiddenList.length;
    const dataShow = this.getVisibleData();
    let barWidth = 30;
    if (
      this.optionsData.length > 0 &&
      this.optionsData.length !== 25 &&
      this.optionsData.length > 15 &&
      sn > 0
    ) {
      barWidth = parseInt(((w - 60) / this.optionsData.length - 60) / sn);
    }
    if (barWidth > 30 || barWidth > 15 || selectedTab === 3) {
      barWidth = 30;
    }
    if (barWidth <= 15) {
      barWidth = 15;
    }
    let adLinePositions = [];
    return (
      <div
        className="barChartContainer"
        ref={(e) => (this.barChartCardContainer = e)}
      >
        {!listNotShow && (
          <ItemList
            listData={data}
            listKey={isDetailed ? "segment" : "brand"}
            defaultList={isDetailed ? [0] : []}
            listClass="equityLeftContainer"
            hiddenList={hiddenList}
            statTestingEnabled={statTestingEnabled}
            charKeyMap={charKeyMap}
            onClickShowOrHide={this.onClickShowOrHide}
            page={page}
            getList={this.props.getList}
            selectedMarket={selectedMarket}
            selectedBrand={this.props.selectedBrand}
            getMarkets={this.props.getMarkets}
          />
        )}
        <div className="equityRightContainer" ref={this.myInput}>
          <div
            style={
              page !== "advanced"
                ? {
                    position: "absolute",
                    width: `${chartWidth - 30}px`,
                    overflowX: "auto",
                  }
                : {}
            }
            className="barChartContent"
          >
            {" "}
            {selectedTab === 1 && (
              <div className="selectContainer">
                <SimpleSelect
                  data={vkOptions}
                  selected={valueKey}
                  open={optionOpen}
                  placeholder=""
                  skey="c_"
                  onOpenSelect={() =>
                    this.setState({ optionOpen: optionOpen ? false : true })
                  }
                  onSelectFunction={this.onSelectVk.bind(this)}
                />
              </div>
            )}
            {this.dataValid && dataShow.length > 0 && w && h && (
              <div className="zField">
                {dataShow.map((d, index) => {
                  return [...d.data.data].map((barData) => {
                    if (
                      barData.zValue &&
                      zThreshold < Math.abs(barData.zValue)
                    ) {
                      const xPosition =
                        this.xValue(barData.id) +
                        index * (barWidth + 5) -
                        (dataShow.length / 2) * barWidth -
                        (dataShow.length - 1) * 2.5;
                      const yPosition =
                        barData[valueKey] > 1
                          ? this.yValue(barData[valueKey])
                          : this.yValue(1);
                      const zStyle = {
                        position: "absolute",
                        left: xPosition + "px",
                        width: barWidth + "px",
                        top: yPosition - 30 + "px",
                        textAlign: "center",
                        color: barData.zValue < 0 ? "#1BA379" : "#E51E00",
                      };
                      const arrowIcon =
                        barData.zValue < 0 ? positiveIcon : negativeIcon;
                      const wQText =
                        barData.zValue < 0
                          ? " The post-period is statistically higher than the pre-period for this metric."
                          : " The post-period is statistically lower than the pre-period for this metric.";
                      return (
                        <div
                          className="zValue withQText"
                          key={barData.label + index}
                          style={zStyle}
                        >
                          <img src={arrowIcon} alt="" />
                          <span>{wQText}</span>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  });
                })}
              </div>
            )}
            {this.dataValid && dataShow.length > 0 && w && h ? (
              <svg width={`${w}px`} height={`${h + 60}px`}>
                <g transform={`translate(0, 5)`}>
                  <g
                    ref={(nodes) =>
                      d3.select(nodes).call(this.customYAxis, nodes)
                    }
                  />
                  {dataShow.map((d, index) => {
                    return [...d.data.data].map((barData) => {
                      const xPosition =
                        this.xValue(barData.id) +
                        index * (barWidth + 5) -
                        (dataShow.length / 2) * barWidth -
                        (dataShow.length - 1) * 2.5;
                      const yPosition = this.yValue(barData[valueKey]);
                      const higherIconsList = this.getHigherIconsList(
                        barData,
                        d.higherThan,
                        get(d, "segment.segmentName", "")
                      );
                      if (
                        index === 0 &&
                        ((barData?.label === d.data.data[4]?.label &&
                          barData?.label === "Recommend") ||
                          (barData?.label === d.data.data[1]?.label &&
                            d.data.data[0]?.label === "advertisingRecall"))
                      ) {
                        adLinePositions.push(xPosition);
                      } else if (
                        index === dataShow.length - 1 &&
                        (barData?.label === "advertisingRecall" ||
                          barData?.label === "Usage")
                      ) {
                        adLinePositions.push(xPosition);
                      }
                      const fontSize = barWidth === 30 ? "13" : "10";
                      return (
                        <g key={barData.label + index}>
                          <rect
                            id={barData.label + "_" + index}
                            key={barData.label + index}
                            className="bar"
                            x={xPosition}
                            y={yPosition}
                            width={barWidth + "px"}
                            height={h - yPosition - 50 + "px"}
                            rx="0"
                            fill={
                              this.props.page === "segments"
                                ? d.color
                                  ? d.color
                                  : d.segment.color
                                : d.brand.brandUpdation === "yes" ||
                                  this.props.page === "global"
                                ? d.brand.color
                                : d.color
                            }
                          />
                          <text
                            id={barData.label + "_" + index}
                            key={barData.label + index}
                            className="bar"
                            fontSize={fontSize}
                            fontWeight="semi-bold"
                            fill="black"
                            x={xPosition + 3}
                            y={yPosition - 5}
                          >
                            {valueKey === "percentage"
                              ? barData?.percentage === 100
                                ? barData?.percentage
                                : barData.percentage?.toFixed(1)
                              : barData?.value === 100
                              ? barData?.value
                              : Number(barData.value)?.toFixed(1)}
                          </text>

                          {statTestingEnabled &&
                            higherIconsList?.map((icon, i) => {
                              const fontSize = barWidth === 30 ? "13" : "8";
                              const statTestingLetterX =
                                icon?.match(/^\+/) && barWidth === 30
                                  ? xPosition + 5
                                  : icon?.match(/^\+/) && barWidth === 15
                                  ? xPosition + 2
                                  : barWidth === 30
                                  ? xPosition + 10
                                  : xPosition + 5;
                              const statTestingLetterY =
                                barWidth === 30
                                  ? yPosition -
                                    (barWidth + 5) * (i + 1) -
                                    20 +
                                    20
                                  : yPosition -
                                    (barWidth + 5) * (i + 1) -
                                    20 +
                                    10;

                              return (
                                <g key={barData.label + "_" + index + icon}>
                                  <rect
                                    id={barData.label + "_" + index + icon}
                                    width={barWidth + "px"}
                                    height={barWidth + "px"}
                                    x={xPosition + 1}
                                    y={
                                      yPosition - (barWidth + 2) * (i + 1) - 20
                                    }
                                    rx={barWidth / 2}
                                    ry={barWidth / 2}
                                    fill={"lightgray"}
                                  ></rect>
                                  <text
                                    fontSize={fontSize}
                                    fontWeight="bold"
                                    fill="black"
                                    x={statTestingLetterX}
                                    y={statTestingLetterY + 3}
                                  >
                                    {icon}
                                  </text>
                                </g>
                              );
                            })}
                        </g>
                      );
                    });
                  })}
                  {
                    <g
                      transform={`translate(0, ${h - 50})`}
                      ref={(node) =>
                        d3.select(node).call(this.customXAxis, node)
                      }
                    />
                  }
                </g>
              </svg>
            ) : (
              <NoDataBox />
            )}
          </div>
        </div>
      </div>
    );
  }
}
