import React, { useEffect } from "react";
import ddIcon from "../../../assets/icons/icon_arrow_down.svg";
import spiritDrinkersMarketLogo from "../../../assets/icons/spirit_drinkers.png";
import cx from "classnames";
import "./BrandsDropDownSelect.scss";
import { IoCloseSharp } from "react-icons/io5";
import { CircleFlag } from "react-circle-flags";
import { getCountryCode } from "../../../utils/common";

const CampaignsDropDown = (props) => {
  useEffect(() => {
    document.addEventListener("click", outsideClickListener);
    return () => {
      document.removeEventListener("click", outsideClickListener);
    };
  });
  const onHeaderClick = () => {
    props.onOpenSelect();
    if (!props.open) {
      document.addEventListener("click", outsideClickListener);
    } else {
      document.removeEventListener("click", outsideClickListener);
    }
  };

  const outsideClickListener = () => {
    if (props.open) {
      props.onOpenSelect();
    }
    document.removeEventListener("click", outsideClickListener);
  };

  const handleSelect = (e, index) => {
    if (multiSelect) {
      document.removeEventListener("click", outsideClickListener);
    } else {
      outsideClickListener();
    }
    const { sIndex } = props;
    if (props?.onSelectFunction) props.onSelectFunction(index, sIndex);
  };

  const { data, selected, open, openTop, summary, multiSelect, skey } = props;
  let selectedData = multiSelect ? data : data[selected];
  if (selected?.length > 0) {
    selectedData = selected?.reduce((acc, obj, ind) => {
      let selectedValue = data.find((ele, index) => index === obj);
      acc.push(selectedValue);
      return acc;
    }, []);
  }
  let countryCode = getCountryCode(selected?.marketName, selected?.countryCode);
  let marketNames =
    selectedData?.length > 4 &&
    selectedData.slice(4).map((obj) => obj?.marketName);
  return (
    <>
      <div className={cx("dropDown", { open: open, summary: summary })}>
        <div className={"dropDownHeaderContainer"} onClick={onHeaderClick}>
          <img className="ddArrow" src={ddIcon} alt="" />
          {multiSelect ? (
            <div className="multiSelected">
              <div className="selectedListConatiner">
                {selectedData.map((market, index) => {
                  const marketStyle = {
                    zIndex: 1 - index,
                    left: 30 * index + "px",
                  };
                  let countryCode = getCountryCode(
                    market?.marketName,
                    market?.countryCode
                  );
                  return (
                    index < 4 && (
                      <div className="marketItem">
                        <div className="marketTitle" style={marketStyle}>
                          {countryCode !== "spiritDrinkers" ? 
                              <CircleFlag
                                countryCode={countryCode}
                                height="35"
                                className="marketLogo"
                              />
                           : (
                            <img
                              src={spiritDrinkersMarketLogo}
                              className="marketLogo"
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              {selectedData.length > 4 && (
                <div className="selectedNum">
                  + {selectedData.length - 4}
                  {marketNames?.length > 0 && (
                    <span className="tooltiptext">
                      {marketNames?.join(", ")}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <>
              {countryCode !== "spiritDrinkers" ? 
                  <CircleFlag
                    countryCode={countryCode}
                    height="35"
                    width={35}
                  />
              : (
                <img
                  src={spiritDrinkersMarketLogo}
                  className="marketLogo"
                  alt=""
                />
              )}
              <div className={"campaignTitle"}>
                <span className={"campaignRegion"}>{selected.region}</span>
                <span className={"campaignName"}>{selected.marketName}</span>
              </div>
            </>
          )}
        </div>
        <div className="dropDownContainer">
          {multiSelect && (
            <div className="selectedBrands">
              <span>
                {selected?.length} {selected?.length > 1 ? "markets" : "market"}{" "}
                selected
              </span>
              <IoCloseSharp onClick={(e) => onHeaderClick(e)} />
            </div>
          )}
          <div className="dropdownListContainer">
            {data?.length > 0 &&
              data.map((campaign, index) => {
                let countryCode = getCountryCode(
                  campaign?.marketName,
                  campaign?.countryCode
                );
                let market = campaign.marketName;
                return (
                  <>
                    {multiSelect ? (
                      <>
                        <div
                          key={skey + index}
                          className={cx("selectItem", {
                            multi: multiSelect,
                            selected:
                              (!multiSelect && index === selected) ||
                              (multiSelect && selected?.indexOf(index) > -1),
                          })}
                          onClick={
                            (!multiSelect && index !== selected) ||
                            (multiSelect &&
                              (selected?.length > multiSelect ||
                                selected?.indexOf(index) < 0))
                              ? (e) => handleSelect(e, index)
                              : null
                          }
                        >
                          <input
                            type="checkbox"
                            checked={selected?.includes(index)}
                          />
                          {countryCode !== "spiritDrinkers" ? (
                            countryCode ? (
                              <CircleFlag
                                countryCode={countryCode}
                                height="35"
                                style={{ padding: "0 10px 0 10px" }}
                              />
                            ) : (
                              <div className="roundedTextLogo">
                                {market?.marketName
                                  ?.slice(0, 1)
                                  ?.toUpperCase()}
                              </div>
                            )
                          ) : (
                            <img
                              src={spiritDrinkersMarketLogo}
                              className="marketLogo"
                              alt=""
                            />
                          )}
                          <div className="name">{market}</div>
                        </div>
                      </>
                    ) : (
                      <div key={index} className={"selectCampaignContainer"}>
                        <div className={"campaignNameBox"}>
                          <span>{campaign.groupName}</span>
                        </div>
                        <div className={"campaignRegionBox"}>
                          {campaign.marketGroupsList.map((subCampaign, ind) => (
                            <span
                              key={ind}
                              className={cx("campaignRegion", {
                                selected: selected.id === subCampaign.id,
                              })}
                              id={subCampaign.id}
                              onClick={() =>
                                props.onSelectCampaign(subCampaign)
                              }
                            >
                              {subCampaign.region}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignsDropDown;
