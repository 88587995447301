import React from "react";
import downLoadIcon from "../../assets/icons/download.svg";
import moreIcon from "../../assets/icons/Icon_views_more.svg";
import editIcon from "../../assets/icons/icon_report_edit.svg";
import copIcon from "../../assets/icons/icon_views_clone.svg";
import exportIcon from "../../assets/icons/icon_views_download.svg";
import xlsxIcon from "../../assets/icons/xlsx.svg";
import pptIcon from "../../assets/icons/ppt.svg";
import deleteIcon from "../../assets/icons/icon_views_delete.svg";
import useViewsReportsExportsStatus from "../../hooks/useViewsReportsExportsStatus";
import ButtonWithDropdownOption from "../ButtonWithDropdownOption";
import { StyledViewsActions } from "./ViewCard/ViewsActions/Styled.ViewsActions";

function ReportActions(props) {
  const {
    report,
    handleExportModal,
    handleReportsActions,
    handleExport,
    handleReportActionsModal,
    handleExportReportOptions,
  } = props;
  const {
    isReportsExportAvailable,
    isReportsExcelExportAvailable,
    isReportsPPTExportAvailable,
  } = useViewsReportsExportsStatus();

  const handleReportClick = (closeDropdown, action) => {
    handleReportsActions(action, report);
    closeDropdown();
  };

  return (
    <StyledViewsActions className="reportActionContainer">
      {isReportsExportAvailable && (
        <ButtonWithDropdownOption
          closeOnContentClick={false}
          className="view-export-download-main-container"
          trigger={
            <img
              width={15}
              src={downLoadIcon}
              alt="views-actions"
              onClick={(e) => handleExportModal(report.id, e)}
            />
          }
        >
          {() => (
            <ul>
              {isReportsPPTExportAvailable && (
                <li
                  key={`ppt_view_${report.id}`}
                  onClick={()=>handleExport(report, true)}
                >
                  <img height={18} src={pptIcon} alt="views-details" />
                  <span>PPT</span>
                </li>
              )}
              {isReportsExcelExportAvailable && (
                <li
                  key={`Excel_view_${report.id}`}
                  onClick={()=>handleExport(report, false)}
                >
                  <img height={18} src={xlsxIcon} alt="views-details" />
                  <span>Excel</span>
                </li>
              )}
            </ul>
          )}
        </ButtonWithDropdownOption>
      )}
      {(isReportsExportAvailable || report.type !== "SYSTEM") && (
        <ButtonWithDropdownOption
          closeOnContentClick={false}
          trigger={
            <img
              width={15}
              src={moreIcon}
              alt="views-actions"
              onClick={(e) => handleReportActionsModal(report.id, e)}
            />
          }
        >
          {(closeDropdown) => (
            <ul>
              {report.type !== "SYSTEM" && (
                <>
                  <li 
                    key={`edit_${report.id}`}
                    onClick={(e) =>
                      handleReportClick(closeDropdown, "edit", report)
                    }
                  >
                    <img height={18} src={editIcon} alt="views-details" />
                    <span>Edit Report</span>
                  </li>
                  <li
                    key={`clone_view_${report.id}`}
                    onClick={(e) =>
                      handleReportClick(
                        closeDropdown,
                        "edit-date-period",
                        report
                      )
                    }
                  >
                    <img height={18} src={editIcon} alt="views-details" />
                    <span>Edit Date Period</span>
                  </li>
                  <li
                    key={`make_default_view_${report.id}`}
                    onClick={(e) =>
                      handleReportClick(closeDropdown, "clone", report)
                    }
                  >
                    <img height={18} src={copIcon} alt="views-details" />
                    <span>Clone Report</span>
                  </li>
                </>
              )}
              {isReportsExportAvailable && (
                <ButtonWithDropdownOption
                  trigger={
                    <li key={`export_view_${report.id}`}>
                      <img height={18} src={exportIcon} alt="views-details" />
                      <span>Export Report</span>
                    </li>
                  }
                  className="view-export-download-main-container"
                  offset={[40, -40]}
                  placement="right"
                  onClick={(e) => handleExportReportOptions(report.id, e)}
                >
                  <ul>
                    {isReportsPPTExportAvailable && (
                      <li
                        key={`ppt_view_${report.id}`}
                        onClick={()=>handleExport(report, true)}
                      >
                        <img height={18} src={pptIcon} alt="views-details" />
                        <span>PPT</span>
                      </li>
                    )}
                    {isReportsExcelExportAvailable && (
                      <li
                        key={`Excel_view_${report.id}`}
                        onClick={()=>handleExport(report, false)}
                      >
                        <img height={18} src={xlsxIcon} alt="views-details" />
                        <span>Excel</span>
                      </li>
                    )}
                  </ul>
                </ButtonWithDropdownOption>
              )}
              {report.type !== "SYSTEM" && (
                <li
                  id="view-delete-action"
                  key={`delete_view_${report.id}`}
                  onClick={(e) =>
                    handleReportClick(closeDropdown, "delete", report)
                  }
                >
                  <div className="view-delete-action-button">
                    <img height={18} src={deleteIcon} alt="views-details" />
                    <span>Delete Report</span>
                  </div>
                </li>
              )}
            </ul>
          )}
        </ButtonWithDropdownOption>
      )}
    </StyledViewsActions>
  );
}

export default ReportActions;
