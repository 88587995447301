import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import fileIcon from "../../assets/icons/File type icon.png";
import searchIcon from "../../assets/icons/icon-search.svg";
import { RxCrossCircled } from "react-icons/rx";
import "./Views.scss";
import CreateEditReport from "./CreateEditReport";
import DeleteReport from "./DeleteViewOrReport";
import EditViewDateSelector from "./EditViewDateSelector";
import cx from "classnames";
import ApiData from "../../utils/Api";
import {
  setReports,
  setViews,
} from "../../reducers/TwoByTwoChart/actionCreators";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import LoadingLayer from "../widgets/Shared/LoadingLayer";
import { generateWSPrivateChannelId } from "../../utils/userHelper";
import useFileDownload from "../FileDownload/hooks/useFileDownloadHook";
import Toaster from "./Toaster";
import ReportCard from "./ReportCard";

const ReportsReorderableList = (props) => {
  const dispatch = useDispatch();
  const reportsListData = useSelector(
    (state) => state?.twoByTwoChart?.reportsList
  );
  const viewsListData = useSelector((state) => state?.twoByTwoChart?.viewsList);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [toasterMessage, setToasterMessage] = useState(null);
  const [selectedReports, setSelectedReports] = useState([]);
  const { addNewFileToDownload, updateNewFileDownloadStatus } =
    useFileDownload();
  const { reportIds } = useMemo(() => {
    const reportIds = categoryListData?.map((report) => {
      return report?.id;
    });
    return {
      reportIds,
    };
  }, [categoryListData]);
  const clickedInsideModalRef = useRef(false); // Track clicks inside modal or icon
  const { id } = JSON.parse(localStorage.getItem("userInfo"));
  const connectionId = generateWSPrivateChannelId(id);

  useEffect(() => {
    setCategoryListData(reportsListData);
    updateViewsForValidation(reportsListData);
  }, [reportsListData]);

  // Fetch initial data
  useEffect(() => {
    getReportsListData();
  }, []);

  const updateViewsForValidation = async (reportsListData) => {
    if (reportsListData?.length === 0) {
      let data = await ApiData.getAllReportViews();
      dispatch(setViews(data));
    }
  };

  const getReportsListData = async () => {
    let reportsData = await ApiData.getReports();
    updateViewsForValidation(reportsData);
    dispatch(setReports([...reportsData]));
    setCategoryListData(reportsData);
    setIsLoading(false);
  };

  // Toggle main modal visibility
  const handleReportActionsModal = (id, e) => {
    e.stopPropagation();
    clickedInsideModalRef.current = true;
  };

  // Toggle export modal visibility
  const handleExportModal = (id, e) => {
    e.stopPropagation();
    clickedInsideModalRef.current = true;
  };

  // Toggle export report options for Export Report button
  const handleExportReportOptions = (id, e) => {
    e.stopPropagation();
    clickedInsideModalRef.current = true;
  };

  // Handle actions (edit, copy, delete)
  const handleReportsActions = (action, ele) => {
    setSelectedAction(action ? action : null);
    setSelectedReports(ele ? [ele] : []);
  };

  // Handle search input change
  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value.trim() !== "") {
      handleKeyUp("", e.target.value);
    } else {
      setCategoryListData(reportsListData); // Reset data
    }
  };

  // Clear search
  const clearSearch = () => {
    setSearchInput("");
    setCategoryListData(reportsListData); // Reset data
  };

  // Search on "Enter" key press
  const handleKeyUp = (e, val) => {
    let searchValue = val ? val : searchInput;
    if (e?.keyCode === 13 || searchValue.trim() !== "") {
      const filteredData = categoryListData?.filter(({ name }) =>
        name
          .toLowerCase()
          ?.replace(/\s/g, "")
          .includes(searchValue.toLowerCase()?.replace(/\s/g, ""))
      );
      setCategoryListData(filteredData?.length ? filteredData : []);
    }
  };

  const handleRedirectViewsPage = (selectedReport) => {
    props.data.dataItemClick(
      1,
      `/reports/${selectedReport?.id}`,
      selectedReport?.name
    );
  };

  const handleToasterClose = (e) => {
    e && e.stopPropagation();
    setToasterMessage(null);
  };

  const handleExport = (ele, isPPT = false) => {
    const fileType = isPPT ? "pptx" : "xlsx";
    const fileName = `${ele?.name}.${fileType}`;
    ApiData.PostReportExport(
      {
        reportId: ele?.id,
        fileName,
        connectionId,
        userId: id,
      },
      fileType
    )
      .then((res) => {
        handleToasterMessage({
          type: "success",
          message: "Export initiated",
        });
        // updateNewFileDownloadStatus(true);
        addNewFileToDownload({
          fileName,
          jobId: res,
          screenType: "reports",
        });
      })
      .catch(() => {
        handleToasterMessage({
          type: "error",
          message: "Error while exporting report.",
        });
      });
  };

  const handleToasterMessage = (messageObj) => {
    setToasterMessage({
      type: messageObj.type,
      message: messageObj.message,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const updateReportsOrder = (updatedReports) => {
    updatedReports = updatedReports?.map(({ id }, ind) => {
      return {
        reportId: id,
        order: ind,
      };
    });
    ApiData.updateReportsOrder(updatedReports);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      setCategoryListData((reports) => {
        const oldIndex = reports.findIndex(
          (report) => report.id === active?.id
        );
        const newIndex = reports.findIndex((report) => report.id === over?.id);
        const updatedArray = arrayMove(reports, oldIndex, newIndex);
        updateReportsOrder(updatedArray);
        return updatedArray;
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingLayer />
      ) : (
        <div className="viewsContainer reportsPage">
          {reportsListData?.filter(({ viewIds }) => viewIds !== null).length >
          0 ? (
            <>
              <div className="selectFilterContainer">
                <img src={searchIcon} alt="" className="actionIcon" />
                <input
                  type="text"
                  placeholder="Search Reports"
                  value={searchInput}
                  className="textarea"
                  onChange={onSearchChange}
                  onKeyUp={handleKeyUp}
                />
                {searchInput && (
                  <RxCrossCircled
                    className="prefixIcon cursor"
                    onClick={clearSearch}
                  />
                )}
              </div>
              {categoryListData?.length > 0 ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "12px",
                    }}
                  >
                    <SortableContext
                      items={reportIds}
                      useDragOverlay={false}
                      strategy={rectSortingStrategy}
                    >
                      {categoryListData.map(
                        (ele) =>
                          ele.viewIds !== null && (
                            <ReportCard
                              key={ele?.id}
                              id={ele?.id}
                              report={ele}
                              handleRedirectViewsPage={handleRedirectViewsPage}
                              handleExportModal={handleExportModal}
                              handleReportsActions={handleReportsActions}
                              handleExport={handleExport}
                              handleReportActionsModal={
                                handleReportActionsModal
                              }
                              handleExportReportOptions={
                                handleExportReportOptions
                              }
                            />
                          )
                      )}
                    </SortableContext>
                  </div>
                </DndContext>
              ) : (
                <div className="emptyCardContainer">
                  <div className="emptyImageContainer">
                    <div className="emptyCard">
                      <div
                        className="emptyFileImageConatiner"
                        style={{ height: "105px" }}
                      >
                        <img src={fileIcon} alt="" />
                      </div>
                      <div className="emptyContentContainer">
                        <div className="subHeader">
                          No Reports found related to search.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="emptyCardContainer">
              <div className="emptyImageContainer">
                <div className="emptyCard">
                  <div className="emptyFileImageConatiner">
                    <img src={fileIcon} alt="" />
                  </div>
                  <div className="emptyContentContainer">
                    <div className="header">No Reports Created</div>
                    <div className="subHeader">
                      You must have already saved slides in order to create a
                      report.
                    </div>
                  </div>
                  <div
                    className="form-button-actions"
                    onClick={() =>
                      viewsListData?.length !== 0 &&
                      handleReportsActions("create")
                    }
                  >
                    <div
                      className={cx("saveButton", {
                        disabled: viewsListData?.length === 0,
                      })}
                    >
                      Build Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Modals for Create/Edit/Delete */}
      {(selectedAction === "clone" ||
        selectedAction === "edit" ||
        selectedAction === "create") && (
        <CreateEditReport
          isOpen={selectedAction !== null}
          selectedAction={selectedAction}
          handleReportsActions={handleReportsActions}
          closeModal={handleReportsActions}
          reportId={selectedReports[0]?.id}
          handleToasterMessage={handleToasterMessage}
        />
      )}
      {selectedAction === "delete" && (
        <DeleteReport
          selectedType={"report"}
          closeModal={handleReportsActions}
          isOpen={selectedAction === "delete"}
          selectedData={selectedReports}
          handleToasterMessage={handleToasterMessage}
        />
      )}
      {selectedAction === "edit-date-period" && (
        <EditViewDateSelector
          isOpen={selectedAction !== null}
          selectedViewAction="edit"
          handleReportsActions={handleReportsActions}
          closeModal={handleReportsActions}
          data={[selectedReports[0]]}
          selectedScreen="reports"
          handleToasterMessage={handleToasterMessage}
        />
      )}
      {toasterMessage && (
        <Toaster
          isOpen={toasterMessage}
          variant={toasterMessage?.type}
          onClose={(e) => handleToasterClose(e)}
        >
          {toasterMessage?.message}
        </Toaster>
      )}
    </>
  );
};

export default ReportsReorderableList;
